@import 'styles/Mixins';
@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography.scss';

.root {
  padding-top: 1rem;
  .header {
    display: flex;
    margin-bottom: 1rem;
    align-items: center;

    .title {
      flex: 1;
      @include themed() {
        color: t(color-primary);
      }
      @include app-font(22px, 26px);
    }
  }
}
.addNewItemButton {
  @include themed() {
    background-color: t(color-primary);
  }
  @include themed() {
    color: t(color-background);
  }
  max-width: 24px;
  min-width: 24px;
  max-height: 24px;
  min-height: 24px;
  border-radius: 50%;
  padding: 0 0;
  svg {
    width: 20px;
    height: 20px;
    align-self: center;
  }
}

.menuRoot {
  .subheader {
    padding: 0.5rem 1.5rem;
    @include themed() {
      color: t(color-primary);
    }
    @include app-font(14px, $weight: 500);
  }
}

.item {
  transition: none !important;
  @include themed() {
    color: t(color-text);
  }
  padding: 0.5rem 1.5rem;
  @include app-font(14px);

  &:hover {
    @include themed() {
      background-color: t(color-background-tag-cloud);
    }
  }

  &:focus {
    @include themed() {
      color: t(color-text-inverse-light);
      background-color: t(color-primary);
    }
  }
}

.tooltip {
  @include body-XXXS;
  @include themed() {
    color: t(color-text-inverse-light);
    background-color: t(color-text-inverse-dark);
  }
  margin-right: 4px !important;
}
