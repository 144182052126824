@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';

.container {
  display: flex;
  align-self: flex-end;
  flex-direction: column;
  margin-bottom: 24px;
  padding-right: 20px;
  width: 211px;

  .thumbnail {
    height: 156px;
    width: inherit;
    @include themed() {
      background-color: t(color-background-tag-cloud);
    }
    border-radius: 16px 16px 0px 0px;
  }
  .details {
    @include themed() {
      background-color: t(color-background-stronger);
    }
    border-radius: 0px 0px 16px 16px;
    padding: 10px;

    .category {
      @include body-XS();
      padding-bottom: 4px;
    }
    .title {
      @include body-S();
    }
  }
}

.popper {
  .tooltip {
    margin-top: 4px !important;
    inset: 12px auto 0 auto;
    border-radius: 1px;
    @include themed() {
      box-shadow: 0 0 8px t(background-color-black-opaque);
      background-color: t(color-surface);
    }
    @include body-XS();
    padding: 2px 8px;
    @include themed() {
      color: t(color-text-weak);
    }
  }
}
