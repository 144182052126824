@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';
@import 'styles/mediaQueries.scss';

.memberHeader {
  @include themed() {
    background-color: t(color-surface-strong);
  }
}

.contentContainer {
  display: flex;
  min-width: 922px;
  padding-right: 5px;
  box-sizing: border-box;

  .contentGrid {
    width: 100%;
    height: 100%;
    @include themed() {
      background-color: t(color-background-strong);
    }
    display: grid;
    grid-template-areas: 'chat notes chart';
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 12px;
  }

  .contentGridElement {
    min-width: 295px;
  }

  .chartContainer {
    box-sizing: border-box;
    grid-area: chart;
  }

  @include on-mac-book-pro-14-2022-and-up {
    .expandedChatLayout {
      grid-template-columns: 2fr 1fr 1fr;
    }
  }

  @include on-mac-book-pro-14-2022-and-less {
    .expandedChatLayout {
      grid-template-columns: 2fr 1fr 0;
      &.withOpenDrawer {
        grid-template-columns: 1fr 1fr 0;
      }
    }
  }
}
