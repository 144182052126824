@import 'styles/design-tokens/Colors.scss';
@import 'styles/design-tokens/Typography.scss';
@import 'styles/Variables';
@import './InboxSection.mixin.scss';

$sectionTitleHeight: 47px;

.sectionHeaderWrapper {
  position: sticky;
  padding: 7px 6px;

  @include themed() {
    background-image: linear-gradient(
      to right,
      t(color-background) 97%,
      transparent 3%
    );
  }
  z-index: 10;
  // ensure to adjust the height variable when modifying props that may affect the height
  // such as padding, margin e.t.c. So we don't break the sticky position of inbox section titles
  height: $sectionTitleHeight;
  box-sizing: border-box;

  /*** Today's Member Tab ***/
  &.risk-alerts {
    top: $inboxHeaderTabsHeight;
    bottom: #{$sectionTitleHeight * 3};
  }
  &.appointments,
  &.conversations-and-tasks {
    top: #{$inboxHeaderTabsHeight + $sectionTitleHeight};
    bottom: #{$sectionTitleHeight * 2};
  }
  &.scheduled-checkins,
  &.tasks {
    top: #{$inboxHeaderTabsHeight + $sectionTitleHeight * 2};
    bottom: $sectionTitleHeight;
  }
  &.completed {
    top: #{$inboxHeaderTabsHeight + $sectionTitleHeight * 3};
    bottom: 0;
  }

  /*** All Tab ***/

  &.scheduled {
    top: $inboxHeaderTabsHeight;
    bottom: #{$sectionTitleHeight * 2};
  }
  &.past {
    top: #{$inboxHeaderTabsHeight + $sectionTitleHeight};
    bottom: $sectionTitleHeight;
  }
  &.closed {
    top: #{$inboxHeaderTabsHeight + $sectionTitleHeight * 2};
    bottom: 0;
  }

  .sectionHeader {
    border: none;
    border-radius: 8px;
    @include themed() {
      background-color: t(color-background);
    }
    display: flex;
    padding: 2px 10px;
    height: 33px;
    text-align: left;
    width: 100%;
    @include label-XS;
    position: sticky;
    z-index: 10;

    .sectionIcon {
      height: 27px;
      width: 16px;
      margin-right: 3px;
      @include inbox-section-icons;
    }

    .sectionTitle {
      align-self: center;
      flex: 1;
    }

    .expandIcon {
      align-self: center;
      height: 12px;
      margin-left: 8px;
      width: 13px;
      transform: rotate(90deg);
      transition: transform 400ms;
      &.rotate90deg {
        transform: rotate(0deg);
        transition: transform 400ms;
      }

      svg {
        font-size: 1.5rem;
        left: -6px;
        margin: 0;
        padding: 0;
        position: relative;
        top: -6px;
      }
    }

    @include inbox-section-colors;

    .addCircleButton {
      @include themed() {
        background-color: t(color-primary);
        color: t(color-text-inverse-light);
      }
      width: 22px;
      height: 22px;
      border-radius: 50%;
      padding: 0;
      border: none;
      box-shadow: 0px 2px 0px rgba(26, 25, 25, 0.2);
      margin-top: 3px;
      cursor: pointer;

      .addCircleIcon {
        @include themed() {
          background-color: t(color-primary);
          color: t(color-text-inverse-light);
        }
        width: 22px;
        height: 22px;
        border-radius: 50%;
        padding: 0;
      }
    }
  }

  .section {
    @include themed() {
      background-color: t(color-background);
    }
    height: 180px;
    overflow-y: scroll;

    & ~ & {
      border-radius: 0;
    }

    .sectionContent {
      display: flex;
      flex-flow: column nowrap;
      flex-grow: 1;
      padding: 0;
    }

    .paddingTop {
      padding-top: 12px;
    }
  }

  @include inbox-section-colors;
}

.loadMoreBtn {
  @include label-XS;
  @include themed() {
    color: t(color-text);
  }
  outline: none;
  background: none;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  width: 100%;
  text-align: start;
  &:disabled {
    cursor: not-allowed;
  }
}
.error {
  display: flex;
  justify-content: center;
  background: #fee3de;
  padding: 8px 16px;
  cursor: pointer;
  margin-top: -2px;
  @include body-XS;
}
