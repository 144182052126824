@import 'styles/design-tokens/Colors.scss';
@import 'styles/design-tokens/Typography.scss';

.allergiesTable {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  @include themed() {
    background-color: 1px solid t(color-border);
  }
  margin-top: 1em;
}

.allergies_rows {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: 3em;
}

.label {
  @include label-XS;
}

.info {
  @include body-XS;
}

.allergy_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 4px;
  padding-right: 4px;
  gap: 4px;
}

.allergy_field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  background: #ffffff;
  @include themed() {
    border: 1px solid t(color-border);
  }
  border-radius: 6px;
}

.drug_name_field_container {
  width: 30%;
}

.comments_field_container {
  width: 70%;
}

.column_4 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 8px 12px 12px;
  gap: 4px;
  width: 30%;
}

.column_8 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 8px 12px 12px;
  gap: 4px;

  width: 70%;
}
