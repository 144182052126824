@import 'styles/Colors';
@import 'styles/Mixins';
@import 'styles/Variables';
@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';

.tag {
  @include app-font(13px);
  display: flex;
  width: fit-content;
  align-items: center;
  margin: 2px;
  max-width: $autocompleteBoxWidth;
  padding: 2px 4px 2px 16px;
  overflow: hidden;

  border: 1px solid $mediumGray;
  background: $fadedGray;
  color: $mediumSlate;
  border-radius: 100px;
}

.tagV2 {
  @extend .tag;
  @include body-XS;
  margin: 8px 0;
  min-height: 45px;
  border-radius: 8px;
  border: none;
  width: 100%;
  max-width: 96%;

  letter-spacing: 0.04em;
  text-transform: capitalize;
  @include themed() {
    color: t(color-text-weak);
    background-color: t(color-interactive-static-light-strong);
  }

  span {
    text-transform: capitalize;
    width: 100%;
  }

  &:hover {
    @include themed() {
      background-color: t(color-interactive-strongest);
    }
  }
}

.icon {
  font-size: 16px;
  cursor: pointer;
  padding: 4px;

  margin-left: auto;
  margin-right: 10px;

  @include themed() {
    color: t(color-text-weak);
    &:hover {
      color: t(color-text);
    }
  }
}
