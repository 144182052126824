@import 'styles/Colors';
@import 'styles/Mixins';

.root {
  padding-top: 1rem;
  .header {
    display: flex;
    margin-bottom: 1rem;
    align-items: center;

    .title {
      flex: 1;
      color: $steelBlue;
      @include app-font(22px, 26px);
    }
  }

  .dropdownButton {
    background-color: $steelBlue;
    color: $white;
    text-transform: capitalize;
  }
}

.menuRoot {
  .subheader {
    padding: 0.5rem 1.5rem;
    color: $steelBlue;
    @include app-font(14px, $weight: 500);
  }
  .item {
    color: $mediumSlate;
    padding: 0.5rem 1.5rem;
    @include app-font(14px);
  }
}
