@import 'styles/design-tokens/Colors.scss';
@import 'styles/design-tokens/Typography.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  margin: 16px;
  gap: 10px;
  @include themed() {
    background-color: t(color-background-stronger);
  }
  border-radius: 6px;
}

.icon {
  width: 16px;
  height: 16px;
}

.text {
  @include body-XS;
  @include themed() {
    color: t(color-text-weak);
  }
  padding-top: 3px;
}
