@import 'styles/Colors';
@import 'styles/design-tokens/Colors';

@mixin cellWrapper($name, $backgroundColor, $textColor) {
  .scheduleCellWrapper#{$name} {
    @include themed() {
      &:global(.rbc-event) {
        background-color: t($textColor);
        color: t($backgroundColor);
        &:global(.rbc-selected) {
          background-color: t($backgroundColor);
          color: t($textColor);
          // Overrides react-big-calendar's .rbc-event { border: none !important; }
          border: 0.05rem solid t($textColor) !important;
        }
      }
    }
  }
}

.scheduleCellWrapperAppointment {
  @include themed() {
    &.tentativeAppointment {
      background: repeating-linear-gradient(
        45deg,
        $steelBlue 0px,
        $steelBlue 5px,
        $darkSteelBlue 5px,
        $darkSteelBlue 7px
      );
      &:global(.rbc-event) {
        &:global(.rbc-selected) {
          background-color: t(color-background);
          color: t(color-text-link);
          // Overrides react-big-calendar's .rbc-event { border: none !important; }
          border: 2px solid t(color-text-link) !important;
        }
      }
    }
  }

  &.canceledAppointment {
    // Overrides react-big-calendar's .rbc-event { border: none !important; }
    border: 2px solid $steelBlue !important;
    &:global(.rbc-event) {
      background-color: white;
    }
    color: $steelBlue;
    text-decoration: line-through;
  }
}

.scheduleCellWrapperMeeting {
  &.tentativeMeeting {
    background: repeating-linear-gradient(
      45deg,
      $darkGrey 0px,
      $darkGrey 5px,
      $warmGrey 5px,
      $warmGrey 7px
    );
    &:global(.rbc-selected) {
      background-color: $white;
    }
  }
}

@include cellWrapper('Appointment', color-primary, color-background);
@include cellWrapper('Availability', color-text, color-surface-stronger);
@include cellWrapper(
  'Meeting',
  color-text-inverse-dark-weak,
  color-text-inverse-light
);
@include cellWrapper('Event', background-color-legacy-pine, color-background);
