@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';

.errorDetailsModal {
  .dialogTitle {
    margin-top: 12px;
    padding: 12px 24px;
    @include heading-S;
    @include themed() {
      color: t(color-text-inverse-dark);
    }
    .closeButton {
      position: absolute;
      right: 24px;
      padding: 0;
      margin: 0;
    }
  }

  .dialogContent {
    @include body-XS;
    padding: 12px 24px;
  }

  .dialogAction {
    padding: 24px;
    width: 500px;
  }
}

svg.iconSize {
  font-size: 16px;
  margin-right: 4px;
}
