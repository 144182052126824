@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';

.message {
  @include themed() {
    background-color: t(color-primary-strong);
  }
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;

  .text {
    @include themed() {
      color: t(color-text-inverse-light);
    }
    @include body-XS;
    padding-top: 8px;
  }

  .alertSection {
    @include themed() {
      background-color: t(color-background-tag-cloud);
    }
    max-width: 120px;
    border-radius: 4px;
    align-self: center;
    margin-bottom: 12px;
  }
  .alertText {
    @include themed() {
      color: t(color-text);
    }
    @include label-XXS;
    font-weight: 400;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.04em;
    padding: 3px;
  }

  .replyAlertSection {
    @extend .alertSection;
    @include themed() {
      color: t(color-text-inverse-light);
      background-color: t(color-background-weaker);
    }
  }
  .replyAlertText {
    @extend .alertText;
    @include themed() {
      color: t(color-surface);
    }
  }
  .subject {
    @include body-XS;
    @include themed() {
      color: t(color-surface);
    }
    font-weight: bold;
  }
}

.ownerBubble {
  @include themed() {
    background-color: t(color-interactive-stronger);

    .ownerText {
      color: t(color-text);
    }

    .ownerSubject {
      color: t(color-text);
    }
  }
}
