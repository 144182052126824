@import 'styles/design-tokens/Colors.scss';
@import 'styles/design-tokens/Typography.scss';

.siCoachAddedBanner {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 10px;

  width: 100%;
  height: 37px;

  @include themed() {
    background-color: t(color-background-stronger);
    color: t(color-text-weak);
  }
  border-radius: 6px;
  margin: 16px;

  @include body-XS;
}
