@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';
@import 'styles/Mixins';

.checkbox {
  display: flex;
  width: 100%;

  .icon {
    display: flex;
    flex-flow: row nowrap;
    padding: 0;
    background-color: transparent;
    border: none;
    margin-right: 4px;
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }

    svg {
      margin-top: 2px;
      @include checkBoxSize;
    }
  }

  .text {
    display: flex;
    flex-grow: 1;

    & > .label {
      display: inline-block;
      padding: 0;
      background-color: transparent;
      border: none;

      @include themed() {
        &.unchecked {
          color: t(color-text-weak);
        }
        &.checked {
          color: t(color-text-weaker);
          text-decoration: line-through;
        }
        &.checkedWithoutStrikeThrough {
          color: t(color-text-weaker);
          text-decoration: none;
        }
        &.notApplicable {
          color: t(color-text-weaker);
          text-decoration: line-through;
        }
        &.notApplicableWithoutStrikeThrough {
          color: t(color-text-weaker);
          text-decoration: none;
        }
      }

      &.s {
        .title {
          @include themed() {
            color: t(color-text);
          }
          @include body-S;
        }

        .subTitle {
          @include body-XS;
          @include themed() {
            color: t(color-text-weak);
          }
        }

        .priority {
          @include themed() {
            color: t(color-text-critical);
          }
        }
      }

      &.xs {
        .title {
          @include themed() {
            color: t(color-text);
          }
          @include body-XS;
        }

        .subTitle {
          @include body-XXS;
          @include themed() {
            color: t(color-text-weak);
          }
        }

        .priority {
          @include themed() {
            color: t(color-text-critical);
          }
        }
      }
    }
  }
}
