@import 'styles/design-tokens/Colors';

.dependentReport {
  @include themed() {
    background-color: t(color-surface-static);
  }
  .tab {
    padding: 16px;
    @include themed() {
      border-bottom: 1px solid t(color-primary);
    }
  }
  .tabContent {
    padding: 16px;
  }

  .completedBy {
    @include themed() {
      color: t(background-color-gray);
    }
    font-size: 14px;
    line-height: 14px;
    padding: 16px;
  }

  .cardSection {
    padding: 16px;
  }
}
