@import 'styles/design-tokens/Typography';

.GAD7Info {
  margin: 20px 0;
  .main {
    margin: 2px 0 3px 0;
    display: flex;
    align-items: center;
  }
  .title {
    @include label-XXXS;
    text-align: left;
  }
  .total {
    @include body-S;
    text-align: left;
  }
  .timestamp {
    @include body-XS;
    text-align: left;
  }
  .icon {
    padding-right: 4px;
  }
}
.tooltipLine {
  display: flex;
  @include body-XXS;
  text-align: left;
}
