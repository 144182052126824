@import 'styles/Colors';
@import 'styles/Mixins';
@import 'styles/Variables';

.root {
  margin-top: 32px;

  .flex {
    display: flex;
    &.flexColumn {
      flex-direction: column;
    }
  }

  .timezone {
    display: inline-flex;
    vertical-align: center;
    align-items: center;
    margin-left: 8px;
    margin-bottom: 16px;
    @include app-font(12px);
  }

  div.datePicker {
    width: 250px;
    margin: 0 16px 0 0;
  }
}
