@import 'styles/design-tokens/Colors';

.datePicker {
  max-width: 200px;
  @include themed() {
    background-color: t(color-background);
  }
  border-radius: 20px;

  input {
    padding: 7px;
  }

  fieldset {
    border-radius: 20px;
  }
}

.grid {
  @include themed() {
    background-color: t(color-surface-strong) !important;
  }
  height: 100%;
  max-height: 100%;

  .item {
    height: 100%;

    &:nth-of-type(1) {
      padding-right: 0;
      @include themed() {
        border-right: 1px solid t(color-border-strong);
      }
      overflow: auto;
    }

    &:nth-of-type(2) {
      padding: 0;
      overflow: auto;
    }
  }
}

.nav {
  margin: 1rem 0;
  display: flex;
  align-items: center;

  .navTab {
    flex: 1;
  }

  .header {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: 24px;
  }
}

.dateRangeBox {
  &:hover {
    @include themed() {
      background-color: t(color-interactive-light);
      color: t(color-text-weak-alt);
    }
    cursor: pointer;
  }

  .stack {
    &:nth-of-type(1) {
      font-size: 12px;
    }

    &:nth-of-type(2) {
      font-size: 25px;
    }
  }
}
.header {
  margin: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  @include themed() {
    color: t(color-text);
  }

  .avatar {
    height: 112px;
    width: 112px;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .name {
    font-weight: 700;
    font-size: 18px;
    line-height: 21.6px;
  }

  .stats {
    display: flex;
    justify-content: center;
    gap: 20px;

    .mute {
      @include themed() {
        color: t(color-text-weaker-inverted);
      }
    }
  }
}

.dateRangeBoxSelected {
  @extend .dateRangeBox;

  @include themed() {
    background-color: t(color-text-inverse-dark);
    color: t(color-surface);
  }
}

.feedbackWrapper {
  background-color: rgba(255, 255, 255, 0.6);
  padding: 30px;
  margin: 5px 20px;
  text-align: center;
  font-size: 14px;
  line-height: 21px;
  color: #666666;

  .rating {
    @include themed() {
      color: t(color-text);
    }
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .mute {
    @include themed() {
      color: t(color-text-weaker-inverted);
    }
  }

  .quotemark {
    width: 2em;
    height: 2em;
    transform: rotate(180deg);
  }

  .stepper {
    @include themed() {
      background-color: t(background-color-light-gray-opaque);
    }
  }
  .comment {
    padding-top: 8px;
  }
  .readMore {
    padding: 8px 0 16px 0;
  }
}

.divider {
  margin: 20px;
}

.profileLink {
  text-align: center;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 20px;
}

.profileLink a {
  @include themed() {
    color: t(color-primary);
  }
}

.link {
  @include themed() {
    color: t(color-interactive-inverted);
    text-decoration-color: t(color-interactive-inverted);

    &:visited {
      color: t(color-interactive-inverted);
    }
  }
}
