@import 'styles/design-tokens/Colors';

.multiTabContainer {
  display: flex;
  @include themed() {
    background-color: t(color-background);
  }
  width: vw !important;
  padding-right: 6px !important;
}

.memberHeader {
  @include themed() {
    background-color: t(color-surface-strong);
  }
}

.contentContainer {
  display: flex;

  .content {
    display: flex;
    width: 100%;
    height: 100%;
    @include themed() {
      background-color: t(color-background-strong);
    }

    display: grid;
    grid-template-columns: 25% 25% 50%;

    @media (max-width: 1350px) {
      grid-template-columns: 49% 25% 25%;
    }
    @media (max-width: 1250px) {
      grid-template-columns: 450px 300px calc(100% - 750px);
    }
  }

  .expandedChatLayout {
    grid-template-columns: 49% 25% 25%;
    @media (max-width: 1250px) {
      grid-template-columns: 500px 300px calc(100% - 800px);
    }
  }

  .twoColumnsLayout {
    grid-template-columns: 50% 50%;
  }

  .oneColumnLayout {
    grid-template-columns: 100%;
  }
}

.chartContainer {
  box-sizing: border-box;
}
