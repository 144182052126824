@import 'styles/Colors';
@import 'styles/Mixins';
@import 'styles/Variables';
@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography.scss';

svg.info {
  padding: 0 8px;
  font-size: 1rem;
  @include themed() {
    color: t(color-text-weak);
  }
  margin-bottom: -4px;
}
