@import 'styles/design-tokens/Typography.scss';
@import 'styles/Mixins';
@import 'styles/design-tokens/Colors.scss';

.root {
  display: flex;
  margin-left: -3px;

  .label {
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .checkbox {
    @include themed() {
      color: t(color-primary);
    }
  }

  .disabled {
  }

  .checkbox ~ .checkboxLabel {
    font-weight: 700;
  }

  > span {
    padding: 4px !important;
  }

  svg {
    @include checkBoxSize;
  }
}
.checkboxLabel {
  @include themed() {
    color: t(color-text-weak);
  }
  @include body-XS;
}
