@import 'styles/design-tokens/Colors.scss';
@import 'styles/design-tokens/Typography.scss';

.careTeamButtonContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 16px;
}

.loading {
  margin-top: 1rem;
}

.fullWidth {
  width: 100%;
}

.careTeamButton {
  display: flex;
  min-width: 49%;

  button {
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    display: flex;
    width: 100%;
    height: 32px;

    border-radius: 72px;

    @include label-S;

    @include themed() {
      box-shadow: 0px 2px 0px t(background-color-black-opaque);
      border: 2px solid t(color-border);
      background-color: t(color-background);
      color: t(color-surface-gradient-inverted);

      &:hover {
        background-color: t(color-background);
      }

      &:disabled {
        background-color: t(color-background);
        opacity: 0.5;
      }
    }
  }
}

.careTeamButton:nth-child(3) {
  width: 100%; /* The third button takes up the full width */
}

@mixin careTeamButtonSICoachIndicator {
  box-sizing: border-box;

  width: 12px;
  height: 12px;
  margin-left: 8px;
  @include themed() {
    border: 1px solid t(color-background);
  }
  border-radius: 20px;

  flex: none;
  order: 2;
  flex-grow: 0;
}

.careTeamButtonSICoachIndicatorOnline {
  @include careTeamButtonSICoachIndicator;
  @include themed() {
    background-color: t(color-status-online);
  }
}

.careTeamButtonSICoachIndicatorOffline {
  @include careTeamButtonSICoachIndicator;
  @include themed() {
    background-color: t(color-status-offline);
  }
}

.careTeamButtonSICoachIndicatorReadOnly {
  @include careTeamButtonSICoachIndicator;
  @include themed() {
    background-color: t(color-status-readonly);
  }
}
