@import 'styles/design-tokens/Colors.scss';
@import 'styles/design-tokens/Typography.scss';

.body {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 12px 0 0;
  .content {
    align-items: inherit;
    display: inherit;
    flex-direction: inherit;
    width: 100%;
    .graph {
      padding: 20px 0;
    }
    .border {
      @include themed() {
        border-bottom: 1px solid t(color-border-strong);
      }
    }
    .mugsText {
      @include body-XXXS;
      @include themed() {
        color: t(color-text-weaker);
      }
    }
  }
}
