@import 'styles/Colors';

.headerWithDot {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .header {
    margin: 0;
    padding: 0;
  }

  .dot {
    $size: 16px;
    width: $size;
    height: $size;
    border-radius: 20px;
    background: $fadedSlate;
    &.active {
      background: $sunset;
    }
  }
}
