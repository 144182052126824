@import 'styles/design-tokens/Typography';
@import 'styles/design-tokens/Colors';

.item {
  width: 50%;
  padding-top: 1rem;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
}

.disabled {
  cursor: default;
}

.fullscreen {
  width: 100%;
}
