@import 'styles/Colors';

div.root {
  padding: 2px 16px;
  display: flex;
  align-items: center;
  background: $fadedGray;
  border-radius: 25px;
}

.input {
  margin-left: 8px;
  flex: 1;
}

button.iconButton {
  padding: 0;

  &:hover {
    // disable hover effect on search icon
    background-color: transparent;
  }
}
