@import 'styles/design-tokens/Colors';
@import 'styles/Colors';
@import 'styles/Variables';

.grid {
  height: 100%;
  max-height: 100%;

  .item {
    // Independently scroll left and right rail
    height: 100%;
    padding: 12px;
    border-right: 1px solid $gridBorder;

    &.pl-0 {
      padding-left: 0;
    }

    &:nth-of-type(1) {
      // left rail padding
      padding-right: 0;
    }

    // Patient profile
    &:nth-of-type(2) {
      // Right rail padding
      @include themed() {
        background-color: t(color-surface-strong);
      }
      padding: 1px 0 0 0;
    }
  }
}

.profileWrapper {
  overflow-y: scroll;
  height: calc(100vh - $memberHeaderHeight);
  box-sizing: border-box;
  padding: 24px 12px 12px;
}

.profileV2Wrapper {
  height: calc(100vh - $memberHeaderHeight);
  background: inherit;
  padding: 0;

  &.withMultiTab {
    height: calc(100vh - $memberHeaderHeight - $multiTabHeight);
    @include themed() {
      background-color: t(color-surface-strong);
    }
  }
}

.chart {
  padding: 2rem;
  background: #fff;
}
