.masonryGrid {
  display: flex;
  width: auto;
}

.column {
  background-clip: padding-box;
  box-sizing: border-box;
  background: inherit;
}

.masonryItem {
  padding-left: 16px;
  margin-bottom: 12px;
  box-sizing: border-box;
}
