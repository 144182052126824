@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography.scss';

.blue_sky_bg {
  @include themed() {
    background-color: t(color-background-tag-cloud);
  }
}

.formGroup {
  margin-bottom: 1.5rem !important;
}

.formGroupV2 {
  padding-top: 16px;
}

.label {
  @include label-XS;
  @include themed() {
    color: t(color-surface-overlay);
  }
  line-height: 16px;
  display: flex;
}

.subtext {
  margin: 0;
  @include body-XS;
  @include themed() {
    color: t(color-text-weak);
  }
}

.checkbox {
  @include body-S;
  @include themed() {
    color: t(color-text-weak);
  }
}

.otherTextField {
  margin-top: -16px;
}

.gridColumn {
  padding: 0 16px 0 0 !important;
}

.socialDevelopmentOtherRoot {
  margin: 0 0 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.noMargin {
  margin: 0;
}

.agenciesTextField {
  margin: 0;
}

.marginNotEndorsing {
  margin: 0 0 1rem;
}
.textAreaField {
  margin-top: 8px;
}
.marginBooleanField {
  margin-top: 16px;
}
