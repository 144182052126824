@import 'styles/Colors';
@import 'styles/Variables';

.appointments {
  background: $white;

  .table {
    .head {
      th:last-child {
        width: $tableActionItemWidth;
        padding-right: 0;
      }
    }

    tr {
      td .iconActionBar {
        display: none;
      }

      &:hover {
        td .iconActionBar {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
