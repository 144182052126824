@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';

.header {
  position: sticky;
  top: 0;
  width: 100%;
  height: 37px;
  @include themed() {
    background-color: t(color-background);
  }
  box-shadow: 0px 1px 6px 3px rgba(0, 0, 0, 0.1);
  display: grid;
  padding: 8px 16px;
  box-sizing: border-box;
  flex-shrink: 0;
  align-items: center;
  grid-template-columns: 1fr 150px;
  justify-content: space-between;

  .tooltipTitle {
    margin: 0;
  }

  .tooltipContainer {
    display: grid;
    grid-template-columns: 28px 1fr;
    height: 21px;
    align-items: center;
    align-self: baseline;
  }

  button.closeBtn {
    width: 150px;
    height: 24px;
    padding: 11px;
    margin: 0;
    @include label-XS();
    text-transform: unset;
    border-radius: 75px;

    @include themed() {
      background-color: t(color-interactive-light);
      color: t(color-text-weak-alt);
    }

    box-shadow: 0 2px 0 rgba(26, 25, 25, 0.2);
  }

  .closingText {
    @include label-XS();
    @include themed() {
      color: t(color-text-weaker);
    }
  }

  .closeText {
    padding: 4px;
    @include label-XS();
    margin-top: 3px;
    @include themed() {
      color: t(color-text);
    }
  }

  .btn {
    @include label-XS;
    text-transform: none;
    display: flex;
    align-items: center;
    @include themed() {
      color: t(color-text-inverse-light);
    }
    height: 21px;
  }

  .submitBtn {
    padding: 4px 8px;
    border-radius: 72px;
    @include themed() {
      background-color: t(color-primary);
    }
  }

  .iconSize {
    @include label-XS;
    margin-right: 4px;
  }
}

.popper {
  .tooltip {
    border-radius: 5px;
    @include themed() {
      color: t(color-surface-strong);
      background-color: t(color-surface-strong-inverted);
    }
    margin: 0 0 0 8px !important;
    @include utility-S();
    padding: 2px 10px;
  }
}
