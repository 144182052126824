@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography.scss';

button.saveButton {
  @include themed() {
    color: t(color-surface-static);
    background-color: t(color-text-link);
  }
  text-transform: capitalize;

  &:disabled {
    opacity: 0.5;
    @include themed() {
      color: t(color-surface-static);
      background-color: t(color-text-link);
    }
  }
  &:hover {
    @include themed() {
      color: t(color-surface-static);
      background-color: t(color-text-link);
    }
  }

  height: 28px;
  width: 118px;
  border-radius: 72px;
}
