@import 'styles/design-tokens/Colors';

.switch {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 16px;
  margin-top: 1px;
  @include themed() {
    background-color: t(color-interactive-strongest);
  }
  border-radius: 27px;
}

.switchChecked {
  @include themed() {
    background-color: t(color-primary);
  }
}

.thumb {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  display: block;
  top: 2px;
  left: 2px;
  @include themed() {
    background-color: t(color-surface);
  }
  position: relative;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;
}

.thumbChecked {
  left: 14px;
}

.track {
  border-radius: 27px;
  opacity: 1;
  box-sizing: border-box;
}

.input {
  cursor: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
}
