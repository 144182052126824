@import 'styles/Colors';
@import 'styles/Variables';

.root {
  width: $tableActionItemWidth;
  svg {
    font-size: 1.2rem;
    &:hover {
      color: $steelBlue;
    }
  }
}
