@import 'styles/design-tokens/Colors';

.header {
  // TODO need line all the way across
  @include themed() {
    border-bottom: solid t(color-background-strongest) 1px;
  }
  font-weight: bold;
  padding-bottom: 9px;
  // TODO font size 18px
}
