/**
 * @mixin
 * @description
 * This mixin is used to apply colors to the inbox section for both Inbox V1 & V2.
 * 
 * @example
 * @include inbox-section-colors;
 */

@mixin inbox-section-colors {
  .light-grey {
    @include themed() {
      background-color: t(color-background-stronger);
      color: t(color-text-weak);
    }
  }

  .light-rose {
    @include themed() {
      background-color: t(color-background-tag-rose);
      color: t(color-text-critical);
    }
  }

  .light-mint {
    @include themed() {
      background-color: t(color-surface-success);
      color: t(color-text-success);
    }
  }

  .light-cloud {
    @include themed() {
      background-color: t(color-background-tag-cloud);
      color: t(color-primary);
    }
  }
}

@mixin inbox-section-icons {
  &.suicide-risk {
    background-image: url('/assets/inbox/sections/suicide-risk.svg');
    background-repeat: no-repeat;
    background-size: 14px;
    background-position: center center;
  }

  &.no-suicide-risk {
    background-image: url('/assets/inbox/sections/no-suicide-risk.svg');
    background-repeat: no-repeat;
    background-size: 14px;
    background-position: center center;
  }

  &.checkmark {
    background-image: url('/assets/inbox/sections/checkmark.svg');
    background-repeat: no-repeat;
    background-size: 14px;
    background-position: center center;
  }
}
