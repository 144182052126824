$appFont: 'Headspace Apercu';
$topNavHeight: 56px;
$bannerHeight: 34px;
$bannerPadding: 7px;
$autocompleteBoxWidth: 462px;
$maxFormWidth: 450px;
$smallFontSize: 13px;
$smallFontWeight: 700;
$mediumFontSize: 18px;
$formControlBottomMargin: 1.5rem;
$tableActionItemWidth: 120px;
$numberInputWidth: 75px;
$helpTextHeight: 16px;
$memberProfileTabHeight: 138px;
$memberHeaderHeight: 101px;
$multiTabHeight: 42px;
$verticalMenuWidth: 48px;
$inboxHeaderTabsHeight: 57px;
