@import 'styles/design-tokens/Colors';

.status {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  @include themed() {
    color: t(color-text-weak);
  }
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.icon {
  padding-right: 4px;
}

.link {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  margin-top: 9px;
  height: 4em;
  cursor: pointer;

  @include themed() {
    a {
      color: t(color-primary);
    }
  }
}

.emptyLink {
  @extend .link;
  cursor: default;
}

.row {
  padding: 0 0 0 16px;
}

.statusColumn {
  padding-left: 16px;
}

.capitalize {
  text-transform: capitalize;
}

.icon {
  height: 18px;
  font-size: 16px;
}

.overdue {
  filter: invert(28%) sepia(24%) saturate(6816%) hue-rotate(357deg)
    brightness(96%) contrast(85%);
  @include themed() {
    color: t(color-background-critical);
  }
}

.iconLabel {
  padding-top: 3px;
}
