@import 'styles/Colors';

.root {
  label {
    display: flex;
    margin: 1rem 0 0.2rem 0;
  }

  .text {
    padding: 6px 0;
  }

  .datePicker {
    margin: 0;
  }

  div.selectOption {
    min-width: 100px;
  }
}

.titleRoot {
  display: flex;
  align-items: center;

  .title {
    flex: 1;
  }

  button.iconButton {
    margin-right: 8px;
    padding: 0;

    &:hover {
      background-color: transparent !important;
    }
  }
}
