@import 'styles/design-tokens/Colors';

.tab {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  @include themed() {
    border: solid 1px t(color-border);
  }
  margin: 6px 0 6px 6px;
  padding: 5px;
  border-radius: 6px;
  width: 100%;
  box-sizing: border-box;
  @include themed() {
    background-color: t(background-color);
    color: t(text-color);
  }
  &.tab:hover {
    @include themed() {
      background-color: t(color-background-stronger);
    }
  }

  .title {
    margin: 0 5px 0 3px;
    text-overflow: clip;
    white-space: nowrap;
    text-align: left;
    overflow: hidden;
    max-width: 100%;
    width: 100%;
  }
  .notify {
    display: flex;
    flex-shrink: 0;
    margin-left: 3px;
    padding-right: 12px;
    @include themed() {
      color: t(color-primary);
    }
    background-image: url('/assets/inbox/ellipse.svg') !important;
    background-repeat: no-repeat;
    background-position: center right;
    border: solid 1px transparent;
  }
  .notifyWithoutCount {
    height: 8px;
  }
  .cancel {
    display: flex;
    flex-shrink: 0;
    margin-left: 3px;
    height: 18px;
    width: 18px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    background-image: url('/assets/inbox/x-default.svg') !important;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 10px;
    &.cancel:hover {
      background-image: url('/assets/inbox/x-hover.svg') !important;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 18px;
    }
  }

  .tabBtn {
    width: 100%;
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    @include themed() {
      color: t(color-text);
    }
  }

  svg {
    display: flex;
    flex-shrink: 0;
    margin-right: 3px;
    width: 20px !important;
  }

  &.active {
    margin-bottom: 0;
    border-radius: 6px 6px 0 0;
    padding-bottom: 11px;
    border-bottom: none;

    @include themed() {
      border: solid 1px t(color-border);
      background-color: t(color-surface-strong);
      &.active:hover {
        background-color: t(color-surface-strong);
      }
    }
  }
}
