@import 'styles/design-tokens/Colors.scss';

.container {
  display: flex;
  .tagsContainer {
    display: flex;
    margin-top: 3px;
    margin-right: 3px;
    flex-wrap: nowrap;

    .fusion {
      @include themed() {
        background-color: t(background-color-orange);
        color: t(color-background);
      }
    }

    .legacyMemberApp {
      @include themed() {
        background-color: t(background-color-gold);
        color: t(color-text);
      }
    }

    .headspaceEAP {
      @include themed() {
        background-color: t(color-text-highlight);
        color: t(color-text);
      }
    }

    .d2c {
      @include themed() {
        background-color: t(background-color-pale-green);
        color: t(color-background);
      }
    }

    .miscellaneousTag {
      @include themed() {
        background-color: t(color-foreground-weak);
        color: t(color-text-inverse-light);
      }
    }
  }
}
