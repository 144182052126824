@import 'styles/design-tokens/Typography';
@import 'styles/design-tokens/Colors';

.itemList {
  display: flex;
  flex-wrap: wrap;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
}

.item {
  width: 50%;
  padding-top: 1rem;
  display: flex;
  align-items: center;
}

.fullscreen {
  width: 100%;
}

.nameContainer {
  @include label-S;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 12px;
  gap: 4px;
  text-align: start;
}

.roleContainer {
  @include body-XS;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 12px;
  gap: 4px;
}

.specializationContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  @include utility-XS;
  text-transform: uppercase;
  padding-left: 12px;
  gap: 4px;
}

.specialization {
  @include themed() {
    background-color: t(color-status-success-strong);
    color: t(color-text-inverse-light);
  }
  border-radius: 3px;
  padding: 0px 2px;
  text-align: center;
}
