@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography.scss';
@import 'styles/Variables';

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6px 12px;
  gap: 8px;
  box-shadow: 0px 1px 8px 3px rgba(0, 0, 0, 0.15);
  @include themed() {
    background-color: t(color-background);
  }
  position: sticky;
  top: 60.5px;
  z-index: 1;
}

.riskAssessment {
  @include label-S;
  @include themed() {
    color: t(color-status-critical);
  }
}

.subTitle {
  @include body-S;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 100%;
}

.navigation_back {
  display: flex;
  width: 100%;
}

.coachNavContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  white-space: nowrap;
  align-items: center;
}

.navigation_container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 100%;
}

.description {
  font-family: $appFont;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  @include themed() {
    color: t(color-text);
  }

  &.riskAssessment {
    @include themed() {
      color: t(color-text-critical);
    }
  }
}

.dateContainer {
  margin-left: auto;
  display: flex;

  &.withMargin {
    margin-right: 8px;
  }
}

.coachDateContainer {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
}

.date {
  font-style: normal;
  @include body-XS;
  @include themed() {
    color: t(color-text-weak);
  }
  margin-top: 6px;
  margin-right: 5px;

  &.noMargin {
    margin-top: 0;
  }
}

.details_container {
  display: flex;
  align-items: center;
  height: 22px;
  font-size: 14px;
  line-height: 150%;
  padding: 4px 8px 4px;
  width: 100%;

  &.vertical {
    flex-direction: column;
    align-items: start;
  }
}

.nativation_button_container {
  margin: auto;
}

.tag_details {
  margin-left: 0.5em;
  margin-right: 0.5em;
  font-size: 12px;
  line-height: 150%;
  text-transform: uppercase;
}

.navigation_framebox {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 12px 0 8px;
  width: 100%;
  min-height: 33px;
  @include themed() {
    border: 1px solid t(color-interactive-stronger);
  }
  border-radius: 6px;
  justify-content: center;
}

.button_icon {
  padding-right: 0.5em;
}

.button_circular {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
  @include themed() {
    box-shadow: 0px 2px 0px t(shadow-default);
  }
  border-radius: 72px;
  border: 0;
  cursor: pointer;
}

.navigation__button {
  font-family: $appFont;
  font-size: 14px;
  line-height: 150%;
  @include themed() {
    color: t(color-text);
  }

  display: flex;
  align-items: center;
  text-align: center;

  background-color: transparent;
  border: none;
  cursor: pointer;
}

.tag {
  margin-top: 0;
  flex: 1;
}
