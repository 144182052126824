@import 'styles/design-tokens/Typography.scss';
@import 'styles/design-tokens/Colors.scss';

.message {
  @include body-XS;
  @include themed() {
    background-color: t(color-interactive-strong);
  }
  max-width: 280px;
  padding: 8px;
}
