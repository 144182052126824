@import 'styles/Colors';
@import 'styles/Mixins';
@import 'styles/design-tokens/Typography.scss';

.root {
  padding: 0 16px 16px;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;

  h2 {
    color: $steelBlue;
    margin-bottom: 1rem;
  }

  .loader {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-content: center;
  }

  .auditLogs {
    list-style: none;
    margin: 0;
    padding: 0;

    .log {
      &:first-child {
        padding-top: 0;
      }

      padding: 16px 0;

      .title {
        @include body-XS;
        font-weight: 700;
        margin-bottom: 8px;
        overflow-wrap: break-word;
      }

      .detail {
        @include body-XXS;
        display: flex;
        justify-content: space-between;
        color: $slate;
      }
    }
  }
}
