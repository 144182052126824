@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';

.readOnlyChat {
  @include themed() {
    background-color: t(color-background-weaker);
  }
  width: 100%;
  height: 48px;

  p {
    @include body-XS;
    @include themed() {
      color: t(color-surface);
    }
    text-align: center;
  }
}
