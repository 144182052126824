@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .text {
    @include body-XS;
    @include themed() {
      color: t(color-text-weaker);
    }
  }

  .img {
    max-width: 108px;
    margin-top: 80px;
  }
}
