@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography.scss';

.tooltip {
  .columns {
    flex-basis: content;
    max-width: initial;
  }
  table {
    td {
      padding: 0.5rem;
    }
  }
}

.dot {
  $size: 10px;
  display: inline-block;
  margin-right: 0.25rem;
  width: $size;
  height: $size;
  border-radius: $size;
  @include themed() {
    background-color: t(color-interactive-static-light-strong);
    &.active {
      &.red {
        background: t(color-status-critical);
      }
      &.yellow {
        background: t(color-status-warning);
      }
      &.grey {
        background-color: t(color-text-weaker);
      }
      &.blue {
        background-color: t(color-status-info);
      }
    }
  }
}

.chartTitle {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  .titleLabel {
    @include label-XS;
  }
}

.container {
  display: flex;
}
