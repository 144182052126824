@import 'styles/Colors';
@import 'styles/Variables';
@import 'styles/design-tokens/Typography.scss';
@import 'styles/design-tokens/Colors';

@mixin yesNoLabel {
  padding: 0;
  margin-right: 12px;
  margin-bottom: 4px;

  label {
    @include themed() {
      color: t(color-text);
    }
  }
}

.root {
  align-items: center;
  .label-XS {
    @include yesNoLabel;
    label {
      @include label-XS;
    }
  }
  .label-M {
    @include yesNoLabel;
    label {
      @include label-M;
    }
  }
  .label-L {
    @include yesNoLabel;
    label {
      @include label-L;
    }
  }
}

.radioButton {
  width: 90px;
}

span.radioRoot {
  @include themed() {
    color: t(background-color-legacy-tumeric);
  }
}
