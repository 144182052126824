@import 'styles/design-tokens/Colors';

.wrapper {
  min-width: 200px;
  padding-right: 16px;
  box-sizing: border-box;
  margin-top: 8px;

  .container {
    width: 100%;
    height: calc(100vh - 500px);
    min-height: 400px;
    @include themed() {
      background-color: t(color-background);
    }
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding-bottom: 16px;
    box-sizing: border-box;

    .content {
      box-sizing: border-box;
      padding: 0 4px 0 16px;
      flex: 1;
      overflow-y: auto;
      height: inherit;
    }
  }
}
