@import 'styles/design-tokens/Typography.scss';
@import 'styles/design-tokens/Colors.scss';

.session {
  display: flex;
  flex-wrap: wrap;
  .names {
    @include label-XS;
    display: flex;
    flex-basis: 100%;
    align-items: center;
    margin-bottom: 5px;
  }
  .duration {
    @include themed() {
      color: t(color-text-weak);
    }
    flex-basis: 100%;
    margin-top: 24px;
  }
  .lastBadge {
    @include label-XS;
    flex-basis: 100%;
    margin-top: 8px;
    .lastBadgeText {
      background: #ffb700;
      padding: 4px 8px;
      text-transform: capitalize;
    }
  }
}

.meetingHeader {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}

.copyZoomLink {
  margin-top: 5px;
}
