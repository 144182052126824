@import '../../../node_modules/react-big-calendar/lib/sass/styles';
@import 'styles/Variables';
@import 'styles/design-tokens/Colors';

.rbc-calendar {
  font-family: $appFont;

  height: 50vh !important;

  .rbc-allday-cell {
    display: none;
  }
}
.rbc-events-container {
  .rbc-event {
    border: none;
    @include themed() {
      outline: 1.5px solid t(background-color);
    }
    &.intakeAppointment {
      background-color: $sea;
      border: 2px solid $sea !important;
      &.rbc-selected {
        @include themed() {
          background-color: t(color-background);
        }
        color: $sea;
      }
    }
  }
}
