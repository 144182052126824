@import 'styles/design-tokens/Colors.scss';
@import 'styles/design-tokens/Typography.scss';

.emptySectionWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: auto;
    height: 70px;
    padding-top: 1rem;
  }

  p {
    @include body-S;
    @include themed() {
      color: t(color-text-weaker);
    }
  }
}
