/*** Apercu Pro ***/

@font-face {
  src: url('/assets/fonts/Apercu-Pro/Apercu-Pro-Black.otf') format('opentype');
  font-weight: 800;
  font-family: 'Apercu Pro';
}

@font-face {
  src: url('/assets/fonts/Apercu-Pro/Apercu-Pro-Black-Italic.otf')
    format('opentype');
  font-weight: 800;
  font-family: 'Apercu Pro';
  font-style: italic;
}

@font-face {
  src: url('/assets/fonts/Apercu-Pro/Apercu-Pro-Bold.otf') format('opentype');
  font-weight: 700;
  font-family: 'Apercu Pro';
}

@font-face {
  src: url('/assets/fonts/Apercu-Pro/Apercu-Pro-Bold-Italic.otf')
    format('opentype');
  font-weight: 700;
  font-family: 'Apercu Pro';
  font-style: italic;
}

@font-face {
  src: url('/assets/fonts/Apercu-Pro/Apercu-Pro-Medium.otf') format('opentype');
  font-weight: 500;
  font-family: 'Apercu Pro';
}

@font-face {
  src: url('/assets/fonts/Apercu-Pro/Apercu-Pro-Medium-Italic.otf')
    format('opentype');
  font-weight: 500;
  font-family: 'Apercu Pro';
  font-style: italic;
}

@font-face {
  src: url('/assets/fonts/Apercu-Pro/Apercu-Pro-Regular.otf') format('opentype');
  font-weight: 400;
  font-family: 'Apercu Pro';
}

@font-face {
  src: url('/assets/fonts/Apercu-Pro/Apercu-Pro-Regular-Italic.otf')
    format('opentype');
  font-weight: 400;
  font-family: 'Apercu Pro';
  font-style: italic;
}

/*** Apecru ***/

@font-face {
  src: url('/assets/fonts/Apercu/Apercu.otf') format('opentype');
  font-weight: 400;
  font-family: 'Apercu';
}

@font-face {
  src: url('/assets/fonts/Apercu/Apercu-Italic.otf') format('opentype');
  font-weight: 400;
  font-family: 'Apercu';
  font-style: italic;
}

@font-face {
  font-family: 'Headspace Apercu';
  font-style: normal;
  font-weight: 400;
  src: url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-Regular.otf'),
    /* IE9 Compat Modes */
      url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-Regular.otf?#iefix')
      format('opentype'),
    /* IE6-IE8 */
      url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-Regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-Regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-Regular.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Headspace Apercu';
  font-style: normal;
  font-weight: 400;
  font-style: italic;
  src: url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-Italic.otf'),
    /* IE9 Compat Modes */
      url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-Italic.otf?#iefix')
      format('opentype'),
    /* IE6-IE8 */
      url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-Italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-Italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-Italic.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Headspace Apercu';
  font-style: bolder;
  font-weight: 600;
  src: url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-Medium.otf'),
    /* IE9 Compat Modes */
      url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-Medium.otf?#iefix')
      format('opentype'),
    /* IE6-IE8 */
      url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-Medium.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-Medium.woff')
      format('woff'),
    /* Modern Browsers */
      url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-Medium.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Headspace Apercu';
  font-style: bolder;
  font-weight: 600;
  font-style: italic;
  src: url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-MediumItalic.otf'),
    /* IE9 Compat Modes */
      url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-MediumItalic.otf?#iefix')
      format('opentype'),
    /* IE6-IE8 */
      url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-MediumItalic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-MediumItalic.woff')
      format('woff'),
    /* Modern Browsers */
      url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-MediumItalic.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Headspace Apercu';
  font-style: lighter;
  font-weight: 200;
  src: url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-Light.otf'),
    /* IE9 Compat Modes */
      url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-Light.otf?#iefix')
      format('opentype'),
    /* IE6-IE8 */
      url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-Light.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-Light.woff')
      format('woff'),
    /* Modern Browsers */
      url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-Light.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Headspace Apercu';
  font-style: lighter;
  font-weight: 200;
  font-style: italic;
  src: url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-LightItalic.otf'),
    /*TODO*/ /* IE9 Compat Modes */
      url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-LightItalic.otf?#iefix')
      /*TODO*/ format('opentype'),
    /* IE6-IE8 */
      url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-LightItalic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-LightItalic.woff')
      format('woff'),
    /* Modern Browsers */
      url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-LightItalic.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Headspace Apercu';
  font-style: bold;
  font-weight: 700;
  src: url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-Bold.otf'),
    /* IE9 Compat Modes */
      url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-Bold.otf?#iefix')
      format('opentype'),
    /* IE6-IE8 */
      url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-Bold.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-Bold.woff')
      format('woff'),
    /* Modern Browsers */
      url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-Bold.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Headspace Apercu';
  font-style: bold;
  font-weight: 700;
  font-style: italic;
  src: url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-BoldItalic.otf'),
    /* IE9 Compat Modes */
      url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-BoldItalic.otf?#iefix')
      format('opentype'),
    /* IE6-IE8 */
      url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-BoldItalic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-BoldItalic.woff')
      format('woff'),
    /* Modern Browsers */
      url('https://static.headspace.com/fonts/apercu-v1.002/HeadspaceApercu-BoldItalic.ttf')
      format('truetype');
}
