@import 'styles/design-tokens/Typography';

.icon {
  width: 16px;
  height: 16px;
  vertical-align: bottom;
  align-self: end;
  margin-left: 3px;
  @include label-XXXS;
}
