@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography.scss';

.container {
  margin: 0 auto;
  padding: 16px 16px 4px 16px;

  .title {
    padding: 0 16px 12px 0;
    @include label-M;
    @include themed() {
      color: t(color-status-warning);
    }
    display: flex;
    align-items: center;
  }

  .picture {
    width: 18px;
    height: 22px;
    margin: 0 0 0 6px;
  }

  .body {
    padding: 0 0 12px 0;
  }

  .subtitle {
    margin: 0;
    @include label-S;
    @include themed() {
      color: t(color-text-weak);
    }
  }

  .list {
    margin: 0 0 4px 0;

    .item {
      @include body-S;
      @include themed() {
        color: t(color-text);
      }
    }
  }

  .footer {
    padding: 0;
  }

  .butReportButtonContainer {
    display: contents;
  }

  &.smallError {
    .title {
      @include label-S;
      padding-bottom: 4px;
    }
    .subtitle {
      @include label-XS;
    }
    .list .item {
      @include body-XS;
    }
  }
  .errorText {
    margin: 0;
    padding: 0 0 16px;
    @include label-S;
  }
}
