@import 'styles/design-tokens/Colors.scss';
@import 'styles/design-tokens/Typography.scss';

$cardMaxHeight: 500px;
$cardHeaderMaxHeight: 36px;

.box {
  @include themed() {
    background-color: t(color-background);
  }

  border-radius: 6px;
  box-shadow: 0px 3px 8px 2px rgba(135, 100, 30, 0.15);
  position: relative;
  min-width: 290px;
  margin-top: 16px;

  &-small {
    max-height: $cardMaxHeight * 0.6; // 300px
  }
  &-medium {
    max-height: $cardMaxHeight; // default size
  }
  &-large {
    max-height: inherit;
    min-width: 370px;
  }

  .toggleButton {
    border: none;
    @include themed() {
      color: t(color-status-info);
      background-color: t(color-background);
    }
    align-self: center;
    width: 28px;
    svg {
      cursor: pointer;
      align-self: center;
      font-size: 3rem;
    }
  }

  .actionMenu {
    display: flex;
    border: none;
    @include themed() {
      background-color: t(color-background);
    }
    align-self: center;
    position: absolute;
    right: 24px;
  }

  .boxHeader {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: initial;
    border-bottom-right-radius: initial;
    border: none;
    @include themed() {
      background-color: t(color-background);
      border-bottom: 1px solid t(color-status-info);
    }
    display: flex;
    min-height: $cardHeaderMaxHeight;
    padding-top: 6px;

    position: sticky;
    top: 0;
    z-index: 1000;

    .boxTitle {
      @include label-S;
      @include themed() {
        color: t(color-text-link);
      }
      margin-block-start: 0;
      margin-block-end: 0;
      align-self: center;
      text-align: left;
      margin-left: 32px;
    }
  }

  .boxContent {
    border-radius: 0 0 8px 8px;
    padding: 0;
    box-sizing: border-box;
    background: inherit;

    &-small {
      max-height: calc(#{$cardMaxHeight * 0.6} - #{$cardHeaderMaxHeight});
    }
    &-medium {
      max-height: calc(#{$cardMaxHeight} - #{$cardHeaderMaxHeight});
    }
    &-large {
      max-height: inherit;
    }

    .MuiCollapse-wrapper {
      background: inherit;

      .MuiCollapse-wrapperInner {
        background: inherit;
      }
    }
  }

  .boxContent::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 6px;
  }

  .boxContent::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  .boxContent::-webkit-scrollbar:vertical {
    width: 10px;
  }

  .boxContent::-webkit-scrollbar-thumb {
    border-radius: 6px;
    border-width: 3px 2px;
    border-style: solid;
    @include themed() {
      border-color: t(color-surface);
      background-color: t(color-interactive-strongest);
    }
  }

  .cardSection {
    padding: 16px;
  }

  .disabled {
    @include themed() {
      color: t(color-interactive-strongest);
    }
  }
}
