@import 'styles/design-tokens/Colors';
@import 'styles/Variables';
@import 'styles/design-tokens/Typography.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 13px;
}

.button_add {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  height: 2em;
  @include themed() {
    box-shadow: 0px 2px 0px t(shadow-default);
  }
  border-radius: 72px;
  border: 0;
  cursor: pointer;
  width: fit-content;
  &:disabled {
    cursor: default;
  }
}

.orange {
  @include themed() {
    background-color: t(color-text-critical);
  }
}

.blue {
  @include themed() {
    background-color: t(color-primary);
  }
}

.button_add__label {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1em 0px;
  gap: 8px;

  @include body-XS;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;

  @include themed() {
    color: t(color-text-inverse-light);
  }
}

.button_add__icon {
  transform: rotate(45deg);
}

.line {
  padding: 0px;
  gap: 12px;
  width: 170px;
  height: 0px;
}

hr {
  margin: 0;
  border: 0;
}

.border_orange {
  @include themed() {
    border-top: 1px solid t(color-text-critical);
  }
}

.border_blue {
  @include themed() {
    border-top: 1px solid t(color-primary);
  }
}
