@import 'styles/Colors';
@import 'styles/Variables';
@import 'styles/design-tokens/Typography.scss';

div.root {
  display: flex;

  .label {
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .checkbox {
    color: $primary;
  }

  .checkboxLabel {
    color: $slate;
    @include body-XXS;
  }

  .disabled {
  }

  .checkbox ~ .checkboxLabel {
    font-weight: 700;
  }
}
