@import 'styles/design-tokens/Colors.scss';
@import 'styles/design-tokens/Typography.scss';

.accordionContainer {
  padding: 6px 6px 0 6px;
}

.accordionContainer:last-child {
  padding: 6px;
}

.accordionHeader {
  border: none;
  @include themed() {
    border-top: 1px solid t(color-foreground-weak);
    background-color: t(color-interactive-strong);
  }
  display: flex;
  padding: 12px 16px 12px;
  text-align: left;
  height: 38.4px;
  width: 100%;
  cursor: pointer;
  @include label-XS;
  @include themed() {
    color: t(color-text-weaker);
  }
  position: sticky;
  z-index: 10;
  border-radius: 8px;

  .accordionTitle {
    align-self: center;
    flex: 1;
  }
  .expandIcon {
    align-self: center;
    height: 12px;
    margin-left: 8px;
    width: 13px;
    transform: rotate(90deg);
    transition: transform 400ms;
    svg {
      font-size: 1.5rem;
      left: -6px;
      margin: 0;
      padding: 0;
      position: relative;
      top: -6px;
    }
  }

  .rotate90deg {
    transform: rotate(0deg) !important;
    transition: transform 400ms;
  }
}

.accordionHeader:first-of-type {
  border: none;
}

.accordion {
  @include themed() {
    background-color: t(color-background);
  }
  & ~ & {
    border-radius: 0 !important;
  }

  .accordionContent {
    display: flex;
    flex-flow: column nowrap;
    padding: 0;
  }

  .paddingTop {
    padding-top: 12px;
  }
}
