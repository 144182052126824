@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography.scss';

.chip {
  height: 21px;
  padding: 0 9px;

  @include themed() {
    background-color: t(color-status-critical);
  }

  span {
    @include label-XXS;
    @include themed() {
      background-color: t(color-status-critical);
    }

    height: 14px;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  svg {
    height: 16px;
    width: auto;
  }
}

.flagText {
  @include label-XXS;
  @include themed() {
    color: t(color-text-inverse-light) !important;
  }
}
