@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';

.cardSection {
  padding: 16px;
  padding-top: 0;
  margin-top: 8px;
}

.cardSectionNoLabel {
  padding: 16px;
  padding-top: 0;
  word-break: break-word;
}

.orgDetails {
  p {
    margin-top: 0;
  }

  pre {
    white-space: pre-wrap;
  }

  .markdownH1 {
    @include label-S;
    @include themed() {
      color: t(color-text);
    }
  }

  .markdownH2 {
    @include label-XXS;
    @include themed() {
      color: t(color-text);
    }
    margin-top: 10px;
  }

  .markdownBodyText {
    @include body-XS;
    @include themed() {
      color: t(color-text-weak);
    }
  }

  .markdownCodeBlock {
    @include body-XXS;
    @include themed() {
      color: t(color-text);
      background-color: t(color-interactive-strong);
    }
    display: block;
    padding: 12px;
  }

  .markdownLink {
    @include link-XS;
    @include themed() {
      color: t(color-text-link);
    }
  }
}
