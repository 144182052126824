@import 'styles/Colors';
@import 'styles/Mixins';

.root {
  .emptyCell {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  }

  tr.rowSelected,
  tr.rowSelected:hover {
    background-color: $mediumGray;
  }

  td.cell {
    &.actionMenu:last-child {
      padding: 0;
    }

    @include app-font(13px, 16px);
    color: $mediumSlate;
  }
}
