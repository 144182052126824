@import 'styles/Colors';
@import 'styles/Mixins';
@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography.scss';

.root {
  .textArea {
    textarea {
      font-size: 16px;
    }
  }

  .appointment {
    display: flex;
    margin-top: 30px;
    margin-left: 60px;
  }
  .close {
    font-size: large;
  }

  .buttons {
    button {
      margin: 10px;
    }
    .save {
      width: 94px;
      height: 32px;
      text-transform: none;
    }
    .saveBtn {
      width: 94px;
      min-height: 40px;
      text-transform: none;
    }
    .actionsBtn {
      margin-left: 10px;
      border: 2px solid $noteTabTitle;
      width: 94px;
      max-height: 40px;
      color: $noteTabTitle;
      &:disabled {
        opacity: 0.5;
      }
    }
  }

  .flex {
    display: flex;
    .input {
      margin: 0 16px 0 0;
    }
  }

  div.datePicker {
    margin: 0 16px 0 0;
    width: 12rem;
  }

  .alert {
    margin-bottom: 16px;
    max-width: 45rem;
  }
  .member {
    width: 40rem;
  }
  .memberLink {
    margin-top: 8px;
    margin-bottom: 8px;
    @include themed() {
      color: t(color-text-link);
    }
  }
  .timezone {
    display: flex;
    vertical-align: center;
    align-items: center;
    margin-left: 1rem;
    white-space: nowrap;
    a {
      @include themed() {
        color: t(color-text-link);
      }
    }
  }

  .controlGroup {
    margin-top: 24px;
  }

  .marginAfter {
    margin-right: 1rem;
  }

  .marginBeforeAndAfter {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .formText {
    margin-top: 1rem;
    @include themed() {
      color: t(color-text);
    }
  }
}

.deleteModalContainer {
  h2 {
    display: flex;
    @include body-S;
    @include themed() {
      background-color: t(color-background-strong);
    }
    padding: 8px;
    align-items: center;
    margin-bottom: 24px;
  }

  span {
    margin-left: 15px;
  }
}
