@import 'styles/Colors';
@import 'styles/Variables';

div.loader {
  justify-content: unset;
  padding: 12px 0;
}

.root {
  .item {
    color: $mediumSlate;
    padding: 0.5rem 1rem;
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
      opacity: 20%;
    }

    &:not(.disabled):hover {
      background-color: $fadedSlate;
    }
  }
}
