@import 'styles/Colors';
@import 'styles/design-tokens/Typography.scss';

.patientList {
  // using sticky to keep the list in place
  height: 100%;
  overflow: auto;
  position: sticky;
  top: 0;
  border-bottom: 1px solid $gridBorder;

  display: flex;
  flex-direction: column;
  background: $gridBackgroundColor;

  h1 {
    color: $fadedSlate;
    margin-left: 1rem;
  }
  input[type='checkbox'] {
    margin-right: 10px;
  }

  button {
    display: flex;
    width: 100%;
    text-align: left;
    margin: 0;
    padding: 1rem;
    border: none;
    @include label-XS;
    color: $darkSlate;
    outline: none;
    background: transparent;
    cursor: pointer;
    transition: background-color 0.2s;
    align-items: center;
    line-height: 17px;

    div {
      flex: 1;
    }

    small {
      display: block;
      font-weight: normal;
      color: $slate;
      line-height: 15px;
    }
    &:hover:not(.emptyList) {
      background-color: $fadedTumeric;
    }

    &.active {
      background-color: $mediumGray;
    }
    .subtitle {
      display: flex;
      align-items: center;
      text-align: center;

      .memberpill {
        color: #ffffff;
        border-radius: 20px;
        display: inline-block;
        font-size: 10px;
        line-height: 14px;
        text-align: center;
        padding: 2px 6px;

        flex: none;
        margin-right: 5px;

        &.spanish {
          background: #4379b6;
        }

        &.french {
          background: #235949;
        }

        &.teen {
          background: #666666;
        }
      }
    }
  }
}

.time {
  align-self: flex-end;
  text-align: right;
  font-size: 9px;
  line-height: 11px;
}

.expando {
  &.root {
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.15);
    background-color: $gridBackgroundColor;
    :before {
      background-color: transparent;
    }
  }
  &.header {
    font-size: 14px;
    padding: 0 1rem;
    div {
      padding: 0;
      margin: 0;
    }
  }
  &.content {
    flex-direction: column;
    padding: 0;
  }
}
