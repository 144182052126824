@import 'styles/design-tokens/Colors';
@import 'styles/Variables';
@import 'styles/design-tokens/Typography.scss';

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 0px;
  gap: 13px;
}

.codeSetHeader {
  @include themed() {
    color: t(color-primary);
  }
  font-size: 18px;
}

.line {
  padding: 0px;
  gap: 12px;
  width: 170px;
  height: 0px;
  flex: 1;

  hr {
    margin: 0;
    border: 0;
    @include themed() {
      border-top: 1px solid t(color-primary);
    }
  }
}

.removeButton {
  cursor: pointer;
  @include themed() {
    fill: t(color-text-weak);
  }
}

.addButton {
  margin-bottom: 1.5rem;

  button {
    &:disabled {
      opacity: 0.5;
    }
  }
}

.menuItem {
  white-space: pre;

  &:hover {
    @include themed() {
      background-color: t(color-background-tag-cloud);
    }
  }

  &:focus {
    @include themed() {
      color: t(color-text-inverse-light);
      background-color: t(color-primary);
    }
  }
}

.enumDropdown + div[role='presentation'] li {
  white-space: normal;

  &:hover {
    @include themed() {
      background-color: t(color-background-tag-cloud);
    }
  }

  &:focus {
    @include themed() {
      color: t(color-text-inverse-light);
      background-color: t(color-primary);
    }
  }
}

.selectClass {
  white-space: pre !important;
}
