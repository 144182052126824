#actionBar {
  margin-bottom: 1rem;
  margin-top: 1rem;

  .newAppointment {
    padding-left: 23px;
    padding-right: 33px;
    span {
      font-size: 14px;
      text-transform: none;
    }
  }
}
