@import 'styles/Colors';
@import 'styles/design-tokens/Colors';

.label {
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;

  @include themed() {
    color: t(color-background);
  }
  background: $fadedSlate;

  &.active {
    background: $sunset;
  }
}
