@import 'styles/design-tokens/Colors.scss';
@import 'styles/design-tokens/Typography';

.container {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  border-radius: 50px;
  @include themed() {
    background-color: t(color-status-info-strong);
    color: t(color-text-inverse-light);
  }
  text-align: center;
}

.container_transparent {
  background-color: transparent;
}
