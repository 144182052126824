@import 'styles/Colors';

$padding: 0.25rem;

#eventTypeGroup {
  .label {
    padding: 0.25rem;
    margin-left: 0px;
  }
}
