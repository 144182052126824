@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography.scss';

.container {
  padding: 32px 20px 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .title {
    margin: 0 0 12px 0;
    @include label-M;
    @include themed() {
      color: t(color-status-warning);
    }
  }

  .subtitle {
    margin: 0;
    @include label-S;
    @include themed() {
      color: t(color-text);
    }
  }

  .text {
    @include body-S;
    @include themed() {
      color: t(color-text-weak);
    }
  }

  .picture {
    margin: 60px auto;
    width: 350px;
    height: 256px;
  }

  .list {
    margin: 0 0 4px 0;

    .item {
      @include body-S;
      @include themed() {
        color: t(color-text);
      }
    }
  }

  .bugBtn {
    margin-top: 28px;
  }
}
