@import '~@ginger.io/core-ui/dist/styles/Colors';
@import 'Variables';
@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';

p.subject {
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 5px;
}

.subheader {
  font-size: 11px;
  line-height: 14px;
  margin-top: 0;
  margin-bottom: 15px;
}

.messageBody {
  font-size: 13px;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 25px;

  .moreOrLess {
    color: $steelBlue;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.container {
  display: flex;
  margin: 10px 0;
  color: $darkSlate;
}

.leftContainer {
  display: flex;
  flex-direction: row-reverse;
  padding: 12px;
  width: $avatarWidth;
  box-sizing: border-box;
}

.rightContainer {
  background: $white;
  @include themed() {
    border: 0.5px solid t(color-border-strong);
    box-shadow: 0 4px 4px t(background-color-black-opaque);
  }
  border-radius: 3px;
  box-sizing: border-box;
  padding: 0 24px;
  flex: 1;
}

.moreOrLess {
  border: none;
  @include themed() {
    color: t(color-primary);
  }
  cursor: pointer;
  text-decoration: underline;
  background-color: inherit;
}
