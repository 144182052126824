.buttonContainer {
  display: inline-block;
  margin-left: 20px;
}

.passwordContainer {
  margin-top: 40px;
}

.password {
  display: inline-block;
  font-weight: bold;
}
