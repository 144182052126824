@import 'styles/design-tokens/Colors';
@import 'styles/Variables';
@import 'styles/design-tokens/Typography.scss';

.container {
  padding: 0px;
  margin-bottom: 18px;
}

.container_label {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 13px;
}

.label {
  @include label-M;
  @include themed() {
    color: t(color-text-critical);
  }
  margin-block-end: 4px;
}

.subtext {
  @include label-XS;
  @include themed() {
    color: t(color-text-critical);
  }
  margin: 0;
}

.line {
  padding: 0px;
  gap: 12px;
  width: 170px;
  height: 0px;
  padding-top: 0.7em;
}

hr {
  margin: 0;
  border: 0;
  @include themed() {
    color: t(color-text-critical);
  }
}
