.uploadRow {
  margin: 15px 0;

  .sizeText {
    font-size: 12px;
    display: flex;
    margin-top: 9px;
  }

  .selectRoot {
    padding: 8px;
    font-size: 12px;
    width: 350px;
  }

  .textField {
    input {
      padding: 11px;
      font-size: 12px;
    }
  }
}
