@import 'styles/Variables';

.menuItemRoot {
  font-family: $appFont !important;
  font-size: 12px !important;
  line-height: 15px !important;
  align-items: center !important;
}

.iconButton {
  padding: 8px !important;
  margin: 0 0 0 auto !important;
}

.menuItemContent {
  min-width: 100px;
}

.menuContainer {
  display: flex;
}
