@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';

.container {
  display: flex;
  flex-direction: column;
  width: auto;
  //position: absolute;
  //bottom: 0;
  margin-bottom: 10px;

  .notification {
    @include body-XS;
    border-radius: 2px;
    @include themed() {
      background-color: t(color-surface);
    }
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
    padding: 5px;
  }
}

.popper {
  .tooltip {
    margin-top: 4px !important;
    inset: 12px auto 0 auto;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    border-radius: 1px;
    @include themed() {
      background-color: t(color-surface);
    }
    @include body-XS();
    padding: 2px 8px;
    @include themed() {
      color: t(color-text-weak);
    }
  }
}
