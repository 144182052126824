@import 'styles/design-tokens/Colors.scss';
@import 'styles/design-tokens/Typography.scss';

.addSICoachModalButton {
  box-shadow: 0px 2px 0px rgba(26, 25, 25, 0.2);
  border-radius: 72px;
  height: 32px;
  padding: 0px 24px;
  width: auto;
  justify-content: center;
  align-items: center;
  display: flex;

  &.light {
    @include themed() {
      background-color: t(color-interactive-strong);
      color: t(color-text-inverse-dark);
    }
  }
}
