@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography.scss';

.emptyState {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 25px;

  .calendarImage {
    padding: 16px 0;
    max-width: 200px;
  }
  .noScheduled {
    @include body-XS;
    text-align: center;
    @include themed() {
      color: t(color-text-weaker);
    }
  }
}
.createContainer {
  margin: 12px 16px;
  .dateContainer {
    margin-bottom: 10px;
    &.noMargin {
      margin-bottom: 0;
    }
    .noteRoot {
      margin: 0;
    }
    .noteLabel {
      display: flex;
      margin-bottom: 4px;
    }
    .dateLabel {
      @include label-XS;
      margin-bottom: 4px;
    }
    .datePicker {
      width: 100%;
    }
    .datePickerInput {
      height: 24px;
      padding: 8px 32px 8px 8px;
    }
  }
  .timeContainer {
    margin-bottom: 20px;
    .timeLabel {
      @include label-XS;
      margin-bottom: 4px;
    }
  }
  .occurrenceForm {
    padding: 16px 0;

    .grid {
      @include body-XS;
      display: flex;
      align-items: center;
      padding: 2px 8px;
      .numberInput {
        display: flex;
        margin: 0 8px;
        input {
          text-align: center;
          height: 24px;
          width: 60px;
          padding: 8px;
        }
      }

      .label {
        @include themed() {
          color: t(color-text);
        }
        @include label-XS;
      }
    }
  }

  // TODO: remove .cancelButton in favor of .cancelSession after MARKET-3453 is released
  // and enable_coaching_scheduler is enabled for everyone
  .cancelButton {
    margin-top: 24px;
    box-shadow: 0 2px 0 rgba(26, 25, 25, 0.2);
    border-radius: 72px;
    @include themed() {
      color: t(color-surface);
      background-color: t(color-status-critical);
    }
    @include label-S;
  }
  .cancelSession {
    @include label-S;
    height: 32px;
    margin-top: 9px;
    margin-bottom: 5px;
    border-radius: 999px;
    @include themed() {
      color: t(color-surface);
      background-color: t(color-status-critical);
      border-color: t(color-status-critical);
      box-shadow: 0 2px 0 0 t(shadow-default);
    }
  }
}

.fab {
  margin-left: 7px;
  margin-right: 2px;
  height: 24px;
  text-transform: none;

  .fabText {
    @include label-XS;
    margin: 0 15px;
  }

  &:disabled {
    opacity: 0.26;
    @include themed() {
      background-color: t(color-primary);
      color: t(color-background);
      box-shadow: 0 2px 0 t(shadow-default);
    }
    height: 24px;
    width: 97px;
  }
}

.recurrenceSection {
  margin-top: -20px;
  margin-bottom: 16px;
  .grid {
    @include body-XS;
    display: flex;
    align-items: center;
    padding: 2px 8px !important;

    .numberInput {
      display: flex;
      flex-direction: column;
      width: 100% !important;
      input {
        text-align: center !important;
        height: 24px;
        padding: 8px;
      }
      p {
        margin-left: calc(-100% + 10px);
        margin-right: -100%;
      }
    }
    .label {
      @include themed() {
        color: t(color-text);
      }
      @include label-XS;
    }
  }
}

.root {
  margin: 6px 0 10px 0;
  width: 100%;
  display: flex;
  flex-direction: column;

  &.extraMargin {
    margin: 16px 0;
  }
}

.schedulingLabel {
  display: flex;
  margin: 10px 0 6px 0;
  @include themed() {
    color: t(color-text);
  }
  @include label-XS;
}

.sessionCountContainer {
  display: grid;
  grid-template-columns: 130px 60px;
  align-items: baseline;
  height: 40px;
}

.numberField {
  input {
    height: 24px !important;
    text-align: left !important;
    padding: 8px;
  }
}

.checkAvailability {
  @include label-S;
  height: 32px;
  text-transform: none;
  width: 100%;
  margin: 18px 9px 0 0;
  border-radius: 999px;
  @include themed() {
    background-color: t(color-primary);
    color: t(color-background);
    box-shadow: 0 2px 0 0 t(shadow-default);
  }
}

.withMargin {
  margin-bottom: 10px;
}

.reminderInfoBoxV1 {
  margin-top: 12px;
}

.reminderInfoBox {
  margin: 15px;
  width: 100%;
}

.sessionReminderBox {
  margin: 12px 16px;
}

.schedulerInfoBox {
  width: auto;
  margin-top: 12px;
}

.videoEligibilityBox {
  margin-top: 0;
}

.gcalLink {
  @include label-S;
  display: flex;
  align-items: center;
  @include themed() {
    color: t(color-text-link);
  }
  text-decoration: none;
  padding-top: 8px;
  padding-bottom: 12px;
  width: fit-content;
}

.calendarIcon {
  margin-left: 4px;
}

.sessionInfoContainer {
  margin-bottom: 12px;
}

.displayTimeContainer {
  margin-top: 20px;
  margin-bottom: 8px;

  h4 {
    margin-bottom: 10px;
  }
}

.serviceNowBtnContainer {
  margin-top: 18px;
}

.timeOffRequest {
  @include label-XS;
}

.dateContainerV2 {
  margin-top: 15px;
  margin-bottom: 0;

  .noteRoot {
    margin: 0;
  }
  .noteLabel {
    display: flex;
    margin-bottom: 4px;
  }
  .dateLabel {
    @include label-XS;
    margin-bottom: 4px;
  }
  .datePicker {
    width: 100%;
  }
  .datePickerInput {
    height: 24px;
    padding: 8px 32px 8px 8px;
  }
}

.dropdownMenuItem {
  @include body-XS;
}

.optionText {
  @include body-S;
}

.optionContainer {
  display: flex;
  align-items: center;
}

.optionIcon {
  margin-right: 8px;
  margin-bottom: 2px;
}

.zoomLinkContainer {
  margin-top: 20px;
}

.copyZoomLink {
  margin-top: 5px;
}
