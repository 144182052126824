@import 'styles/Colors';
@import 'styles/Mixins';

.documentUpload {
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    justify-content: flex-end;

    .uploadBtn {
      background-color: $steelBlue;
      text-transform: capitalize;
    }
  }
}

.documentUploadV2 {
  @extend .documentUpload;
  padding: 16px;
}
