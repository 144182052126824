.container {
  width: 100%;
  box-sizing: border-box;
  margin-left: -5px;
}

.masonryGrid {
  display: flex;
  width: auto;
  padding-right: 5px;
  box-sizing: border-box;
}

.column {
  background-clip: padding-box;
  box-sizing: border-box;
}

.masonryItem {
  padding-left: 5px;
  margin-bottom: 12px;
  box-sizing: border-box;
}
