@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';

.textareaGroup {
  flex-shrink: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 0 8px;
  @include themed() {
    background-color: t(color-background);
  }
  .textareaContainer {
    position: relative;

    .timerButton {
      width: 28px;
      height: 28px;
      position: absolute;
      bottom: 5px;
      right: 5px;
      box-shadow: 0px 2px 0px rgba(26, 25, 25, 0.2);
      border-radius: 39px;
      border: none;
      cursor: pointer;
      @include themed() {
        background-color: t(color-background);
      }
      .timerIcon {
        width: 20px;
        height: 20px;
        display: block;
        padding-right: 2px;
        box-sizing: border-box;
      }
    }
  }
}
