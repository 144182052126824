@import 'styles/Colors';

@mixin borderLeft($color) {
  border-left: 10px solid $color;
}

div.root {
  .contentRoot {
    &.success {
      @include borderLeft($teal);
    }

    &.error {
      @include borderLeft($sunset);
    }
  }
}
