@import 'styles/Colors';
@import 'styles/Mixins';
@import 'styles/design-tokens/Colors';

span.root {
  &.checkbox {
    @include themed() {
      color: t(background-color-legacy-tumeric);
    }
  }
}
