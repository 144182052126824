@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography.scss';

.pillList {
  width: 100%;
  margin-top: 16px;
}

.pill {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0;
  padding: 20px 16px 20px 32px;
  @include themed() {
    background-color: t(color-interactive-strong-alt);
    color: t(color-foreground);
  }
  border-radius: 999px;
  border: 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: 8px;

  &:hover {
    @include themed() {
      background-color: t(color-interactive-light);
    }
  }
}

.pillLabel {
  @include label-S;
}

@mixin radioInputAfter {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.radioInput {
  padding: 0;

  @include themed() {
    color: t(color-interactive-stronger-alt);
  }

  svg {
    @include themed() {
      stroke: t(color-interactive-stronger-alt);
    }
  }

  &::after {
    @include radioInputAfter;
    width: 15px;
    height: 15px;
    @include themed() {
      background-color: t(color-interactive-stronger-alt);
    }
  }
}

.checked {
  @include themed() {
    color: t(color-primary);
  }

  svg {
    @include themed() {
      stroke: t(color-primary);
    }
    stroke-width: 4px;
    transition: none !important;
    animation: none !important;
  }

  &::after {
    @include radioInputAfter;
    width: 8px;
    height: 8px;
    @include themed() {
      background-color: t(color-background);
    }
  }
}
