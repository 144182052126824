@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';
@import 'styles/Variables';

.content {
  display: flex;
  @include themed() {
    color: t(color-text);
  }
  box-sizing: border-box;
  height: $memberHeaderHeight;

  .mutationState {
    display: inline-block;
    margin: 0 3px;
  }

  .demographics {
    padding: 24px 16px;
    .demographicsTop {
      display: flex;
      flex-wrap: nowrap;
      column-gap: 3px;
    }
    .bottomLineContainer {
      display: flex;
      //this is needed to prevent wrapping of elements that leads to losing the chat text area
      min-width: 254px;
      flex-direction: column;

      .moreInfo {
        @include body-S;
        text-wrap: nowrap;
        text-decoration: underline;
        cursor: pointer;
        text-underline-offset: 3px;
      }
    }
    .row {
      flex-direction: row;
    }
    .demographicsItem {
      margin: 0 3px;
      @include body-S;
      text-wrap: nowrap;
    }
    .block {
      display: block;
    }

    .memberName {
      @include label-M;
      line-height: 1.4;
    }

    @include themed() {
      .bottomLine {
        color: t(color-text-weak);
      }
      .noName {
        color: t(color-text-weakest);
      }
    }
  }
}

.tooltipText {
  @include body-XS;
}

.bar {
  @include themed() {
    background-color: t(color-surface-strong);
  }
  box-shadow: 0px 2px 3px rgba(135, 100, 30, 0.17);
  clip-path: polygon(0% 0%, 100% 0%, 100% 120%, 0% 120%);
}

.avatar {
  height: 60px;
  width: 60px;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 24px;
  margin-right: 0px;
  font-size: 2rem;
}

.dobTooltip {
  @include body-XXS;
}

.coverageTooltip {
  @include body-XXS;
}
