@import 'styles/Colors';
@import 'styles/design-tokens/Colors';

.checkbox {
  &.unchecked {
    color: $gridBorderColor;
  }

  &.unchecked.steelBlue {
    color: $steelBlue;
  }

  &.unchecked.pine {
    @include themed() {
      color: t(background-color-legacy-pine);
    }
  }

  &.unchecked.tumeric {
    @include themed() {
      color: t(background-color-legacy-tumeric);
    }
  }
  &.unchecked.darkGrey {
    color: $darkGrey;
  }

  &.primary.checked {
    color: $primary;
  }

  &.primary.checked.pine {
    @include themed() {
      color: t(background-color-legacy-pine);
    }
  }

  &.primary.checked.tumeric {
    @include themed() {
      color: t(background-color-legacy-tumeric);
    }
  }

  &.primary.checked.darkGrey {
    color: $darkGrey;
  }
}
