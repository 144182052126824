@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';

.errorContainer {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .text {
    @include heading-S;
    @include themed() {
      color: t(color-text-weak);
    }
    margin: 36px 0 0;
  }
}
