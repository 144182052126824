@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';

.flexCenter {
  display: flex;
  align-items: center;
}

.coachRequestState {
  @include themed() {
    color: t(color-text-weak);
  }
  @include body-XS;
  margin-right: 16px;

  .errorDetailBtn {
    @include body-XS;
  }

  .successState {
    @include themed() {
      color: t(color-text-link-strong);
    }
  }

  .errorState,
  .errorDetailBtn {
    @include themed() {
      color: t(color-status-critical-strong);
      background-color: t(color-background-tag-rose);
    }
    padding: 4px 8px;
    border-radius: 5px;
  }

  .errorDetailBtn {
    text-transform: capitalize;
    text-decoration: underline;
  }

  .clearBtn {
    margin-left: 8px;
    cursor: pointer;
  }
}

svg.iconSize {
  font-size: 16px;
  margin-right: 4px;
}
