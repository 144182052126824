@import 'styles/design-tokens/Colors';

.searchBoxWrapper {
  margin: 0 15px;
  position: relative;

  .searchBox {
    width: 100%;
    @include themed() {
      background-color: t(background-color);
    }

    background-image: url('/assets/inbox/magnifying-glass.svg');
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: 8px center;

    input[type='text'] {
      padding: 10px;
      margin-left: 25px;
    }
  }

  .loader {
    position: absolute;
    right: 8px;
    top: 13px;
  }
}
