@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';

.container {
  width: 100%;
  margin: 16px 0;

  .dateAndTime {
    @include themed() {
      color: t(color-text-weaker);
    }
    @include body-XS;
    z-index: 10;
    display: flex;
    flex-direction: row;
  }

  .dateAndTime:before,
  .dateAndTime:after {
    content: '';
    flex: 1 1;
    @include themed() {
      border-bottom: 0.5px solid t(color-border-stronger);
    }
    margin: auto;
  }
  .dateAndTime:before {
    margin-right: 8px;
  }
  .dateAndTime:after {
    margin-left: 8px;
  }
}
