@import 'styles/design-tokens/Typography.scss';
@import 'styles/design-tokens/Colors';

.coachHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6px 12px;
  gap: 8px;
  box-shadow: 0px 1px 8px 3px rgba(0, 0, 0, 0.15);
  @include themed() {
    background-color: t(color-background);
  }
  position: sticky;
  top: 0;
  z-index: 1;
}

.cardSection {
  padding: 16px;
}

.inlineSection {
  margin-top: 24px;
  padding-bottom: 16px;
  @include themed() {
    border-top: 1px solid t(color-border-critical);
  }
}

.dropInCardSection {
  border-top: none;
}

.noBorder {
  margin-top: 0;
  border: none;
}

.inlineRiskSection {
  padding: 0;
}

.baseRiskButton {
  margin: 24px 16px 0 0;
  width: 68px;
  height: 24px;
  border-radius: 72px;

  @include themed() {
    box-shadow: 0 2px 0 t(shadow-default);
    background-color: t(color-background-critical);
    &:hover {
      background-color: t(color-background-critical);
    }
  }
}

.addAdditionalRisk {
  width: 52px;
  margin: 24px 13px 8px 16px;
}

.dropdownContainer {
  padding: 0 16px;
}

.label {
  @include label-XS;
  margin-top: 24px;
  margin-bottom: 4px;

  &.groupLabel {
    margin-bottom: 0;
  }

  &.subtle {
    font-weight: 400;
    margin: 8px 0;
  }

  &.withBorder {
    @include themed() {
      border-top: 1px solid t(color-border-critical);
    }
    padding-top: 24px;
  }
}

.numberField {
  width: 100% !important;
  margin-right: 0 !important;

  input {
    text-align: left !important;
    height: 34px;
    padding: 8px;
  }
}

.helperText {
  @include body-XS;
  margin-bottom: 4px;
  @include themed() {
    color: t(color-text-weak);
  }

  &.subtle {
    margin-top: 4px;
  }

  &.subtleClinical {
    margin-top: 4px;
    margin-bottom: 0;
  }
}

.dropdown {
  @include body-S;
  font-size: 16px !important;
}

.menuItem {
  @include body-XS;
  transition: none !important;
  white-space: normal;

  &:hover {
    @include themed() {
      background-color: t(color-background-tag-cloud);
    }
  }

  &:focus {
    @include themed() {
      color: t(color-text-inverse-light);
      background-color: t(color-primary);
    }
  }
}

.radioButtonContainer {
  margin-top: 24px;
}

@mixin titleField {
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  padding-right: 2px;
  margin-bottom: 24px;
}

.titleField {
  @include titleField;
}

.riskTitleField {
  @include titleField;
  margin-bottom: 0;

  &.withButton {
    padding-right: 16px;
    grid-template-columns: min-content 1fr 18px;
  }
}

.dropdownTitleField {
  @include titleField;
  padding-right: 20px;
}

@mixin title {
  @include label-M;
  margin-top: 24px;
  margin-right: 12px;
  white-space: nowrap;
  grid-column: 1 / span 1;
}

.actionsTakenTitle {
  @include title;
  margin-bottom: 8px;
}

.actionsTakenDescription {
  @include body-XS;
  @include themed() {
    color: t(color-text-weak);
  }
}

.riskAssessmentTitle {
  @include title;
  @include themed() {
    color: t(color-text-critical);
  }
}

.treatmentGoalTitle {
  @include title;
  @include themed() {
    color: t(color-primary);
  }
}

.riskAssessmentSubtitle {
  @include label-M;
  @include themed() {
    color: t(color-text-critical);
  }
  grid-row: 2 / span 1;
  grid-column: 1 / span 2;
}

.dropInTitle {
  @include title;
}

.removeRiskBtn {
  grid-column: 3 / span 1;
  margin-left: 12px;
  cursor: pointer;
  margin-top: 22px;
  @include themed() {
    color: t(color-text-weak);
  }
}

.titleDescription {
  @include label-XS;
  @include themed() {
    color: t(color-text-critical);
  }
  grid-row: 3 / span 1;
  grid-column: 1 / span 2;
  margin-bottom: 16px;

  &.noMargin {
    margin-bottom: 0;
  }
}

.inlineBorder {
  width: 100%;
  @include themed() {
    border: 1px solid t(color-border-critical);
  }
  border-top: none;
  height: 0;
  margin-top: 24px;
  grid-column: 2 / span 1;

  @include themed() {
    &.blueBorder {
      border: 1px solid t(color-primary);
    }
  }

  &.riskBorder {
    margin-top: 15px;
  }
}

.yesOrNoField {
  display: grid;
  grid-template-columns: 100px 1fr;
  max-width: 450px;
}

.yesOrNoButtonContainer {
  display: flex;
  justify-content: space-around;
}
