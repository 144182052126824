@import 'styles/Colors';
@import 'styles/Mixins';
@import 'styles/Variables';

.root {
  width: $autocompleteBoxWidth;

  & li.item {
    @include app-font(14px, 17px, 400);
    color: $mediumSlate;

    .highlight {
      font-weight: 700;
    }
  }

  .inputWrapper {
    background-color: $white;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid $fadedSlate;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 4px 12px;
    align-items: center;

    .input {
      flex: 1;
      margin-left: 6px;
      font-size: 14px;
      height: 30px;
      box-sizing: border-box;
      padding: 4px 6px;
      border: 0;
      outline: 0;
    }

    &:hover {
      border-color: $slate;
    }

    &:focus-within {
      border-color: $slate;
    }

    button.iconButton {
      padding: 0;

      &:hover {
        background-color: transparent;
      }
    }
  }

  .tags {
    margin: 1rem 0;
  }
}

.full {
  width: 100%;
}

.half {
  width: 50%;
}

.maxwidth {
  max-width: $autocompleteBoxWidth;
}
