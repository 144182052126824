@import 'styles/design-tokens/Colors';

.root .badge {
  top: -0.3rem;
  right: -0.3rem;
  @include themed() {
    color: t(color-text-success);
  }
}

.root .dotNotification {
  top: -0.1rem;
  right: -0.1rem;
  font-size: 130%;
  @include themed() {
    color: t(color-primary);
  }
}
