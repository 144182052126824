@import 'styles/design-tokens/Colors';

.zoomLink {
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  padding: 5px 12px !important;
  width: 69px !important;
  border-radius: 72px !important;
  text-transform: none;
  font-weight: 700;
  font-size: 12px;
  line-height: 120%;
  @include themed() {
    background-color: t(color-primary) !important;
  }
  box-shadow: 0px 2px 0px rgba(26, 25, 25, 0.2);
}
