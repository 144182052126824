@import '~@ginger.io/core-ui/dist/styles/Colors';
@import 'Variables';
@import 'styles/design-tokens/Colors';

.emptyMessageBody {
  width: 100%;
  margin-top: 26px;
  padding: 26px 100px;
  @include themed() {
    background-color: t(color-background);
    border: 1px solid t(color-border);
  }
  box-sizing: border-box;
  border-radius: 3px;

  font-size: $fontSizeSm;
  line-height: 20px;
  color: $darkSlate;

  .welcome {
    font-weight: 600;
  }
}
