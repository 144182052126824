@import 'styles/design-tokens/Colors.scss';
@import 'styles/design-tokens/Typography.scss';

.root {
  border-radius: 24px !important;
}

.escalateMemberToClinicalModal {
  .modalTitle {
    @include heading-S;
    @include themed() {
      color: t(color-text-inverse-dark);
    }
    margin-top: 24px;
    margin-left: 24px;
    margin-right: 24px;
    display: flex;
    justify-content: space-between;

    .close {
      margin-top: 5px;
      cursor: pointer;
    }
  }

  .modalBody {
    width: 500px;

    .text {
      @include body-S;
      @include themed() {
        color: t(color-text-weak-alt);
      }

      span {
        @include label-S;
        @include themed() {
          color: t(color-primary);
        }
      }
    }

    .noteText {
      @include body-XXS;
      @include themed() {
        color: t(color-text-weak-alt);
      }
    }

    .buttonContainer {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
    }
  }
}
