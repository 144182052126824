.contentWrapper {
  position: fixed;
  background-color: white;
  padding: 40px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
}

.buttonWrapper {
  margin: 50px auto 0 auto;
}
