@import 'styles/Colors';
@import 'styles/Mixins';
@import 'styles/Variables';
@import 'styles/design-tokens/Colors';

.root {
  .control {
    display: flex;
    align-items: center;

    .input {
      width: $numberInputWidth;
      margin-right: 8px;

      input {
        @include themed() {
          background-color: t(color-interactive-static-light);
        }
        text-align: right;
        &.sm {
          font-size: $smallFontSize;
          padding: 8px;
        }
        &.md {
          font-size: $mediumFontSize;
          padding: 16px;
        }
      }
    }

    .label {
      @include app-font($smallFontSize, 18px, 600);
      color: $slate;
    }
  }
}
