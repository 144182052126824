@import 'styles/design-tokens/Colors.scss';
@import 'styles/design-tokens/Typography.scss';

$cardMaxHeight: 500px;
$cardHeaderMaxHeight: 48px;

.box {
  @include themed() {
    background-color: t(color-background);
  }
  border-radius: 6px;
  box-shadow: 0px 3px 8px 2px rgba(135, 100, 30, 0.15);
  position: relative;
  min-width: 290px;

  &-small {
    max-height: $cardMaxHeight * 0.6; // 300px
  }
  &-medium {
    max-height: $cardMaxHeight; // default size
  }
  &-large {
    max-height: $cardMaxHeight * 1.6; // 800px
  }
  &-fit-vh {
    height: 100vh;
  }
  &-fit-container {
    height: 100%;
  }

  .toggleButton {
    position: absolute;
    top: 12px;
    right: 18px;
    border: none;
    @include themed() {
      color: t(color-status-info);
      background-color: t(color-background);
    }
    align-self: center;
    height: 0.8rem;
    width: 1.5rem;
    svg {
      cursor: pointer;
      left: -7px;
      margin: 0;
      padding: 0;
      position: relative;
      top: -10px;
      font-size: 2.2rem;
    }
  }
  .toggleButtonCenter {
    @extend .toggleButton;
    right: unset;
    left: 270px;
  }
  .actionMenu {
    position: absolute;
    top: 12px;
    right: 24px;
    border: none;
    @include themed() {
      background-color: t(color-background);
    }
    align-self: center;
  }

  .boxHeader {
    border-radius: 8px;
    border: none;
    @include themed() {
      background-color: t(color-background);
    }
    display: flex;
    width: 100%;
    height: $cardHeaderMaxHeight;
    padding: 12px 16px;
    cursor: pointer;

    &:disabled {
      cursor: default;
    }

    .expandIcon {
      align-self: center;
      height: 0.7rem;
      margin-right: 8px;
      width: 0.8rem;
      transform: rotate(90deg);
      transition: transform 400ms;
      svg {
        @include themed() {
          color: t(color-status-info);
        }
        font-size: 1.5rem;
        left: -6px;
        margin: 0;
        padding: 0;
        position: relative;
        top: -8px;
      }
    }
    .boxTitleCenter {
      @include label-S;
      @include themed() {
        color: t(color-text-link);
      }
      margin-block-start: 0;
      margin-block-end: 0;
      align-self: center;
      text-align: left;
    }
    .toggleLabel {
      align-self: center;
      @include body-XXS;
      @include themed() {
        color: t(color-text-weak);
      }
    }
    .boxTitle {
      @extend .boxTitleCenter;
      flex: 1;
    }
    .toggleLabelCenter {
      @extend .toggleLabel;
      padding-left: 16px;
    }
    .toggleBox {
      align-self: center;
      height: 0.8rem;
      margin-left: 8px;
      width: 1.5rem;
    }
  }

  .boxContent {
    border-radius: 0 0 8px 8px;
    overflow-wrap: break-word;
    overflow-x: hidden;
    padding: 0;
    box-sizing: border-box;

    &-small {
      max-height: calc(#{$cardMaxHeight * 0.6} - #{$cardHeaderMaxHeight});
    }
    &-medium {
      max-height: calc(#{$cardMaxHeight} - #{$cardHeaderMaxHeight});
    }
    &-large {
      max-height: calc(#{$cardMaxHeight * 1.6} - #{$cardHeaderMaxHeight});
    }
    &-fit-vh {
      height: calc(100vh - #{$cardHeaderMaxHeight});
    }
  }

  .showScrollbar {
    overflow-y: overlay;
  }

  .hideScrollbar {
    overflow-y: hidden;
  }

  .boxContent::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 6px;
  }

  .boxContent::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  .boxContent::-webkit-scrollbar:vertical {
    width: 10px;
  }

  .boxContent::-webkit-scrollbar-thumb {
    border-radius: 6px;
    border-width: 3px 2px;
    border-style: solid;
    @include themed() {
      border: t(color-surface);
      background-color: t(color-interactive-strongest);
    }
  }

  .cardSection {
    padding: 16px;
  }

  .rotate90deg {
    transform: rotate(0deg) !important;
    transition: transform 400ms;
  }

  .hr {
    @include themed() {
      border-top: 1px solid t(color-status-info);
    }
  }

  .disabled {
    @include themed() {
      color: t(color-interactive-strongest);
    }
  }

  .centered {
    text-align: center !important;
  }
}
