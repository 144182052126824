@import 'styles/design-tokens/Colors.scss';
@import 'styles/design-tokens/Typography.scss';

.mainContentText {
  @include label-S;
  @include themed() {
    color: t(color-text);
  }
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.subContentText {
  @include body-XS;
  @include themed() {
    color: t(color-text-weaker);

    &.critical {
      color: t(color-text-critical);
    }

    &.primary {
      color: t(color-primary);
    }
  }
}

.countBubble {
  width: 1.5rem;
  height: 1.1rem;
  display: flex;
  justify-content: center;
  align-content: center;
  padding-top: 0.4rem;

  border-radius: 50%;
  @include themed() {
    background-color: t(color-primary);
    color: t(color-background-strongest);
  }
  @include label-XXS;
}
