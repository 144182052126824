@import 'styles/Colors';
@import 'styles/Mixins';
@import 'styles/Variables';

.root {
  align-items: center;
  display: flex;
  justify-content: space-between;

  .col {
    padding-right: 12px;
  }
}
