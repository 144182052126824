@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';

.container {
  display: flex;
  justify-content: center;
  align-items: center;

  .copyBtn {
    @include themed() {
      color: t(color-primary);
    }
    padding: 0;
  }

  .copiedBtn,
  .copiedFailedBtn {
    @include themed() {
      color: t(color-text-success);
    }
    cursor: default;
    padding: 0;
  }
  .copiedFailedBtn {
    @include themed() {
      color: t(color-text-critical);
    }
  }

  .btn {
    @include label-XS;
    text-transform: none;
    margin: 8px;
    display: flex;
    align-items: center;
  }

  .submitBtn {
    padding: 4px 8px;
    border-radius: 72px;
    @include themed() {
      background-color: t(color-primary);
    }
  }

  .iconSize {
    @include label-XS;
    margin-right: 4px;
  }
}
