@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';

@mixin container {
  max-width: 85%;
  margin-bottom: 24px;
}

.container {
  @include container;
  display: grid;
  grid-template-columns: 32px 1fr 14px;
  grid-column-gap: 12px;

  .avatar {
    height: 32px;
    width: 32px;
    margin-right: 12px;
    @include label-L();
    margin-top: auto;
  }
}

.careProviderMessageContainer {
  @include container;
  display: flex;
  align-self: flex-end;
}

.memberContainer {
  align-self: flex-start;
}

.popper {
  .tooltip {
    margin-top: 4px !important;
    inset: 12px auto 0 auto;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    border-radius: 1px;
    @include themed() {
      background-color: t(color-surface);
    }
    @include body-XS();
    padding: 2px 8px;
    @include themed() {
      color: t(color-text-weak);
    }
  }
}

.noteTitle {
  @include label-XXS();
  margin-bottom: 6px;
  margin-left: 1px;
}

.warningIcon {
  margin-top: 20px;
  align-self: center;
  @include themed() {
    color: t(color-background-critical);
  }
}
