@import 'styles/Colors';
@import 'styles/Variables';
@import 'styles/design-tokens/Colors';

$borderSize: 4px;

@mixin flexList {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.scheduleContainer {
  overflow-y: scroll;
  height: 100vh;
}

.inset {
  margin-top: 30px;
}

.nav {
  margin-top: 10px;
  position: relative;
  z-index: 1;
  height: $topNavHeight;

  display: flex;
  flex-direction: row;
  border-bottom: 4px solid rgba(0, 0, 0, 0.03);

  ul.main {
    @include flexList;
    flex: 1;
    justify-content: flex-start;
    align-items: center;

    li {
      display: flex;
      padding: 0;

      a {
        display: flex;
        flex: 1;
        padding: 1rem;
        font-weight: 400;
        font-size: 14px;
        color: $fontColor;
        text-decoration: none;
        border-top: $borderSize solid transparent;
        border-bottom: $borderSize solid transparent;
        transition: all 0.25s;

        &:hover {
          @include themed() {
            border-bottom-color: t(color-weaker);
          }
        }

        &.active {
          @include themed() {
            border-bottom-color: t(color-primary);
          }
          font-weight: bold;
        }
      }
    }
  }
}
