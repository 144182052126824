@import 'styles/design-tokens/Colors.scss';

.badge {
  text-transform: capitalize;
  padding: 6px 9px;
  border-radius: 20px;

  @include themed() {
    &.not-started {
      background-color: t(color-interactive-static-light-strong);
      color: t(color-text-weak);
    }
    &.in-progress {
      background-color: t(background-color-light-orange);
      color: t(color-text-weak);
    }
    &.completed {
      background-color: t(background-color-light-green);
      color: t(color-text-weak);
    }
  }
}
