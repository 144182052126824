@import 'styles/Colors';
@import 'styles/Mixins';

.documentGrid {
  table {
    & * {
      box-sizing: border-box;
    }

    tbody:not(:last-child) tr:last-child td {
      border-bottom: 1px solid $fadedSlate;
    }
  }

  .tableHead {
    display: flex;
    align-items: center;

    .sortIcon {
      cursor: pointer;
    }
  }
}

.errorRoot {
  width: 450px;
  margin: auto;
  padding: 16px;
  background: #fff;
}
