@import 'styles/design-tokens/Typography.scss';

.root {
  .substanceSection {
    padding: 0 8px 8px 32px;
    .label {
      @include label-XS;
      line-height: 16px;
      padding-top: 8px;
    }
    .durationSection {
      padding: 8px 0;
    }
    .textField {
      padding-top: 8px;
      width: 100%;
    }
  }

  .table {
    width: 100%;
  }
}
