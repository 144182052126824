@import 'styles/design-tokens/Typography.scss';
@import 'styles/design-tokens/Colors';

.controls {
  display: flex;
  margin-top: 20px;
  .textContainer {
    display: flex;
    flex-basis: 50%;
  }
  .text {
    @include body-XS;
    @include themed() {
      color: t(color-text-weaker);
    }
  }
  .buttonContainer {
    display: flex;
    flex-basis: 50%;
    justify-content: flex-end;
    .leftButton {
      margin-right: 12px;
    }
    .button_circular {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 4px;
      @include themed() {
        box-shadow: 0px 2px 0px t(shadow-default);
      }
      border-radius: 72px;
      border: 0;
      cursor: pointer;
    }
  }
}
