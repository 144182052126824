@import 'styles/design-tokens/Typography.scss';
@import 'styles/design-tokens/Colors.scss';

.reminderBox {
  @include body-XS;
  border-radius: 6px;
  display: grid;
  grid-template-columns: 24px 1fr;
  padding: 8px 12px;
  grid-column-gap: 8px;
  @include themed() {
    background-color: t(color-background-stronger);
    color: t(color-text-weak);
  }
  justify-items: left;
}

.calendarIcon {
  height: 20px;
  width: 20px;
}
