@import 'styles/Colors';
@import 'styles/Mixins';
@import 'styles/design-tokens/Typography.scss';
@import 'styles/design-tokens/Colors';

.root {
  .ext {
    width: 25px;
    height: 25px;
    padding: 3px;
    border-radius: 4px;
    @include label-XXS;
    display: inline-flex;
    align-items: center;
    text-align: center;
    font-variant: small-caps;
    justify-content: center;
    color: $white;
    background-color: $steelBlue;
    &.docs {
      background-color: $sunset;
    }
    &.images {
      @include themed() {
        background-color: t(background-color-legacy-tumeric);
      }
    }
  }
  .btnLink {
    cursor: pointer;
  }
}
