@import 'styles/design-tokens/Typography';
@import 'styles/design-tokens/Colors';

.container {
  padding: 8px 8px 16px 16px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.button {
  @include body-XS;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  background: none;
  border: none;
  cursor: pointer;
  height: fit-content;

  .buttonText {
    padding-top: 1px;
  }
}

.sections {
  @include body-XS;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

// .aboutMe is a class that contains two divs, one in the left and one in the right
.aboutMe {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.divider {
  @include themed() {
    border-top: 1px solid t(color-divider);
  }
}

.label {
  @include label-XS;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
