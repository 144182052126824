@import 'styles/design-tokens/Colors.scss';
@import 'styles/design-tokens/Typography.scss';

.sectionHeaderWrapper {
  position: sticky;
  padding: 7px 6px;
  //background-image: linear-gradient(to right, #fff 97%, transparent 3%);
  @include themed() {
    background-color: t(color-background);
  }
  z-index: 999;

  /*** Content Recommendations ***/

  &.current {
    top: 0;
    bottom: 35px;
  }

  &.completed {
    top: 35px;
    bottom: 0;
  }

  .sectionHeader {
    border: none;
    border-radius: 8px;
    @include themed() {
      background-color: t(color-background);
    }
    display: flex;
    padding: 0 10px;
    height: 29px;
    text-align: left;
    @include label-XS;
    position: sticky;
    z-index: 10;

    .sectionIcon {
      height: 27px;
      width: 16px;
      margin-right: 3px;
    }
    .sectionTitle {
      align-self: center;
      flex: 1;
    }
    .expandIcon {
      align-self: center;
      height: 12px;
      margin-left: 8px;
      width: 13px;
      transform: rotate(90deg);
      transition: transform 400ms;
      svg {
        font-size: 1.5rem;
        left: -6px;
        margin: 0;
        padding: 0;
        position: relative;
        top: -6px;
      }
    }

    .rotate90deg {
      transform: rotate(0deg);
      transition: transform 400ms;
    }
  }

  .section {
    @include themed() {
      background-color: t(color-background);
    }
    & ~ & {
      border-radius: 0;
    }

    .sectionContent {
      display: flex;
      flex-flow: column nowrap;
      flex-grow: 1;
      padding: 0;
    }

    .paddingTop {
      padding-top: 12px;
    }
  }

  .light-grey {
    @include themed() {
      background-color: t(color-interactive-strong);
      color: t(color-text-weak);
    }
  }
  .light-cloud {
    @include themed() {
      background-color: t(color-background-tag-cloud);
      color: t(color-primary);
    }
  }

  .addCircleButton {
    @include themed() {
      background-color: t(color-primary);
      color: t(color-text-inverse-light);
    }
    width: 22px;
    height: 22px;
    border-radius: 50%;
    padding: 0;
    border: none;
    @include themed() {
      box-shadow: 0px 2px 0px t(color-surface-strong-inverted);
    }
    margin-top: 3px;
    cursor: pointer;
    .addCircleIcon {
      @include themed() {
        background-color: t(color-primary);
        color: t(color-text-inverse-light);
      }
      width: 22px;
      height: 22px;
      border-radius: 50%;
      padding: 0;
    }
  }
}
