@import 'styles/Colors';
@import 'styles/Variables';
@import 'styles/design-tokens/Colors';

$borderSize: 4px;

@mixin flexList {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.topNav {
  background: $white;
  position: relative;
  z-index: 1;
  height: $topNavHeight;

  display: flex;
  flex-direction: row;
  @include themed() {
    box-shadow: 0 3px 4px t(background-color-black-opaque);
  }

  .badge {
    top: -0.3rem;
    right: -0.3rem;
  }

  ul.main {
    @include flexList;
    flex: 1;
    justify-content: flex-start;
    align-items: center;

    li {
      display: flex;
      padding: 0;

      &:first-child {
        padding: 0 1rem;
        margin-right: 1rem;
        @include themed() {
          border-right: 1px solid t(background-color-gray-opaque);
        }
      }

      a {
        display: flex;
        flex: 1;
        margin: 0 1rem 0 0;
        padding: 1rem;
        font-weight: 400;
        font-size: 14px;
        color: $fontColor;
        text-decoration: none;
        border-top: $borderSize solid transparent;
        border-bottom: $borderSize solid transparent;
        transition: all 0.25s;

        &:hover {
          border-bottom-color: $fadedTumeric;
        }

        &.active {
          @include themed() {
            border-bottom-color: t(background-color-legacy-tumeric);
          }
        }
      }
    }
  }

  div.search {
    @include flexList;
    flex: 0.4;
    padding: 10px 0;
    align-items: center;
    .input {
      box-shadow: none;
    }
  }

  ul.settings {
    @include flexList;
    display: flex;
    margin-right: 1rem;
    align-items: center;

    li {
      display: flex;
      align-items: center;

      svg {
        margin-right: 0.5rem;
      }
    }
  }
}
