@import 'styles/design-tokens/Colors.scss';

.inboxItemWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem;
  @include themed() {
    border-bottom: 1px solid t(color-border);
  }
  min-height: 3rem;
  cursor: pointer;

  &:hover {
    @include themed {
      background-color: t(color-background-stronger);
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.2rem 0.3rem;
    align-items: center;
  }
}
