@import 'styles/Variables';

$notesColumnWidth: 296px;
$chatColumnWidth: 448px;
$spaceBetweenColumns: 28px;

.memberChart {
  width: 100%;
  box-sizing: border-box;
  min-width: 293px;
  padding-right: 3px;

  .navContainer {
    display: flex;

    .navTab {
      flex: 1;
    }
  }
}

.memberChartTabContent {
  $navContainerHeight: 70px;
  $fixed-height: calc(
    $memberHeaderHeight + $multiTabHeight + $navContainerHeight
  );
  overflow-y: scroll;
  height: calc(100vh - $fixed-height);
}
