@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography.scss';

.sortableCellTop {
  vertical-align: top;
  @include label-XS;
}

.cellTop {
  @extend .sortableCellTop;
  padding-left: 16px;
}

.cell {
  padding: 12px 12px 12px 8px;
}

.stickyHeader {
  position: sticky;
  top: 0;
}

.checkbox {
  padding: 0;
}

.root {
  padding: 0;
}

.small {
  width: 25%;
}

.xs {
  width: 0;
  padding: 0;
}

.medium {
  width: 50%;
}

.large {
  width: 100%;
}

.menuRoot {
  background-color: transparent;
}

.menuPaper {
  border-radius: 6px;
}

.menuList {
  padding: 12px 8px;
}

.menuItem {
  padding: 0px 8px;
  height: 32px;
}

.filterItem {
  background: transparent !important;
  color: black;
  box-shadow: none !important;
  padding: 1px;
  min-width: 0;
  text-transform: inherit;
}

.filterIcon {
  size: 1.2em;
  height: 16px;
  padding: 0 0 0 5px;
  margin: 0;

  :active,
  :hover {
    color: black;
  }
}
