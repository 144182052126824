@import 'styles/design-tokens/Colors.scss';
@import 'styles/design-tokens/Typography.scss';
@import 'styles/Variables';
@import 'app/inbox/components/styles/InboxSection.mixin.scss';

.sectionHeaderWrapper {
  position: sticky;
  padding: 0.5rem 0.25rem;
  box-sizing: border-box;
  height: 2.5rem;

  .sectionHeader {
    border: none;
    border-radius: 0.5rem;
    @include themed() {
      background-color: t(color-background);
    }
    display: flex;
    padding: 0.25rem 0.5rem;
    height: 2rem;
    text-align: left;
    width: 100%;
    @include label-XS;
    position: sticky;

    .sectionIcon {
      height: 1.5rem;
      width: 1rem;
      margin-right: 3px;
      @include inbox-section-icons;
    }

    .sectionTitle {
      align-self: center;
      flex: 1;
    }

    .expandIcon {
      align-self: center;
      height: 12px;
      margin-left: 8px;
      width: 13px;
      transform: rotate(90deg);
      transition: transform 400ms;
      &.rotate90deg {
        transform: rotate(0deg);
        transition: transform 400ms;
      }

      svg {
        font-size: 1.5rem;
        left: -6px;
        margin: 0;
        padding: 0;
        position: relative;
        top: -6px;
      }
    }
  }

  .sectionContainer {
    @include themed() {
      background-color: t(color-background);
    }
    height: 180px;
    overflow-y: scroll;

    & ~ & {
      border-radius: 0;
    }

    .sectionContent {
      display: flex;
      flex-flow: column nowrap;
      flex-grow: 1;
      padding: 0;
    }

    .paddingTop {
      padding-top: 12px;
    }
  }

  @include inbox-section-colors;
}

.sectionLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
  height: 3rem;
}

.personIcon {
  font-size: 1rem;
  position: relative;
  top: 0.15rem;
}

.loadMoreBtn {
  @include label-XS;
  @include themed() {
    color: t(color-text-weak);
  }
  width: 100%;
  padding: 0.5rem 1rem;
  text-align: start;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}
