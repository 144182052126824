@import 'styles/design-tokens/Colors';
@import 'styles/Colors';
@import 'styles/Mixins';
@import 'styles/design-tokens/Typography.scss';

$headerOffset: 200px;

.root {
  height: 100%;

  .sticky {
    position: sticky;
    top: 178.5px;
    min-width: 160px;
    max-width: 100%;
  }

  .col {
    height: 100%;
    padding: 6px;

    &:last-child.auditView {
      @include themed() {
        border-left: 1px solid t(color-divider);
      }
    }

    .sidebar {
      @include themed() {
        background-color: t(color-surface-strong);
        color: t(color-foreground-weak);
      }
      border-radius: 12px;
      margin-top: 6px;
      max-width: 260px;
      position: sticky;
      top: 190px;
    }
  }

  .tabs {
    padding: 6px 6px;
    gap: 6px;
    display: flex;
    flex-direction: column;
    list-style: none;
    height: 100%;

    li {
      cursor: pointer;
    }

    .tab {
      @include body-XS;
      text-transform: capitalize;
      display: flex;
      flex: 1;
      color: $mediumSlate;
      align-items: center;
      height: 33px;

      svg {
        color: $grey;
      }

      &.active {
        color: $noteTabTitle;
        background: $noteTabBackground;
        border-radius: 0 20px 20px 0;
        font-weight: 700;

        svg {
          color: $noteTabTitle;
        }
      }

      &.completed {
        svg {
          color: $noteTabTitle;
        }
      }
    }
  }

  .tabsV2 {
    @extend .tabs;

    padding-top: 8px;

    .tab {
      @include themed() {
        &.active {
          background: unset;
        }
        &.completed {
          svg {
            color: t(color-status-info);
          }
        }
      }
    }
  }
}

.checkbox {
  padding: 0 6px 0 0;
}

.section {
  scroll-margin-top: $headerOffset;
}

.button__transparent {
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  outline: none;
  text-align: initial;

  @include themed() {
    color: t(color-foreground-weak);
  }
}

.selectedTab {
  @include label-XS;
  @include themed() {
    color: t(color-primary);
  }
}
