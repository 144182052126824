$themes: (
  light: (
    /*** Primary ***/ color-primary: #2875cc,
    color-primary-strong: #254c7e,
    color-weaker: #63605d,
    /*** Background ***/ color-background: #ffffff,
    color-background-strong: #fcf8ed,
    color-background-stronger: #f4f4f4,
    color-background-strongest: #e9e9e9,
    color-background-critical: #d64218,
    color-background-critical-strong: #963727,
    color-background-weaker: #63605d,
    /*** Foreground ***/ color-foreground: #2d2c2b,
    color-foreground-weak: #44423f,
    /*** Surface ***/ color-surface: #ffffff,
    color-surface-strong: #fcf8ed,
    color-surface-strong-inverted: #272727,
    color-surface-stronger: #f9efd8,
    color-surface-static: #ffffff,
    color-surface-overlay: #272727,
    color-surface-gradient: #ffffff,
    color-surface-gradient-inverted: #191919,
    color-surface-gradient-static: #272727,
    color-surface-success: #f2faf5,
    /*** Text ***/ color-text: #272727,
    color-text-weak: #474747,
    color-text-weak-alt: #5a5857,
    color-text-weaker: #7d7d7d,
    color-text-weaker-inverted: #9e9e9e,
    color-text-weakest: #9e9e9e,
    color-text-inverse-light: #ffffff,
    color-text-inverse-dark: #3a3938,
    color-text-inverse-dark-weak: #474747,
    color-text-link: #2875cc,
    color-text-link-strong: #254c7e,
    color-text-highlight: #e5ab33,
    color-text-critical: #d64218,
    color-text-success: #1c7b53,
    /*** Border ***/ color-border: #e9e9e9,
    color-border-strong: #d4d4d4,
    color-border-stronger: #7d7d7d,
    color-border-critical: #d64218,
    /*** Interactive ***/ color-interactive: #ffffff,
    color-interactive-inverted: #191919,
    color-interactive-strong: #f4f4f4,
    color-interactive-strong-alt: #f9f4f2,
    color-interactive-stronger: #e9e9e9,
    color-interactive-stronger-alt: #e2ded9,
    color-interactive-strongest: #d4d4d4,
    color-interactive-static-light: #ffffff,
    color-interactive-static-light-strong: #e9e9e9,
    color-interactive-static-dark: #272727,
    color-interactive-static-dark-strong: #191919,
    color-interactive-contrast-text: #f4f4f4,
    color-interactive-contrast-background: #191919,
    color-interactive-contrast-background-inverted: #f4f4f4,
    color-interactive-light: #faf6f4,
    /*** Status ***/ color-status-critical: #d64218,
    color-status-critical-strong: #963727,
    color-status-success: #1c7b53,
    color-status-success-strong: #235949,
    color-status-warning: #e5ab33,
    color-status-warning-strong: #b58117,
    color-status-info: #2875cc,
    color-status-info-strong: #254c7e,
    color-status-online: #82b713,
    color-status-readonly: #bebebe,
    color-status-offline: #cb4930,
    /*** Shadow ***/ shadow-default: #1a191933,
    /*** Divider ***/ color-divider: #eaeaea,
    /*** Colors ***/ color-green-400: '#0B662A',
    /*** Custom Colors ***/ background-color-navy: #101340,
    background-color-blue: #0064d9,
    background-color-red: #e01919,
    background-color-red-stronger: #d12a1f,
    background-color-burnt-orange: #8a6d3b,
    background-color-light-orange: #f4ce8a,
    background-color-light-green: #b4dec4,
    background-color-orange: #ff7300,
    background-color-gold: #ffb700,
    background-color-pale-green: #5ab98b,
    background-color-gray-opaque: rgba(177, 177, 177, 0.12),
    background-color-black-opaque: rgba(0, 0, 0, 0.15),
    background-color-light-gray-opaque: rgba(255, 255, 255, 0.6),
    border-color-light-gray: #eae7e4,
    background-color-opaque: rgba(0, 0, 0, 0.15),
    color-background-tag-cloud: #eff7ff,
    color-background-tag-cloud-strong: #d7eaff,
    color-background-tag-sand: #fcf8ed,
    color-background-tag-rose: #fff4f2,
    /*** Legacy Colors ***/ background-color-legacy-pine: #0b7472,
    background-color-legacy-silver: #cccccc,
    background-color-legacy-tumeric: #dea531,
  ),
  bluesky: (
    /*** Primary ***/ color-primary: #0061ef,
    color-primary-strong: #0040ea,
    color-weaker: #63605d,
    /*** Background ***/ color-background: #ffffff,
    color-background-strong: #f9f4f2,
    color-background-stronger: #e2ded9,
    color-background-strongest: #c6c1b9,
    color-background-critical: #e01919,
    color-background-critical-strong: #bf0000,
    color-background-weaker: #63605d,
    //custom
    /*** Foreground ***/ color-foreground: #2d2c2b,
    color-foreground-weak: #44423f,
    /*** Surface ***/ color-surface: #ffffff,
    color-surface-strong: #f9f4f2,
    color-surface-strong-inverted: #101340,
    color-surface-stronger: #e2ded9,
    color-surface-static: #ffffff,
    color-surface-overlay: #2d2c2b,
    color-surface-gradient:
      linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%),
    color-surface-gradient-inverted:
      linear-gradient(178.01deg, rgba(5, 7, 38, 0) 1.68%, #050726 50%),
    color-surface-gradient-static:
      linear-gradient(
        180deg,
        rgba(20, 19, 19, 0) 0%,
        rgba(20, 19, 19, 0.4) 100%
      ),
    color-surface-success: #ebf5f0,
    /*** Text ***/ color-text: #2d2c2b,
    color-text-weak: #44423f,
    color-text-weak-alt: #5a5857,
    color-text-weaker: #63605d,
    color-text-weaker-inverted: #9da5b8,
    color-text-weakest: #a8a5a0,
    color-text-inverse-light: #ffffff,
    color-text-inverse-dark: #2d2c2b,
    color-text-inverse-dark-weak: #44423f,
    color-text-link: #0061ef,
    color-text-link-strong: #0040ea,
    color-text-highlight: #ff7300,
    color-text-critical: #e01919,
    color-text-success: #02873e,
    /*** Border ***/ color-border: #e2ded9,
    color-border-strong: #c6c1b9,
    color-border-stronger: #63605d,
    color-border-critical: #e01919,
    /*** Interactive ***/ color-interactive: #ffffff,
    color-interactive-inverted: #2d2c2b,
    color-interactive-strong: #f9f4f2,
    color-interactive-strong-alt: #f9f4f2,
    color-interactive-stronger: #e2ded9,
    color-interactive-stronger-alt: #e2ded9,
    color-interactive-strongest: #c6c1b9,
    color-interactive-static-light: #ffffff,
    color-interactive-static-light-strong: #e2ded9,
    color-interactive-static-dark: #2d2c2b,
    color-interactive-static-dark-strong: #141313,
    color-interactive-contrast-text: #e5e7ec,
    color-interactive-contrast-background: #050726,
    color-interactive-contrast-background-inverted: #f9f4f2,
    color-interactive-light: #f9f4f2,
    /*** Status ***/ color-status-critical: #e01919,
    color-status-critical-strong: #bf0000,
    color-status-success: #02873e,
    color-status-success-strong: #0b662a,
    color-status-warning: #ffb700,
    color-status-warning-strong: #ff9900,
    color-status-info: #0040ea,
    color-status-info-strong: #0000db,
    color-status-online: #31cc66,
    color-status-readonly: #c6c1b9,
    color-status-offline: #ff5a5a,
    /*** Shadow ***/ shadow-default: #ffffff,
    /*** Divider ***/ color-divider: #e2ded9,
    /*** Colors ***/ color-green-400: '#0B662A',
    /*** Custom Colors ***/ background-color-navy: #101340,
    background-color-blue: #0064d9,
    background-color-red: #e01919,
    background-color-red-stronger: #d12a1f,
    background-color-burnt-orange: #8a6d3b,
    background-color-light-orange: #f4ce8a,
    background-color-light-green: #b4dec4,
    background-color-orange: #ff7300,
    background-color-gold: #ffb700,
    background-color-pale-green: #5ab98b,
    background-color-gray-opaque: rgba(177, 177, 177, 0.12),
    background-color-black-opaque: rgba(0, 0, 0, 0.15),
    background-color-light-gray-opaque: rgba(255, 255, 255, 0.6),
    border-color-light-gray: #eae7e4,
    background-color-opaque: rgba(0, 0, 0, 0.15),
    color-background-tag-cloud: #eff7ff,
    color-background-tag-cloud-strong: #d7eaff,
    color-background-tag-sand: #fcf8ed,
    color-background-tag-rose: #fff4f2,
    /*** Legacy Colors ***/ background-color-legacy-pine: #0b7472,
    background-color-legacy-silver: #cccccc,
    background-color-legacy-tumeric: #dea531,
  ),
  dark: (
    /*** Primary ***/ color-primary: #0061ef,
    color-primary-strong: #0040ea,
    color-weaker: #545f82,
    /*** Background ***/ color-background: #050726,
    color-background-strong: #101340,
    color-background-stronger: #050726,
    color-background-strongest: #545f82,
    color-background-critical: #e01919,
    color-background-critical-strong: #bf0000,
    color-background-weaker: #545f82,
    /*** Foreground ***/ color-foreground: #e5e7ec,
    color-foreground-weak: #d2d5de,
    /*** Surface ***/ color-surface: #050726,
    color-surface-strong: #101340,
    color-surface-strong-inverted: #f9f4f2,
    color-surface-stronger: #2a2d55,
    color-surface-static: #ffffff,
    color-surface-overlay: #2d2c2b,
    color-surface-gradient:
      linear-gradient(178.01deg, rgba(5, 7, 38, 0) 1.68%, #050726 50%),
    color-surface-gradient-inverted:
      linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%),
    color-surface-gradient-static:
      linear-gradient(
        180deg,
        rgba(20, 19, 19, 0) 0%,
        rgba(20, 19, 19, 0.4) 100%
      ),
    color-surface-success: #04352f,
    /*** Text ***/ color-text: #e5e7ec,
    color-text-weak: #d2d5de,
    color-text-weak-alt: #e5e7ec,
    color-text-weaker: #9da5b8,
    color-text-weaker-inverted: #63605d,
    color-text-weakest: #7580a3,
    color-text-inverse-light: #ffffff,
    color-text-inverse-dark: #2d2c2b,
    color-text-inverse-dark-weak: #44423f,
    color-text-link: #00a4ff,
    color-text-link-strong: #0061ef,
    color-text-highlight: #ff7300,
    color-text-critical: #ff5a5a,
    color-text-success: #5bb97f,
    /*** Border ***/ color-border: #2a2d55,
    color-border-strong: #545f82,
    color-border-stronger: #9da5b8,
    color-border-critical: #ff5a5a,
    /*** Interactive ***/ color-interactive: #050726,
    color-interactive-inverted: #e5e7ec,
    color-interactive-strong: #2a2d55,
    color-interactive-strong-alt: #2a2d55,
    color-interactive-stronger: #545f82,
    color-interactive-stronger-alt: #545f82,
    color-interactive-strongest: #7580a3,
    color-interactive-static-light: #ffffff,
    color-interactive-static-light-strong: #e2ded9,
    color-interactive-static-dark: #2d2c2b,
    color-interactive-static-dark-strong: #141313,
    color-interactive-contrast-text: #2d2c2b,
    color-interactive-contrast-background: #f9f4f2,
    color-interactive-contrast-background-inverted: #050726,
    color-interactive-light: #2a2d55,
    /*** Status ***/ color-status-critical: #e01919,
    color-status-critical-strong: #bf0000,
    color-status-success: #02873e,
    color-status-success-strong: #0b662a,
    color-status-warning: #ffb700,
    color-status-warning-strong: #ff9900,
    color-status-info: #0040ea,
    color-status-info-strong: #0000db,
    color-status-online: #31cc66,
    color-status-readonly: #c6c1b9,
    color-status-offline: #ff5a5a,
    /*** Shadow ***/ shadow-default: #050726,
    /*** Divider ***/ color-divider: #2a2d55,
    /*** Colors ***/ color-green-400: '#0B662A',
    /*** Custom Colors ***/ background-color-navy: #101340,
    background-color-blue: #0064d9,
    background-color-red: #e01919,
    background-color-red-stronger: #d12a1f,
    background-color-burnt-orange: #8a6d3b,
    background-color-light-orange: #f4ce8a,
    background-color-light-green: #b4dec4,
    background-color-gray: #666666,
    background-color-orange: #ff7300,
    background-color-gold: #ffb700,
    background-color-pale-green: #5ab98b,
    background-color-gray-opaque: rgba(177, 177, 177, 0.12),
    background-color-black-opaque: rgba(0, 0, 0, 0.15),
    background-color-light-gray-opaque: rgba(255, 255, 255, 0.6),
    border-color-light-gray: #eae7e4,
    background-color-opaque: rgba(255, 255, 255, 0.8),
    color-background-tag-cloud: #eff7ff,
    color-background-tag-cloud-strong: #d7eaff,
    color-background-tag-sand: #fcf8ed,
    color-background-tag-rose: #fff4f2,
    /*** Legacy Colors ***/ background-color-legacy-pine: #0b7472,
    background-color-legacy-silver: #cccccc,
    background-color-legacy-tumeric: #dea531,
  ),
);

@mixin themed() {
  @each $theme, $map in $themes {
    [class*='theme-#{$theme}'] & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}
