@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography.scss';

.alertContainer {
  padding: 16px;
  border-radius: 6px;
  @include themed() {
    background-color: f(background-color-navy);
    color: t(color-text-inverse-light);
  }
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 9999;
  width: 328px;
}

.title {
  @include label-XS;
  margin: 0;
}

.message {
  @include body-XS;
}

@mixin button {
  @include label-XS;
  border-radius: 72px;
  text-transform: none;
}

.buttonGroup {
  @include button;
  display: flex;
  gap: 8px;
  margin-top: 16px;
}

.turnOnButton {
  @include button;
  @include themed() {
    background-color: t(color-primary);
    color: t(color-text-inverse-light);

    &:hover {
      background-color: t(background-color-blue);
    }
  }
  padding: 8px 16px;
  border: none;
}

.turnOffButton {
  @include button;
  @include themed() {
    background-color: t(color-background-red);
    color: t(color-text-inverse-light);
    &:hover {
      background-color: t(color-background-red-stronger);
    }
  }
  padding: 8px 16px;
  border: none;
}

.dismissButton {
  @include button;
  @include themed() {
    background-color: t(color-primary-stronger);
    color: t(color-foreground);
    &:hover {
      background-color: t(color-background-stronger);
    }
  }
  padding: 8px 16px;
  border: none;
}
