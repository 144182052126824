// NOTE: These colors are deprecated! Please use styles/design-tokens/Colors.scss instead.
// $tumeric: #dea531;
$beige: #f2e9db;
$blueberry: #2875cc;
$sky: #77c5d5;
$teal: #3b9aae;
$cloud: #dcebec;
$salmon: #e8927c;
$blush: #f5e0cf;
$warmGrey: #6e6259;
$forest: #005550;
$sunset: #dc5a26;
$white: #ffffff;
$steelBlue: #4379b6;
$darkSteelBlue: #2c57a6;
$grey: #e6e6e6;
$darkGrey: #474747;
$light-grey: #f2f2f2;
$apricot: #e8c483;
// $pine: #0b7472;
$dutchWhite: #f9efd8;
$lightSand: #fcf8ed;

$fadedSlate: #d2d2d2;
$slate: #767676;
$mediumSlate: #5f5f5f;
$darkSlate: #616161;
$darkestSlate: #3c3c3c;

$fadedTumeric: #fbebcc;
$fadedGray: #f7f8fc; // #fcfcfc;
$mediumGray: #efefef;
// $silver: #cccccc;

$primary: $steelBlue;
$secondary: $teal;
$fontColor: $slate;
$h2Color: $teal;
// $dividerColor: #ddd;

$warning: #fcf8e3;
$gridBorder: #eeeeee;
$onHoverColor: rgba(177, 177, 177, 0.12);
$boxShadowColor: rgba(202, 202, 202, 0.25);
$gridBorderColor: rgb(212, 212, 212);
$gridBackgroundColor: rgb(252, 252, 252);
$tooltipColor: #0c2157;

$noteTabTitle: $steelBlue;
$noteTabBackground: rgba(67, 121, 182, 0.1);
// $disabledNoteTableHeader: $silver;

$boxBorder: rgba(135, 100, 30, 0.15);
$boxTextHeader: #2875cc;
$boxTextContent: #474747;
$accordionTextHeader: #7d7d7d;
$accordionTextContent: #474747;
$accordionTextFaded: #9e9e9e;
$boxTextContent: #474747;
$boxTextFaded: #9e9e9e;

$mui-primary: #3f51b5;
$sea: #254c7e;
