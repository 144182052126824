@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography.scss';

.body {
  padding: 0 20px;

  .header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
  }
  .textLabel {
    &.title {
      font-weight: 700;
    }
    padding-bottom: 6px;
    padding-top: 6px;
    @include body-XS;
  }
  .dot {
    padding-right: 12px;
  }
  .item {
    display: flex;
    align-items: baseline;
    padding: 8px 0;
    @include themed() {
      border-top: 1px solid t(color-border-strong);
    }
  }
}
