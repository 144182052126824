@import 'styles/Colors';
@import 'styles/Mixins';

.root {
  th.cell {
    @include app-font(13px, 16px, 500);
    color: $mediumSlate;
  }
}

.rootV2 {
  position: sticky;
  top: 0;
  background: inherit;

  th {
    background: $white;
    &.cell {
      @include app-font(13px, 16px, 500);
      color: $mediumSlate;
    }
  }
}
