@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography.scss';

.tagContainer {
  border-radius: 20px;
  @include themed() {
    background-color: t(color-primary-strongest);
  }
  padding: 2px 8px 2px 8px;
  @include label-XS;
  align-self: start;
  margin-right: 3px;
  text-wrap: nowrap;
}
