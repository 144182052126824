@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';

.addCirclePopover {
  padding: 20px;
  width: 344px;
  .addCirclePopoverText {
    @include label-XS;
    padding: 15px;
    text-align: center;
  }
  .addCirclePopoverTextbox {
    @include body-S;
    padding: 15px 15px 5px;
    .urlBox {
      width: 95%;
      border-radius: 6px;
      padding: 8px;
      @include themed() {
        border: 1px solid t(color-border);
      }
    }
  }
  .addCirclePopoverError {
    @include body-XS;
    @include themed() {
      color: t(color-status-critical);
    }
    padding: 5px 15px 15px;
    text-align: center;
  }
  .addCirclePopoverButton {
    padding: 15px;
    .addButton {
      width: 100%;
      border-radius: 15px;
      @include themed() {
        background-color: t(color-text-link);
        color: t(color-text-inverse-light);
      }
      border: none;
      padding: 6px;
    }
  }
}
