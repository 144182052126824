@import 'styles/design-tokens/Colors';
@import 'styles/Variables';

.careHubScreen {
  display: flex;
  flex-flow: row nowrap;
  height: 100vh;
  overflow: hidden;

  @include themed() {
    background-color: t(color-background-strong);
  }

  &.withAppBanner {
    $screenHeight: calc(100vh - 54px);
    height: $screenHeight;

    .verticalMenuContainer {
      height: $screenHeight;
    }

    .inboxContainer {
      height: $screenHeight;
    }
  }

  .verticalMenuContainer {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: $verticalMenuWidth;
    @include themed() {
      border-right: solid 1px t(color-border-strong);
      background-color: t(color-background);
      box-shadow: 2px 0 4px t(color-surface-strong);
    }
  }

  $inboxWidth: 235px;

  .inboxContainer {
    display: none;
    flex-flow: column nowrap;
    height: 100vh;
    width: $inboxWidth; // 236px as specified on figma is not spacious enough
    min-width: $inboxWidth;
    @include themed() {
      background-color: t(color-background);
      box-shadow: inset -2px 0px 8px t(background-color-black-opaque);
    }
    overflow: hidden;

    &.show {
      display: flex;
    }
  }

  .inboxContainer.show + .careHubScreenWrapper {
    width: calc(100% - #{$inboxWidth} - #{$verticalMenuWidth});
  }

  .careHubScreenWrapper {
    width: calc(100% - #{$verticalMenuWidth});
    overflow-y: scroll;

    .tabButtons {
      display: none;
      background-color: transparent;
    }

    .multiTabContainer {
      display: flex;
      @include themed() {
        background-color: t(color-background);
      }
      width: vw;
    }
  }
}
