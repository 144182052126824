.tabContent {
  padding: 0 12px 0 0;
}
.emptyTabContent {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 16px;
}
.cardSection {
  padding: 16px;
}
