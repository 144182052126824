@import 'styles/design-tokens/Colors';
@import 'styles/Colors';
@import 'styles/Mixins';
@import 'styles/design-tokens/Typography.scss';

.tabPanel {
  margin-bottom: 48px;
  height: 100%;
  overflow: auto;

  .title {
    @include themed() {
      color: t(color-text);
    }
    margin-bottom: 1rem;
  }

  hr {
    margin-bottom: 1rem;
  }
}

.tabPanelV2 {
  @extend .tabPanel;
  padding: 8px 16px 8px;
  margin-bottom: 0;
}

.title {
  @include themed() {
    color: t(color-text);
  }
  margin-bottom: 1rem;
}
