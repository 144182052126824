@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';

.message {
  @include themed() {
    background-color: t(color-primary);
  }
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;

  .text {
    @include themed() {
      color: t(color-text-inverse-light);
    }
    @include body-XS;
    word-break: normal;
  }
}

.memberBubble {
  @include themed() {
    background-color: t(color-interactive-stronger);

    .memberText {
      color: t(color-text);
    }
  }
}

.errorBubble {
  @include themed() {
    background-color: t(color-background-tag-rose);
  }

  .textInErrorBubble {
    @include themed() {
      color: t(color-text);
    }
  }
}

.outOfSession {
  border-radius: 20px;
  @include themed() {
    background-color: t(color-foreground-weak);
  }

  .outOfSessionText {
    @include themed() {
      color: t(color-text-inverse-light);
    }
  }
}
