@import 'styles/Colors';

.box {
  $padding: 1.5rem;
  background: #fff;
  padding: $padding;
  border-radius: 8px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);

  hr {
    margin: 0;
    padding: 0;
    border: none;
    height: 1px;
    background: #efefef;
  }

  &.centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &.noPadding {
    padding: 0;
  }

  .content {
    padding: $padding;
    h3 {
      margin: 0;
    }
  }
}
