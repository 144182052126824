@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';

.recommendationWrapper {
  padding: 0 10px;
  .recommendationHeader,
  .recommendationContent {
    display: flex;
    flex-direction: row;

    .header {
      padding: 10px 0;
    }
    &.content {
      margin-bottom: 15px;
      background: #ffffff;
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
    }
    .recommendationThumbnail {
      flex: 0 0 103px;
      &.content {
        background-color: #d9e7f6;
        height: 90px;
      }
    }
    .recommendationDetails {
      display: flex;
      flex: 1;
      flex-wrap: nowrap;
      min-width: 24px;

      &.singleColumn {
        padding-bottom: 5px;
        flex-wrap: wrap;
      }

      .header {
        @include label-XXS;
        @include themed() {
          color: t(color-text-weak);
        }
      }

      .recommendationTextbox {
        flex: 15 1 auto;
        flex-direction: column;
        align-self: center;
        padding-left: 10px;
        .recommendationTitle {
          display: block;
          flex: 1;
          padding-top: 10px;
          @include label-XS;
          @include themed() {
            color: t(color-text-weak);
          }
          &.singleColumn {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 120px;
          }
        }
        .recommendationSource {
          flex: 1;
          padding-top: 3px;
          @include body-XS;
          @include themed() {
            color: t(color-text-weak);
          }
        }
      }
      .recommendationDate {
        display: flex;
        align-self: center;
        padding-left: 10px;
        @include label-XXS;
        flex: 0 0 95px;
        &.content {
          @include themed() {
            color: t(color-text-weaker);
          }
          background-color: transparent;
          background-image: url('/assets/patients/tabs/goals/timer.svg');
          background-repeat: no-repeat;
          background-size: 13px;
          background-position: 10px center;
        }
      }
      .recommendationProgress {
        display: flex;
        flex: 0 0 75px;
        padding-left: 10px;
        align-self: center;
        &.content {
          @include label-XXXS;
        }
      }
    }
    .recommendationMenu {
      flex: 0 0 55px;
      align-self: center;
      .ellipsisButton {
        height: 10px;
        width: 100%;
        border: none;
        background-color: transparent;
        background-image: url('/assets/patients/tabs/goals/ellipsis.svg');
        background-repeat: no-repeat;
        background-position: center center;
        padding: 0;
        margin: 0;
        cursor: pointer;
      }
    }
  }
}
