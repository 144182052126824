$min-width-screen: 1210px;
$mac-book-air-13-2022: 1280px;
$mac-book-pro-15-2019: 1440px;
$mac-book-pro-14-2022: 1512px;
$max-width-screen-4k: 1920px;

@mixin mac-book-air-13-2022-and-up {
  @media (min-width: $mac-book-air-13-2022) {
    @content;
  }
}

@mixin mac-book-air-13-2022-and-less {
  @media (max-width: $mac-book-air-13-2022) {
    @content;
  }
}

@mixin on-mac-book-pro-14-2022-and-up {
  @media (min-width: $mac-book-pro-14-2022) {
    @content;
  }
}

@mixin on-mac-book-pro-14-2022-and-less {
  @media (max-width: $mac-book-pro-14-2022) {
    @content;
  }
}

@mixin mac-book-pro-15-2019-and-up {
  @media (min-width: $mac-book-pro-15-2019) {
    @content;
  }
}

@mixin mac-book-pro-15-2019-and-less {
  @media (max-width: $mac-book-pro-15-2019) {
    @content;
  }
}
