@import 'styles/design-tokens/Colors';

.dateTimeRange {
  display: flex;
  flex-wrap: wrap;

  .to {
    margin: 1rem;
  }

  .datetime {
    @include themed() {
      background-color: t(color-interactive-static-light);
    }
    div {
      width: 17rem;
    }
  }
}
