@import 'styles/design-tokens/Colors';

.status {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  @include themed() {
    color: t(color-text-weak);
  }
}

.link {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  margin-top: 9px;
  height: 4em;

  @include themed() {
    a {
      color: t(color-primary);
    }
  }
}

.row {
  padding: 0 0 0 16px;
}
