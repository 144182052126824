@import 'styles/design-tokens/Colors';

.root {
  padding: 0 16px 16px;
  .backButton {
    margin: 10px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
    @include themed() {
      background-color: t(color-background);
    }
    font-size: 15px;
    svg {
      font-size: 1.3rem;
      margin-right: 5px;
    }
  }
  .helpText {
    margin: 20px 0;
    h4 {
      margin: 0;
    }
  }
  .formInput {
    margin: 20px 0;
    .input {
      width: 100%;
    }
  }
  .searchButton {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      width: 30%;
    }
  }
}
