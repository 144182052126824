@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography.scss';

.modalTitle {
  h2 {
    display: flex;
  }
}

.modalBody {
  padding: 24px;
}

.modalText {
  @include body-XS;
}

.buttonContainer {
  margin-top: 48px;
  display: flex;
  justify-content: flex-end;
}

.confirmBtn {
  margin-left: 12px;
}
