@import 'styles/Colors';
@import 'styles/Variables';
@import 'styles/design-tokens/Colors';

.full {
  width: 100%;
}

.half {
  width: 50%;
}

.selectInput {
  display: flex;
  align-items: center;
  width: 100%;
  @include themed() {
    background-color: t(color-interactive-static-light);
  }
  &.sm {
    padding: 8px;
    font-size: $smallFontSize;
  }
  &.md {
    padding: 16px;
    font-size: $mediumFontSize;
  }
}
