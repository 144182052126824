@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';

.alertDialog {
  padding: 50px !important;

  .alertDialogContainer {
    padding: 0 !important;
    margin: 0 !important;
    border-radius: 15px !important;
  }

  .alertDialogTitle {
    @include themed() {
      color: t(color-text) !important;
    }
    padding: 15px 15px 10px !important;

    h2 {
      @include themed() {
        color: t(color-text-inverse-dark);
      }
      @include heading-S;
    }
  }

  .alertDialogContent {
    padding: 0 15px 0 !important;
    .alertDialogText {
      padding: 0 !important;
      @include body-XS;
    }
  }

  .alertDialogActions {
    button {
      text-transform: capitalize;
      border-radius: 25px;
      @include themed() {
        color: t(color-text-inverse-light);
      }
    }
  }
}
