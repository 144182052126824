@import 'styles/design-tokens/Colors';

@mixin button {
  @include themed() {
    background-color: t(color-primary);
  }
  font-weight: bold;
  @include themed() {
    color: t(color-text-inverse-light);
  }
  line-height: 14px;
  size: 12px;
  padding: 1.5rem;
  border-radius: 4px;
  box-shadow: none;
  text-transform: capitalize;

  &:hover {
    @include themed() {
      background-color: t(color-primary);
    }
  }

  &.outlined {
    background: transparent;
    @include themed() {
      color: t(color-primary);
    }
  }
}

.button {
  @include button;
}

a.button {
  @include button;
}

.small {
  padding: 0.5rem;
}

.medium {
  padding: 1rem;
}
