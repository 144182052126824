@import 'styles/design-tokens/Typography';
@import 'styles/design-tokens/Colors';

.pictureContainer {
  position: relative;
  width: 3.25rem;
  height: 3.25rem;
}

.picture {
  width: 3.25rem;
  height: 3.25rem;
  border-radius: 50%;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
}

.online {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  @include themed() {
    background-color: t(color-status-online);
  }
  border: 1px solid white;
}

.offline {
  @extend .online;
  @include themed() {
    background-color: t(color-status-offline);
  }
}
