@import 'styles/design-tokens/Colors.scss';
@import 'styles/design-tokens/Typography.scss';
@import 'styles/Variables';

.inboxContainer {
  width: 35rem;
  max-width: 15%;
  @include themed() {
    background-color: t(color-background);
  }

  overflow-y: auto;
  .inboxHeaderTabs {
    box-sizing: border-box;
    margin-bottom: 0.5rem;
    padding-top: 0.55rem;
    top: 0;
    position: sticky;
    @include themed() {
      background-color: t(color-background);
    }
    box-shadow: inset -2px 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1;

    .inboxChildTab {
      @include label-S;
      text-transform: capitalize;
      @include themed() {
        border-bottom: 1px solid t(color-border-strong);
      }
      height: 3rem;
      min-height: 3rem;
      white-space: nowrap;
      padding: 0.3rem;
      min-width: fit-content;
    }
    span {
      height: 3px;
    }
  }
}
