@import 'styles/design-tokens/Colors';
@import 'styles/Mixins';
@import 'styles/Variables';
@import 'styles/design-tokens/Typography.scss';

.root {
  margin: 0 0 $formControlBottomMargin;
  width: 100%;
  display: flex;
  flex-direction: column;

  .label {
    display: flex;
    align-items: center;

    &.md {
      @include app-font($mediumFontSize, 23px, 700);
    }
    &.sm {
      @include label-XS;
    }
    margin-bottom: 8px;
    @include themed() {
      color: t(color-text);
    }
  }

  @include themed() {
    .optional {
      color: t(color-text-weaker);
      @include body-XS;
    }
    .subtext {
      color: t(color-text-weaker);
      font-weight: normal;
      margin-top: 5px;
    }
  }
}

.subtext_paragraph {
  margin: 0;
}

.md {
  @include app-font($mediumFontSize, 23px, 700);
}

.sm {
  @include app-font($smallFontSize, 16px, 500);
}
