@import 'styles/Colors';

.tabs {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $mediumGray;

  li {
    display: flex;
    margin: 0;
    padding: 0;

    button {
      padding: 1rem 0;
      margin: 0 2rem 0 0;
      display: flex;
      font-size: 14px;
      line-height: 16px;
      color: $slate;
      background: transparent;
      border: none;
      outline: none;
      text-decoration: none;
      transition: color 0.25s;
      border-bottom: 3px solid transparent;

      &:hover {
        color: $secondary;
        cursor: pointer;
      }

      &.active {
        font-weight: 700;
        border-bottom-color: $secondary;
      }
    }
  }
}
