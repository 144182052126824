@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography.scss';

.titleAndSessionContainer {
  width: 100%;
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  column-gap: 4px;
  margin-right: 8px;
}

.sessionType {
  @include label-XS;
  @include themed() {
    color: t(color-foreground);
  }
  display: flex;
  align-items: center;
}

.riskAssessment {
  @include label-XS;
  @include themed() {
    color: t(color-status-critical);
  }
  display: flex;
  align-items: center;
}

.riskTitle {
  width: 50px;
}

.notesSessionType {
  @include body-XS;
  @include themed() {
    color: t(color-text-weaker);
  }
}

.subtitle {
  @include body-XS;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @include themed() {
    color: t(color-text);
  }

  &.riskSubtitle {
    white-space: normal;
  }
}

.dateContainer {
  margin-top: 8px;
}

.date {
  font-style: normal;
  @include body-S;
  @include themed() {
    color: t(color-text-weak);
  }
  margin-top: 9px;
}

.tagContainer {
  margin-top: 12px;
  @include body-XXS;
}

.tag {
  letter-spacing: 0.04em;
  text-transform: uppercase;
  @include themed() {
    color: t(color-text-weak);
  }
  height: 21px;
  width: 78px;
  font-size: 12px;
  margin-right: 2px;

  span {
    padding: 1px 6px;
  }

  &.therapyTag {
    @include themed() {
      background-color: t(color-surface-success);
    }
  }

  &.psychiatryTag,
  &.clinicalTag {
    @include themed() {
      background-color: t(color-background-tag-sand);
    }
  }

  &.coachingTag {
    @include themed() {
      color: t(color-text-inverse-dark);
      background-color: t(color-background-tag-cloud-strong);
    }
  }
}

.careTeamMember {
  @include body-XS;
  @include themed() {
    color: t(color-text-weaker);
  }
  margin-left: 2px;
}

.draftIcon {
  margin-left: auto;
}
