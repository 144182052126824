@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography.scss';

.pillButton {
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  width: 126px;
  height: 32px;
  @include themed() {
    background-color: t(color-background);
    border: 2px solid t(border-color-light-gray);
    box-shadow: 0 2px 0 t(background-color-black-opaque);
  }
  border-radius: 72px;
  @include themed() {
    color: t(color-text);
  }
  @include label-XS;
  cursor: pointer;
  display: flex;

  .image {
    width: 16px;
    height: 16px;
    margin-left: 4px;
    padding: 0;
  }
}
