@import 'styles/design-tokens/Typography';
@import 'styles/design-tokens/Colors';

.popper {
  .tooltip {
    padding: 2px 8px;
    @include themed() {
      background-color: t(color-background);
      color: t(color-text-weak);
    }
    border-radius: 1px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    margin: 2px 0 !important;
    max-width: none;
    @include body-XS;
  }
}
