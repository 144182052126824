@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';

.textArea {
  @include body-S;
  resize: none;
  outline: none;
  padding: 8px 42px 8px 8px;
  width: 100%;
  background: transparent;
  @include themed() {
    color: t(color-text-weak);
    border: 1px solid t(color-border);
  }
  border-radius: 6px;
  width: auto;

  &.textArea:disabled {
    cursor: not-allowed;
  }
}
