@import 'styles/Colors';
@import 'styles/Mixins';
@import 'styles/Variables';

$numberInputWidth: 60px;
$datePickerInputWidth: 170px;

.root {
  .text {
    font-size: $smallFontSize;
  }
  .input {
    width: $numberInputWidth;
    text-align: right;
  }

  div.datePicker {
    margin: 0;
    width: $datePickerInputWidth;

    input {
      padding-left: 8px;
    }

    button {
      padding: 0;
      font-size: $smallFontSize;
    }
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 550px;
  }

  .textArea {
    max-width: $maxFormWidth;
  }
}
