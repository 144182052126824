@import 'styles/design-tokens/Typography.scss';
@import 'styles/design-tokens/Colors.scss';

.root {
  padding: 16px;

  .container {
    height: 60%;
  }
  .paper {
    border-radius: 24px;
  }

  .dialogTitle {
    @include themed() {
      color: t(color-text-inverse-dark);
    }
    padding: 16px 24px;

    .title {
      @include heading-S;
    }

    .closeButton {
      position: absolute;
      right: 12px;
      padding: 0;
      margin: 0;
    }
  }

  .dialogContent {
    min-width: 500px;
    .body {
      @include body-XS;
    }
  }

  .dialogAction {
    margin: 8px;

    button.btn {
      @include body-XS;
      font-weight: 700;
      padding: 0.75rem;
      text-transform: capitalize;
      @include themed() {
        color: t(color-text-link);
      }

      border-radius: 72px;

      &.lockBtn {
        @include themed() {
          background-color: t(color-text-link);
          color: t(color-text-inverse-light);
        }
        width: 117px;
      }
      &.cancelBtn {
        @include themed() {
          color: t(color-text-inverse-dark);
          background-color: t(color-interactive-strong);
        }
        box-shadow: 0 2px 0 rgba(26, 25, 25, 0.2);
        width: 91px;
      }
    }
  }
}
