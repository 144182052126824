@import 'styles/Colors';
@import 'styles/Mixins';
@import 'styles/Variables';

div.root {
  .radioGroup {
    display: flex;
    flex-direction: column;

    span.checked {
      color: $primary;
    }

    &.rowDirection {
      flex-direction: row;
    }
    &.columnDirection {
      flex-direction: column;
    }

    .radioButton {
      padding-bottom: 5px;
    }
  }
}

.selectedValue {
  margin: 0;
  margin-top: 5px;
}
