@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography.scss';

.container {
  padding: 0 16px;
}

.backToSearchButton {
  @include label-S;
  @include themed() {
    color: t(color-text-weak);
  }
  margin: 8px 0;
  padding-left: 0;

  display: flex;
  align-items: center;
  text-align: center;

  background-color: transparent;
  border: none;
  cursor: pointer;
}

.leftArrow {
  margin-right: 5px;
}

.sessionCard {
  padding: 8px;
  text-align: center;
}

.title {
  @include label-L;
  @include themed() {
    color: t(color-foreground);
  }
  margin: 4px 0;
}

.subtitle {
  @include label-M;
  @include themed() {
    color: t(color-foreground);
  }
  margin: 4px 0;
}

.description {
  @include body-S;
  text-align: center;
  @include themed() {
    color: t(color-text-weak);
  }

  @include themed() {
    &.lighter {
      color: t(color-background-weaker);
    }

    &.bolder {
      color: t(color-foreground);
    }
  }
}

.toggleBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 27px;
  margin-top: 2px;
}

.toggleButton {
  @include themed() {
    color: t(color-status-info);
  }
  border: none;
  background: transparent;
  align-self: center;
  width: 48px;
  margin-right: 8px;

  svg {
    cursor: pointer;
    align-self: center;
    font-size: 3rem;
  }
}

.disabled {
  @include themed() {
    color: t(color-interactive-strongest);
  }
}

.buttonText {
  @include body-XS;
  @include themed() {
    color: t(color-text-weak);
  }
}

.info {
  height: 16px;
  width: 16px;
  margin-left: 4px;
}

.tagContainer {
  border-radius: 20px;
  @include themed() {
    background-color: t(color-background-strongest);
  }
  padding: 2px 8px 2px 8px;
  @include label-XS;
  align-self: start;
  margin-right: 3px;
  text-wrap: nowrap;
}

.availabilityTooltip {
  padding-left: 12px;
}

.noAvailableTimes {
  @include label-L;
  display: flex;
  justify-content: center;
  color: #62605d; // currently not included in Colors palette
}
