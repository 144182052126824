@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';

.infobox {
  display: flex;
  flex-direction: column;

  @include themed() {
    background-color: t(color-surface-success);
    &.danger {
      background-color: t(color-background-tag-rose);
    }
  }

  padding: 16px;
  margin-bottom: 24px; // TODO: Remove when implementing another segment below this
  .defaultText {
    @include body-XS;
    @include themed() {
      color: t(color-text-weaker);
    }
    text-align: center;
    padding: 16px;
  }
  .header {
    .title {
      @include utility-S;
      @include themed() {
        color: t(color-text-weaker);
      }
      text-transform: uppercase;
      padding-bottom: 16px;
    }
  }
  .section {
    height: 100%;
    .positive {
      @include themed() {
        color: t(color-status-success) !important;
      }
    }
    .negative {
      @include themed() {
        color: t(color-status-critical) !important;
      }
    }
    .icon {
      margin-right: 5px;
      height: 14px;
    }
    .image {
      margin-right: 10px;
    }
    .title {
      width: 100%;
      @include themed() {
        color: t(color-text);
      }
      text-transform: capitalize;
      margin-bottom: 3px;
      padding-right: 24px;
      @include label-XS;
    }
    .sub_title {
      width: 100%;
      @include themed() {
        color: t(color-text-weaker);
      }
      margin-bottom: 5px;
      padding-right: 24px;
      @include body-XS;
    }
    .content {
      @include themed() {
        color: t(color-text);
      }
      padding-right: 24px;
      @include body-XS;
    }
  }
  .summary {
    & > .tr {
      margin-bottom: 8px;

      @include themed() {
        &.danger .text-color {
          color: t(color-text-critical) !important;
        }
        &.success .text-color {
          color: t(color-text-success) !important;
        }
      }
    }
  }

  .tr {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    .td,
    .td_shrink,
    .td_grow2 {
      display: flex;
      flex-flow: row wrap;
      flex-grow: 1;
      flex-basis: 0;
      align-items: flex-start;
      word-break: break-word;
    }
    .td_shrink {
      flex-grow: 0;
    }
    .td_grow2 {
      flex-grow: 2;
    }
  }
}

.default {
  @include themed() {
    background-color: t(color-interactive-strong);
  }
}
.positive {
  @include themed() {
    background-color: t(color-surface-success);
  }
}
.negative {
  @include themed() {
    background-color: t(color-background-tag-rose);
  }
}
