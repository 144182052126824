@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';

.root {
  position: relative;
  margin-bottom: 10px;
  .errorState {
    @include themed() {
      color: t(color-status-critical-strong);
    }
    background-color: #fee3de;
    padding: 4px 8px;
    border-radius: 5px;
  }
}

.button {
  font-weight: bold;
  @include themed() {
    color: t(color-text-inverse-light);
  }
  line-height: 14px;
  size: 12px;
  border-radius: 4px;
  box-shadow: none;
  text-transform: capitalize;
  padding: 0.5rem;

  @include themed() {
    background-color: t(color-primary);
    &:hover {
      background: t(color-primary);
    }
  }

  &.outlined {
    background: transparent;
    @include themed() {
      color: t(color-primary);
    }
  }
  &:disabled {
    opacity: 0.5;
    color: white;
  }
}
