@import 'styles/design-tokens/Colors.scss';
@import 'styles/design-tokens/Typography.scss';

.container {
  @include body-XS;
  display: flex;
  align-items: center;
}

.copyIcon {
  cursor: pointer;
  transition: color 0.2s ease;
  @include themed() {
    color: t(color-text-link);
    background-color: t(color-background-stronger);
  }
  border-radius: 50%;
  padding: 5px;
  height: 14px;
  width: 14px;

  @include themed() {
    &:hover {
      color: t(color-primary);
      background-color: t(color-background-strongest);
    }
  }
}
