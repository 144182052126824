@import 'styles/Colors';
@import 'styles/Mixins';

.root {
  height: 120px;
  background: $white;
  border: 1px solid $grey;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    cursor: pointer;
    @include app-font($weight: 400);
    flex-direction: column;
    display: flex;
    align-items: center;
    color: $steelBlue;
  }
}
