@import 'styles/design-tokens/Colors.scss';
@import 'styles/design-tokens/Typography.scss';

.inboxSegmentWrapper {
  margin: 0;

  .tr {
    display: flex;
    flex-flow: row nowrap;
    flex-grow: 1;

    &.inboxSegment {
      width: 100%;
      padding: 10px 16px 10px 12px;
      box-sizing: border-box;
      margin: 0;
      border: none;
      background-color: transparent;
      @include themed() {
        border-bottom: 1px solid t(color-border);
      }
      cursor: pointer;

      &.inboxSegment:hover {
        @include themed() {
          background-color: t(color-background-stronger);
        }
      }

      .summary {
        display: flex;
        padding: 0 3px;

        .td.text {
          flex: 100%;
        }

        .messageCountBubble {
          width: 21px;
          height: 21px;
          display: flex;
          justify-content: center;
          align-content: center;
          border-radius: 50%;
          @include themed() {
            background-color: t(color-status-info);
            color: t(color-background-strongest);
          }
          @include label-XXS;
        }
      }

      .td,
      .td_shrink,
      .td_grow2 {
        display: flex;
        flex-flow: row wrap;
        flex-grow: 1;
        align-items: flex-start;
        word-break: break-word;
        text-align: left;

        &.title {
          display: inline-block;
          @include label-S;
          @include themed() {
            color: t(color-text);
          }
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 135px;

          svg {
            position: relative;
            bottom: -2px;
          }
        }

        &.duration {
          display: inline-block;
          text-align: right;
          @include body-XS;
          @include themed() {
            color: t(color-text-weaker);
          }
          white-space: nowrap;
          width: 65px;
        }

        &.text {
          @include body-XS;
          @include themed() {
            color: t(color-text-inverse-dark-weak);
          }

          .bold {
            font-weight: 600;
          }

          @include themed() {
            // Color selector
            &.default {
              color: t(color-text-weaker);
            }

            &.critical {
              color: t(color-text-critical);
            }
            &.primary {
              color: t(color-primary);
            }
          }
        }

        & > svg {
          height: 15px;
          margin-right: 3px;
        }
      }

      .td_shrink {
        flex-grow: 0;
      }

      .td_grow2 {
        flex-grow: 2;
      }
    }
  }
}

.icon {
  width: 16px;
  height: 18px;
}
