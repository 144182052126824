@import 'styles/design-tokens/Fonts';
@import 'styles/Colors';
@import '../app/appointments/ScheduleContainer';
@import 'styles/Variables';

html,
body {
  padding: 0;
  margin: 0;
  font-family: $appFont !important;
  @include themed() {
    background-color: t(color-background);
    color: t(color-text);
  }
  height: 100%;
  width: 100%;
  #root {
    height: 100%;
    > div {
      // app wrapper div
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}
/* Remove unused scroll bar at the html level 
 * The redundant class selector is to give precedence to the rule
 */
html[class='core-ui'].core-ui {
  overflow: hidden;
}

h1 {
  font-family: $appFont;
  font-size: 24px;
  line-height: 30px;
  color: $darkestSlate;
  font-weight: 800;

  small {
    display: block;
    font-weight: 400;
    font-size: 16px;
  }
}

h2 {
  font-size: 20px;
  margin: 0;
  padding: 0;
  color: $h2Color;
  font-weight: bold;
}

h3 {
  small {
    color: $slate;
    font-weight: normal;
  }
}

h5 {
  margin: 1rem 0 0.25rem 0;
  color: $darkestSlate;
}

.core-ui table {
  width: 100%;
  border: none;
  border-collapse: collapse;

  thead {
    th {
      text-align: left;
    }
  }

  td.MuiTableCell-root {
    padding: 1rem;
    border-bottom: 1px solid $fadedSlate;
  }

  tr {
    border: none;

    &:last-child {
      td {
        border-bottom: none;
      }
    }
  }
}

a.link {
  color: $teal;
  text-decoration: none;
}

a {
  &:visited {
    color: $mui-primary;
  }
}
