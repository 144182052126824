@import 'styles/design-tokens/Colors.scss';
@import 'styles/design-tokens/Typography.scss';

.link {
  margin-top: 12px;
  @include label-XS;
  text-transform: initial;

  @include themed() {
    color: t(color-primary);
    &:visited {
      color: t(color-primary);
    }
  }
}
