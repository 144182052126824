@import 'styles/Colors';
@import 'styles/Variables';
@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography.scss';

.popper {
  .tooltip {
    background-color: $tooltipColor;
    border-radius: 2px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24);
    color: $white;
    margin: 8px 0;
    font: 12px/24px $appFont;
    white-space: pre-wrap;
  }

  .tooltipV2 {
    @include themed() {
      background-color: t(color-surface-gradient);
      color: t(color-surface-gradient-inverted);
    }
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    margin: 8px 0;
    max-width: 400px;
    @include body-XS;
  }

  span.tooltipArrow {
    color: $tooltipColor;
  }

  span.tooltipArrowV2 {
    @include themed() {
      color: t(color-surface-gradient);
    }
  }

  .compactTooltip {
    color: #fff;
    background-color: #272727;
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
    height: 11px;
    border-radius: 5px;
    display: flex;
    align-items: center;

    /* The ":first-child" selector overrides the Mui class with 14px margin */
    &:first-child {
      margin-top: 4px;
    }
  }
}
