@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';
@import 'styles/Variables';

.container {
  display: flex;
  align-items: normal;
  margin-left: auto;
}
.PHQInfo {
  text-wrap: nowrap;
}
.GADInfo {
  margin: 0 30px;
  text-wrap: nowrap;
}
div.loader {
  margin-top: 3rem;
}
