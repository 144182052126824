@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography.scss';

.root {
  height: 100%;
  padding: 16px 0;
  .label {
    @include themed() {
      color: t(color-primary);
    }
    @include label-M;
  }

  .label_critical {
    @extend .label;
    @include themed() {
      color: t(color-background-critical);
    }
  }

  .divider {
    margin-top: 10px;
    margin-left: 1em;
    @include themed() {
      border: 1px solid t(color-primary);
    }
  }

  .divider_critical {
    @extend .divider;
    @include themed() {
      border: 1px solid t(color-background-critical);
    }
  }

  .button {
    border-radius: 72px;
    min-width: 72px;
    height: 24px;
    @include themed() {
      background-color: t(color-primary);
      color: t(color-background);
    }
    @include label-XS;
    text-transform: unset;
  }

  .deleteButton {
    padding: 0;
    margin-left: 8px;
  }

  .subtext {
    @include label-XS;
    @include themed() {
      color: t(color-primary);
    }
    margin: 0;
  }

  .subtext_critical {
    @extend .subtext;
    @include themed() {
      color: t(color-background-critical);
    }
  }
}
