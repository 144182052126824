@import 'styles/design-tokens/Colors.scss';

.increase {
  display: flex;
  @include themed() {
    color: t(color-status-critical);
  }
  margin-left: -4px;
  margin-right: 4px;
}
.decrease {
  display: flex;
  @include themed() {
    color: t(color-status-success);
  }
  margin-left: -4px;
  margin-right: 4px;
}
