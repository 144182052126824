@import 'styles/design-tokens/Colors';
@import 'styles/Colors';
@import 'styles/Mixins';
@import 'styles/design-tokens/Typography.scss';

.root {
  height: 100%;
  overflow: hidden;

  .col {
    height: 100%;
    padding: 1rem;
    overflow: scroll;

    &:last-child.auditView {
      @include themed() {
        border-left: 1px solid t(color-divider);
      }
    }

    .sidebar {
      background: #f6f6f6;
      min-width: 180px;
      max-width: 200px;
      border-radius: 8px;
      padding-top: 8px;
      min-height: 680px;
    }
  }

  .tabs {
    margin: 0 16px 0 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
    height: 100%;
    overflow: auto;

    li {
      cursor: pointer;
    }

    .tab {
      @include body-XXS;
      text-transform: capitalize;
      display: flex;
      flex: 1;
      color: $mediumSlate;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;

      svg {
        color: $grey;
      }

      &.active {
        color: $noteTabTitle;
        background: $noteTabBackground;
        border-radius: 0 20px 20px 0;
        font-weight: 700;

        svg {
          color: $noteTabTitle;
        }
      }

      &.completed {
        svg {
          color: $noteTabTitle;
        }
      }
    }
  }

  .tabPanel {
    margin-bottom: 48px;
    height: 80%;
    overflow: auto;

    .title {
      @include themed() {
        color: t(color-text);
      }
      margin-bottom: 1rem;
    }

    hr {
      margin-bottom: 1rem;
    }
  }

  .tabsV2 {
    @extend .tabs;
    .tab {
      @include themed() {
        &.active {
          background: unset;
          color: t(color-status-info);
        }
        &.completed {
          svg {
            color: t(color-status-info);
          }
        }
      }
    }
  }

  .tabPanelV2 {
    @extend .tabPanel;
    padding: 8px 16px 8px;
  }
}
