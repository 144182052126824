@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography.scss';

.container {
  margin: 0 auto;
  padding: 32px 20px 0 20px;
  .title {
    margin: 0 0 12px 0;
    @include heading-S;
    @include themed() {
      color: t(color-text-weak);
    }
  }
  .subtitle {
    margin: 0;
    @include label-M;
    @include themed() {
      color: t(color-text-weak);
    }
  }
  .text {
    @include body-S;
    @include themed() {
      color: t(color-text);
    }
  }

  .picture {
    margin: 60px auto;
    width: 350px;
    height: 256px;
  }

  .list {
    margin: 0 0 4px 0;
    .item {
      @include body-S;
      @include themed() {
        color: t(color-text);
      }
    }
  }
}
