@import 'styles/design-tokens/Typography';

.PHQ9Info {
  margin: 20px 0;
  position: relative;
  display: flex;
  flex-wrap: nowrap;

  .main {
    margin: 2px 0;
    display: flex;
    align-items: center;
  }
  .title {
    @include label-XXXS;
    text-align: left;
  }
  .total {
    @include body-S;
    text-align: left;
  }
  .timestamp {
    @include body-XS;
    text-align: left;
  }
  .icon {
    padding-right: 4px;
  }
}
.tooltipLine {
  @include body-XXS;
  display: flex;
  text-align: left;
}

.flag {
  position: absolute;
  left: 30px;
  top: -7px;
}
