@import 'styles/Variables';

/* HEADING */

@mixin _heading {
  font-family: $appFont;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -0.05em;
}

@mixin heading-XXL {
  font-size: 80px;
  @include _heading;
}
@mixin heading-XL {
  font-size: 64px;
  @include _heading;
}
@mixin heading-L {
  font-size: 48px;
  @include _heading;
}
@mixin heading-M {
  font-size: 32px;
  @include _heading;
}
@mixin heading-S {
  font-size: 24px;
  @include _heading;
}

/* BODY */

@mixin _body {
  font-family: $appFont;
  font-weight: 400;
  line-height: 1.5;
}

@mixin body-L {
  font-size: 20px;
  @include _body;
}
@mixin body-M {
  font-size: 18px;
  @include _body;
}
@mixin body-S {
  font-size: 16px;
  @include _body;
}
@mixin body-XS {
  font-size: 14px;
  @include _body;
}
@mixin body-XXS {
  font-size: 12px;
  @include _body;
}
@mixin body-XXXS {
  font-size: 10px;
  @include _body;
}

/* LABEL */

@mixin _label {
  font-family: $appFont;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -0.03em;
}

@mixin label-L {
  font-size: 20px;
  @include _label;
}
@mixin label-M {
  font-size: 18px;
  @include _label;
}
@mixin label-S {
  font-size: 16px;
  @include _label;
}
@mixin label-XS {
  font-size: 14px;
  @include _label;
}
@mixin label-XXS {
  font-size: 12px;
  @include _label;
}
@mixin label-XXXS {
  font-size: 10px;
  @include _label;
}

/* LINK */

@mixin _link {
  font-family: $appFont;
  font-weight: 400;
  line-height: 1.5;
}

@mixin link-L {
  font-size: 20px;
  @include _link;
}
@mixin link-M {
  font-size: 18px;
  @include _link;
}
@mixin link-S {
  font-size: 16px;
  @include _link;
}
@mixin link-XS {
  font-size: 14px;
  @include _link;
}
@mixin link-XXS {
  font-size: 12px;
  @include _link;
}
@mixin link-XXXS {
  font-size: 10px;
  @include _link;
}

/* UTILITY */

@mixin _utility {
  font-family: $appFont;
  line-height: 1.5;
  letter-spacing: 0.04em;
  font-weight: 400;
}

@mixin utility-L {
  font-size: 14px;
  @include _utility;
}
@mixin utility-M {
  font-size: 12px;
  @include _utility;
}
@mixin utility-S {
  font-size: 10px;
  @include _utility;
}
@mixin utility-XS {
  font-size: 8px;
  @include _utility;
}
