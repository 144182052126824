@import 'styles/Colors';
@import 'styles/design-tokens/Colors.scss';
@import 'styles/design-tokens/Typography.scss';

.hide {
  display: none;
}

.nav {
  margin: 1rem 0;
  display: flex;
  align-items: center;

  .navTab {
    flex: 1;
  }

  .header {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: 24px;

    h1,
    h2 {
      margin: 0;
      padding: 0;
    }

    h2 {
      font-weight: 400;
      font-size: 18px;
    }
  }
}

.layout {
  height: 100%;
  padding-right: 6px;
  background: inherit;
}
.column {
  height: 100%;
  background: inherit;
  overflow-y: scroll;
  padding-bottom: 16px;

  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    @include themed() {
      background-color: t(color-interactive-strongest);
    }
  }

  .pauseUnpauseOutrechModal {
    .modalTitle {
      @include heading-S;
      @include themed() {
        color: t(color-text-inverse-dark);
      }
      margin-top: 24px;
      margin-left: 24px;
      margin-right: 24px;
      display: flex;
      justify-content: space-between;
      background-color: red;

      .close {
        margin-top: 5px;
        cursor: pointer;
      }
    }

    .modalBody {
      width: 500px;

      .text {
        @include body-S;
        @include themed() {
          color: t(color-text-weak-alt);
        }

        span {
          @include label-S;
          @include themed() {
            color: t(color-primary);
          }
        }
      }

      .noteText {
        @include body-XXS;
        @include themed() {
          color: t(color-text-weak-alt);
        }
      }

      .buttonContainer {
        display: flex;
        justify-content: flex-end;
        gap: 12px;
      }
    }
  }
}
