@import 'styles/Colors';
@import 'styles/Mixins';
@import 'styles/Variables';

.timeField {
  display: flex;
  align-items: center;

  input {
    min-width: 8rem;
  }

  .to {
    margin: 0 8px;
  }
}
