@import 'styles/Mixins';
@import 'styles/design-tokens/Colors.scss';
@import 'styles/design-tokens/Typography.scss';

.root {
  margin-left: -3px;

  .label {
    @include themed() {
      color: t(color-text-weak);
    }
    @include body-S;
    line-height: 24px;
    padding-left: 4px;
  }

  .disabled {
    @include themed() {
      color: t(color-text-weakest);
    }
  }

  span.radioInput {
    padding: 4px;
  }
  span.checked {
    @include themed() {
      color: t(color-primary);
    }
  }
  svg {
    width: 16px;
    height: 16px;
  }
}
