@import 'styles/Colors';
@import 'styles/Mixins';

.root {
  padding: 16px;
  .container {
    height: 80%;
    padding: 32px;
  }
  .paper {
    padding: 24px;
  }
  .loader {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    align-content: center;
  }

  .auditLogs {
    list-style: none;
    margin: 0;
    padding: 0;

    .log {
      &:first-child {
        padding-top: 0;
      }

      padding: 16px 0;

      .title {
        @include app-font($weight: 600);
        margin-bottom: 8px;
        overflow-wrap: break-word;
      }

      .detail {
        @include app-font(12px);
        display: flex;
        justify-content: space-between;
        color: $slate;
      }
    }
  }
  .dialogTitle {
    color: $darkestSlate;
    padding: 0 0 12px 24px;

    .title {
      @include app-font(24px, $weight: 500);
    }
    .closeButton {
      position: absolute;
      right: 24px;
      padding: 0;
      margin: 0;
    }
  }
  .modalBody {
    .modalText {
      @include app-font(14px);
      color: $mediumSlate;
    }
  }
}
