@import 'styles/Colors';
@import 'styles/Variables';
@import 'styles/design-tokens/Colors';

.appointments {
  background: $white;

  .newAppointment {
    padding-left: 23px;
    padding-right: 33px;
    align-self: flex-end;
    span {
      font-size: 14px;
      text-transform: none;
    }
  }

  .appointmentCell {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 9rem;
  }

  h2.title {
    padding-left: 16px;
  }

  .table {
    .head {
      th:last-child {
        width: $tableActionItemWidth;
        padding-right: 0;
      }
    }

    tr {
      td .iconActionBar {
        display: none;
      }

      &:hover {
        td .iconActionBar {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}

.memberStats {
  font-size: 0.875rem;
  @include themed() {
    color: t(color-text);
  }

  .value {
    font-weight: bold;
  }
  .icon {
    top: 0.25em;
    position: relative;
  }
}

.memberScheduleStatsWrapper {
  position: sticky;
  top: 60.5px;
  z-index: 1000;
  overflow: hidden;
  background-color: inherit;

  .memberScheduleStats {
    background: $mediumGray;
    margin: 16px 16px 0 16px;
    border-radius: 8px;
    padding: 14px;

    .text {
      font-size: 15px;
      line-height: 16px;
      font-weight: 400;
    }
    .value {
      font-weight: 700;
      padding: 3px;
    }
  }
}

.memberFilter {
  border-color: $fadedSlate;
  padding: 7px;
  max-width: 25rem;
  min-width: 25rem;
  padding-right: 1em;
}

.centeredGridItem {
  display: flex;
  align-items: center;
}

.justifyEnd {
  justify-content: flex-end;
}

.borderCollapseSeparate {
  background-color: inherit;
  overflow: initial;
}
.borderCollapseSeparate > table:global.MuiTable-root {
  background-color: inherit;
  border-collapse: separate;
}
.borderCollapseSeparate button:global.MuiButtonBase-root.MuiIconButton-root {
  position: initial;
}
