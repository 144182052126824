@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';

.title {
  margin: 0 0 0 12px;
  @include body-XS;
  @include themed() {
    color: t(color-text-weaker);
  }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .time {
    @include themed() {
      color: t(color-text-weak);
    }
    margin-left: -3px;
  }
}
