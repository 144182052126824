@import 'styles/Colors';
@import 'styles/Mixins';
@import 'styles/Variables';

$componentWidth: 250px;
$inputWidth: 60px;
.root {
  width: $componentWidth;
  align-items: center;
  display: flex;
  justify-content: space-between;

  .dropdown {
    width: 120px;
  }

  .input {
    width: $inputWidth;

    input {
      text-align: right;
    }
  }
}
