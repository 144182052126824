@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography.scss';
@import 'styles/Variables';

.container {
  $padding: 37px; // margin-top + margin-bottom
  box-sizing: border-box;
  margin-top: 12px;
  margin-bottom: 8px;
  height: calc(100vh - $memberHeaderHeight - $padding);
  grid-area: notes;
  width: 100%;

  &.withMultiTab {
    height: calc(100vh - $memberHeaderHeight - $multiTabHeight - $padding);
  }
}

.boxContent {
  height: calc(100vh - 101px - 48px - 42px - 37px);
}

.emptyContainer {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.emptyDescription {
  @include body-XS;
  width: 200px;
  margin-top: 12px;
  text-align: center;
  @include themed() {
    color: t(color-text-weaker);
  }
}

.draftMenuContainer {
  display: flex;
  align-items: center;
}

.draftMenu {
  @include themed() {
    background-color: t(color-interactive-strong);
    color: t(color-text-weak);
    box-shadow: 0 2px 0 t(shadow-default);

    &:hover {
      background-color: t(color-interactive-strong);
      color: t(color-text-weak);
    }
  }
}

.publishButton {
  width: 110px;
  height: 24px;
  border-radius: 72px;
  padding: 4px 16px;
  margin-left: 8px;
  @include themed() {
    box-shadow: 0 2px 0 t(shadow-default);
  }

  &:disabled {
    @include themed() {
      background-color: t(color-primary);
    }
    opacity: 0.5;
    @include themed() {
      color: t(color-text-inverse-light);
    }
  }
}

.actionMenu {
  right: 12px !important;
}

.actionDialog {
  border-radius: 20px;

  h2 {
    @include heading-S;
    // these attributes all need !important otherwise the global h2 styling from App.scss overwrites them
    font-size: 24px !important;
    font-weight: 700 !important;
    padding: 24px 24px 16px !important;
    @include themed() {
      color: t(color-text-inverse-dark) !important;
    }
  }
}

.actionDialogBody {
  @include themed() {
    color: t(color-text-weak-alt);
  }
}

.actionDialogCancel {
  height: 32px;
  width: 114px;
  border-radius: 72px;
  @include themed() {
    background-color: t(color-background-stronger);
    color: t(color-text-inverse-dark) !important;
  }
  border: none;
  @include themed() {
    box-shadow: 0 2px 0 t(shadow-default);
  }

  &:hover {
    border: none;
    @include themed() {
      background-color: t(color-background-strongest) !important;
    }
  }
}

@mixin actionDialogConfirm {
  height: 32px;
  border-radius: 72px;
  @include themed() {
    box-shadow: 0 2px 0 t(shadow-default);
  }
}

.deleteDialogConfirm {
  @include actionDialogConfirm;
  width: 114px;
  @include themed() {
    background-color: t(color-background-critical);
    &:hover {
      background-color: t(color-background-critical-strong);
    }
  }
}

.publishDialogConfirm {
  @include actionDialogConfirm;
  width: 143px;
}

.coachNotesTable {
  max-height: calc(100vh - 48px);

  &.withMultiTab {
    max-height: calc(100vh - $memberHeaderHeight - $multiTabHeight - 85px);
  }

  table {
    border-collapse: separate;
  }

  th {
    height: 35px;
    padding: 0;
  }
}

.tableRow {
  &:hover {
    @include themed() {
      background-color: t(color-interactive-strong);
    }
  }
}

.unreadIndicator {
  vertical-align: baseline;

  span {
    margin-bottom: 1px;
  }
}

.sortLabel {
  margin: 8px 0 8px 8px;

  svg {
    @include themed() {
      color: t(color-text-weak) !important;
    }
  }
}

.headerCell {
  @include themed() {
    color: t(color-text-weak);
  }

  margin: 11px 0 8px 0;

  span {
    padding: 0 0 0 3px;
    margin-right: 4px;
  }

  svg {
    margin-bottom: 2px;
  }
}

.noteTableCard {
  cursor: pointer;
  padding-left: 0 !important;
}

.error {
  display: flex;
  align-items: center;
  background: #fee3de;
  padding: 8px;
  cursor: pointer;
  margin-top: -2px;
  @include body-XS;
  .errorText {
    flex: 1;
  }
  .errorDetailBtn {
    @include themed() {
      color: t(color-status-critical-strong);
    }
    background-color: #fee3de;
    padding: 4px;
    border-radius: 5px;
    text-transform: lowercase;
    text-decoration: underline;
  }
}
