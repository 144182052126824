@import 'styles/Colors';
@import 'styles/Variables';
@import 'styles/design-tokens/Typography.scss';
@import 'styles/design-tokens/Colors';

.root {
  align-items: center;
  margin-bottom: 16px !important;
  .label {
    padding: 0;
    margin-right: 12px;

    label {
      @include themed() {
        color: t(color-text);
      }
      @include body-XS;
      font-weight: 700;
    }
  }
}

span.radioRoot {
  @include themed() {
    color: t(background-color-legacy-tumeric);
  }
}
