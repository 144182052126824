@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  @include body-XS;
  word-break: normal;
  @include themed() {
    color: t(color-text-weak);
  }
}

@mixin linkButton {
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 10px;
  &:hover,
  &:focus {
    outline: none;
  }
}

.blueButton {
  @include linkButton;
  @include themed() {
    color: t(color-text-link);
  }
}

.grayButton {
  @include linkButton;
  @include themed() {
    color: t(color-text-weaker);
  }
}
