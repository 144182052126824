@import 'styles/design-tokens/Typography';
@import 'styles/design-tokens/Colors';

.nameContainer {
  @include label-S;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 12px;
  gap: 4px;
}

.roleContainer {
  @include body-XS;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 12px;
  gap: 4px;
}

.specializationContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  @include utility-XS;
  text-transform: uppercase;
  padding-left: 12px;
  gap: 4px;
}

.specialization {
  @include themed() {
    background-color: t(color-status-success-strong);
  }
  color: white;
  border-radius: 3px;
  padding: 0px 2px;
  text-align: center;
}

.leadCoachIcon {
  fill: #e5ab33;
}
