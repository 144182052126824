@import 'styles/design-tokens/Colors.scss';
@import 'styles/design-tokens/Typography.scss';
@import 'styles//Mixins';
@import 'styles//Variables';

.root {
  .table {
    tbody {
      tr.rowEdit {
        td {
          padding: 8px;
        }

        input {
          padding: 8px;
        }
      }

      tr.rowView {
        .actionBtnGroup {
          visibility: hidden;
        }

        &:hover {
          @include themed() {
            background-color: t(color-border);
          }
          .actionBtnGroup {
            visibility: visible;
          }
        }
      }
    }
  }
}

.rowView {
  td:last-child {
    display: flex;
    justify-content: space-between;
  }

  .actionBtnGroup {
    visibility: hidden;
  }

  &:hover {
    @include themed() {
      background-color: t(color-border);
    }
    .actionBtnGroup {
      visibility: visible;
    }
  }
}

.cellValue {
  line-height: 3rem;
}

.cell {
  padding: 0 1rem !important;
  border-bottom: 0 !important;
}

.textAreaLabelRoot {
  margin: 0;
}
