@import 'styles/design-tokens/Colors.scss';
@import 'styles/design-tokens/Typography.scss';

.tabs {
  margin: 10px 3px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* This CSS spell replaces the margins of elements. It's safer since we never know what elemment will be 
  shifted to the second row due to the size of the container. So instead of trying to guess this and hard-code that n-th element 
  should have 0 margin we set the row and column gaps with this property */
  gap: 10px 30px;

  li {
    display: flex;

    a {
      @include label-S;
      padding: 2px 0;
      display: flex;
      @include themed() {
        color: t(color-text-weaker);
      }
      text-decoration: none;
      text-transform: capitalize;
      transition: all 0.25s;
      border-bottom: 3px solid transparent;
      cursor: pointer;
      @include themed() {
        &.active {
          color: t(color-primary);
          border-bottom-color: t(color-primary);
        }
        &:hover {
          color: t(color-primary);
          border-bottom-color: t(color-primary);
          transition: all 0.3s ease;
        }
      }
    }
  }
}

li:first-of-type {
  margin-left: 0;
}

.nav {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;
  background: inherit;
  overflow: visible;
  padding: 16px 0;

  .mutationState {
    margin: 0;
  }
}

.tabsV2 {
  flex: 1;
  @extend .tabs;
}
