@import 'styles/design-tokens/Colors';

.sessionContainer {
  @include themed() {
    border-bottom: 1px solid t(color-border-strong);
  }
  padding: 16px;

  &:hover {
    @include themed() {
      background-color: t(color-interactive-strong);
    }
    cursor: pointer;
  }
}
