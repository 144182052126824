@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem 0.75rem;

  .title {
    @include body-XS;
    margin: 0;
    @include themed() {
      color: t(color-text-weaker);
    }
  }

  .reloadBtn {
    @include label-XS;
    outline: none;
    background: none;
    border: none;
    @include themed() {
      color: t(color-text);
    }
    cursor: pointer;
    width: 100%;
  }
}
