@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography.scss';

.container {
  margin: 22px 16px 12px 0;
  border-radius: 6px;
  @include body-XS;
  font-weight: 500;
  @include themed() {
    background-color: t(color-background-tag-rose);
    color: t(color-text-weak);
  }
  display: flex;
  padding: 12px 12px 16px;

  .icon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  .text {
    margin: 0;
  }
}
