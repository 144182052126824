@import 'styles/Colors';
@import 'styles/design-tokens/Colors.scss';
@import 'styles/design-tokens/Typography.scss';

.pauseUnpauseOutrechModalContainer {
  width: 320px;
  border-radius: 1px;
}

.avartarContainer {
  display: flex;
  align-items: left;
  flex-wrap: nowrap;
}

.helpIcon {
  margin-right: 8px;
}

.pauseUnpauseOutrechModal {
  .modalContainer {
    border-radius: 24px !important;
  }

  .modalTitle {
    @include heading-S;
    @include themed() {
      color: t(color-text-inverse-dark);
    }
    margin-top: 24px;
    margin-bottom: 24px;
    margin-left: 24px;
    margin-right: 24px;
    display: flex;
    justify-content: space-between;

    .close {
      margin-top: -10px;
      cursor: pointer;
    }
  }

  .modalBody {
    width: 500px;

    .text {
      @include body-S;
      @include themed() {
        color: t(color-text-weak-alt);
      }

      span {
        @include label-S;
        @include themed() {
          color: t(color-primary);
        }
      }
    }

    .noteText {
      @include body-XXS;
      @include themed() {
        color: t(color-text-weak-alt);
      }
    }

    .buttonContainer {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
    }
  }

  button.enableDisable {
    @include body-XS;
    @include themed() {
      color: t(color-surface-static);
      background-color: t(color-text-link);
    }
    box-shadow: 0 2px 0 rgba(26, 25, 25, 0.2);
    height: 30px;
    border-radius: 14px;

    &:disabled {
      opacity: 0.5;
    }
  }

  button.cancel {
    @include themed() {
      color: t(color-text-inverse-dark);
      background-color: t(color-text-inverse-light);
      border: t(color-text-inverse-light);
    }
    box-shadow: 0 2px 0 rgba(26, 25, 25, 0.2);
    &:disabled {
      opacity: 0.5;
    }
    height: 30px;
    border-radius: 12px;
  }
}
