@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';

.chatTextareaGroup {
  flex-shrink: 0;
  width: 100%;
  box-sizing: border-box;
  padding-left: 8px;
  @include themed() {
    background-color: t(color-background);
  }

  .textareaContainer {
    position: relative;
    .textArea {
      @include body-S;
      flex-grow: 1;
      overflow: hidden;
      resize: none;
      outline: none;
      padding: 8px;
      background: transparent;
      @include themed() {
        color: t(color-text-weak);
        border: 1px solid t(color-border);
      }
      border-radius: 6px;
      width: 100%;

      &.textArea:disabled {
        cursor: not-allowed;
      }
    }
    .infoSection {
      display: flex;
      flex-wrap: wrap;
      margin: 8px 0 !important;
      padding-left: 0 !important;

      .label {
        @include body-XS;
        flex-grow: 1;
        line-height: 32px;
        padding-left: 8px;
      }
      .checkbox {
        width: 205px;
        padding-left: 8px;
      }

      .checkBoxClassName {
        width: 100%;
        @include themed() {
          color: t(color-primary);
        }
      }
      .rootCheckbox {
        font-size: 14px !important;

        svg {
          padding: 0 !important;
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}
