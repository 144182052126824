@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';

.body {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 12px 0 0;
  .content {
    align-items: inherit;
    display: inherit;
    flex-direction: inherit;
    width: 100%;
    .surveyScore {
      display: flex;
      flex-direction: column;
      background-color: white;
      border-radius: 8px;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
      .graph {
        padding: 24px 24px 0;
      }
    }
  }
}
