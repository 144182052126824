@import 'styles/Variables';

@mixin app-font($fontSize: 14px, $lineHeight: 18px, $weight: inherit) {
  font-family: $appFont;
  font-size: $fontSize;
  line-height: $lineHeight;
  font-weight: $weight;
}

@mixin checkBoxSize($size: 18px) {
  width: $size;
  height: $size;
}
