.prescriptions {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: 0;
  transform: scale(0.75);
  transform-origin: 0 0;
  width: calc(133%);
  height: calc(133%);
}

.prescriptionsV2 {
  @extend .prescriptions;
  min-height: 316px;
}
