@import 'styles/Variables';
@import 'styles/design-tokens/Colors';

.container {
  @include themed() {
    background-color: t(color-surface);
  }
  border-radius: 0;
  box-shadow: none;
  @include themed() {
    border-bottom: 1px solid t(color-border-strong);
  }
  .newAppointment {
    padding-left: 23px;
    padding-right: 33px;
    span {
      font-size: 14px;
      text-transform: none;
    }
  }

  .appointmentCell {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 9rem;
  }

  h2.title {
    padding-left: 16px;
  }

  .table {
    .head {
      th:last-child {
        width: $tableActionItemWidth;
        padding-right: 0;
      }
    }

    tr {
      td .iconActionBar {
        display: none;
      }

      &:hover {
        td .iconActionBar {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
