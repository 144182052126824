@import 'styles/Colors';
@import 'styles/Mixins';

.root {
  .header {
    @include app-font(14px, 17px);
    color: $fontColor;
    display: flex;
    align-items: center;
    margin: 1rem;
  }

  .backBtn {
    @include app-font(23px, 24px);
    display: inline-flex;
    align-items: center;
    .text {
      margin: 0 1rem;
    }
  }

  .content {
    flex-grow: 1;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;

      li {
        $spacing: 1rem;
        margin-right: $spacing;
        padding: 0;
        display: flex;
        align-items: center;
        svg {
          margin-top: -2px; // small hack to make icons appear centered with the text (optical illusion)
          margin-right: calc(#{$spacing} / 2);
          color: $primary;
        }
      }
    }
  }

  .action {
    display: flex;
    width: 320px;

    button.signBtn {
      @include app-font(14px, 17px, 500);
      background-color: $noteTabTitle;
      color: $white;

      &:disabled {
        opacity: 0.5;
      }
    }
    button.actionsBtn {
      margin-left: 10px;
      border: 2px solid $noteTabTitle;
      color: $noteTabTitle;
      &:disabled {
        opacity: 0.5;
      }
    }
  }
}

.noteDeletionModal {
  .modalTitle {
    @include app-font(16px, 19px);
    background: $dutchWhite;
    display: flex;

    .titleText {
      margin-left: 15px;
    }
  }

  .modalBody {
    min-height: 150px;
    .modalText {
      @include app-font(14px, 20px);
    }
    .buttonContainer {
      margin-top: 40px;
      display: flex;
      justify-content: flex-end;
      .deleteBtn {
        margin-left: 5px;
      }
    }
  }
}
