@import 'styles/design-tokens/Typography.scss';
@import 'styles/design-tokens/Colors.scss';
@import 'styles/Variables';

.textFieldWrapper {
  position: relative;

  .textFieldButton {
    position: absolute;
    z-index: 10;
    bottom: -8px;
    width: calc(100% - 2px);
    cursor: pointer;
    border: none;
    @include themed() {
      background-color: t(color-background);
      color: t(color-text-link);
    }
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 12px;
    @include link-XS;
    text-decoration: underline;

    .textFieldIcon {
      width: 20px;
      height: 20px;
      margin-right: 4px;
      vertical-align: middle;
      transition: transform 500ms;
    }

    .textFieldIconUpsideDown {
      transform: rotate(180deg);
      transition: transform 500ms;
    }
  }
  @include themed() {
    &.textFieldWrapperGradientLayer {
      &:after {
        position: absolute;
        left: 1px;
        bottom: 30px;
        height: 48px;
        width: calc(100% - 2px);
        background: linear-gradient(
          180deg,
          t(color-foreground) 0%,
          t(color-background) 79.34%
        );
        content: '';
        pointer-events: none;
        animation: fadeInFromNone 0.8s ease-out;
      }
    }
  }

  .textFieldButtonForExpandedState {
    bottom: 40px;
  }
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}
.editMode {
  border-radius: 6px;
  @include themed() {
    border: 1px solid t(color-border);
  }
}

.textField {
  padding: 8px 12px;
  box-sizing: border-box;
  width: 100%;

  &.focusedTextField {
    border: 1px solid;
    @include themed() {
      border-color: t(color-primary);
    }
  }
}

.textField::placeholder {
  @include body-XS;
  @include themed() {
    color: t(color-text-weakest);
  }
}

.root {
  // if this is updated, the getNumberOfLines function lineHeightInPx param
  // in app/member-chart-cards/text-area/CardTextArea.tsx must be updated as well
  line-height: 1.5; // corresponds to 24px
}

.input {
  @include body-S;
  @include themed() {
    color: t(color-text-weak);
  }
  position: relative;
  box-sizing: border-box;
  overflow-y: hidden;

  .focused::before {
    border: none !important;
  }
}

.input::before {
  border: none !important;
}

.input:focus {
  overflow-y: scroll;
}

.inputWithPaddingForButton {
  padding-bottom: 43px;
}

.input::-webkit-scrollbar {
  display: none;
}

.input::placeholder {
  @include themed() {
    color: t(color-text-weakest);
  }
  opacity: 1;
}

.readOnly {
  border: none;
  padding: 0 !important;
}
