@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography.scss';

button.launchBtn {
  @include themed() {
    color: t(color-text-inverse-dark);
    background-color: t(color-text-inverse-light);
    border: t(color-text-inverse-light);
  }
  box-shadow: 0 2px 0 rgba(26, 25, 25, 0.2);
  &:disabled {
    opacity: 0.5;
  }
  height: 30px;
  border-radius: 12px;
  text-transform: capitalize;
  vertical-align: bottom;
}

.rotateIcon {
  transform: rotate(-20deg);
}
