@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';

.contentTab {
  padding: 0;

  .goalsContentContent {
    padding: 5px 10px 0;
  }

  .viewLibraryButton {
    @include label-S;
    padding-top: 3px;
    @include themed() {
      background-color: t(color-interactive);
      color: t(color-text-inverse-dark);
      border: 2px solid t(border-color-light-gray);
      box-shadow: 0px 2px 0px t(background-color-black-opaque);
    }
    border-radius: 72px;
    cursor: pointer;
  }
}
