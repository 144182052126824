@import 'styles/design-tokens/Colors';

.accordionTitle {
  @include themed() {
    color: t(color-primary);
    background-color: t(color-background-tag-cloud-strong);
  }
  margin-bottom: 8px;
}
.cardSection {
  padding: 0 16px 16px;
}
.editBtn {
  width: 48px;
  border-radius: 100;
  left: 16px;
}
.accordionText {
  margin: 8px 16px;
  font-weight: 400;
  @include themed() {
    color: t(color-text-weakest) !important;
  }
}
.prescriptionContent {
  font-size: 14px;
}
.activePrescriptionItem {
  font-size: 14px;
  padding-bottom: 2px;
  line-height: 20px;
}
