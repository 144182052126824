@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography.scss';

@mixin menuIcon($name) {
  background-image: url('/assets/inbox/#{$name}-default.svg');
  background-repeat: no-repeat;
  background-size: 36px;

  &:hover {
    background-image: url('/assets/inbox/#{$name}-hover.svg');
  }

  &.active {
    background-image: url('/assets/inbox/#{$name}-active.svg') !important;
    pointer-events: none;
  }
}

.menuItemBtn {
  border: none;
  background: none;
  outline: none;
  padding: 0;
  margin: 0;
}

.top {
  width: 100%;
  height: 50%;

  hr {
    border-top: #e9e9e9 solid 1px;
    margin: 10px 5px 10px;
  }

  .gingerAvatarIcon {
    @include themed() {
      border: 1px solid t(color-border-strong);
    }
    &.online {
      @include themed() {
        border: solid 2px t(color-status-online);
      }
    }
    &.offline {
      @include themed() {
        border: solid 2px t(color-status-offline);
      }
    }
    &.replyOnly {
      @include themed() {
        border: solid 2px t(color-status-readonly);
      }
    }
    &.acceptingItms {
      @include themed() {
        border: solid 2px t(color-status-info);
      }
    }
  }

  .menuItem {
    height: 36px;
    width: 36px;
    border-radius: 6px;
    margin: 5px;
    cursor: pointer;
  }

  .inboxIcon {
    @include menuIcon(inbox);

    .inboxNotificationIcon {
      float: right;
      width: 11px;
      padding: 4px;
    }
  }

  .inboxIcon {
    @include menuIcon(inbox);

    .inboxNotificationIcon {
      float: right;
      width: 11px;
      padding: 4px;
    }
  }

  .memberChartsIcon {
    @include menuIcon(member-charts);
  }

  .scheduleIcon {
    @include menuIcon(schedule);
  }

  .appointmentsIcon {
    @include menuIcon(appointments);
  }

  .prescriptionsIcon {
    @include menuIcon(prescriptions);
  }

  .resourcesIcon {
    @include menuIcon(resources);
  }

  .settingsIcon {
    @include menuIcon(settings);
  }

  .quickLinksIcon {
    @include menuIcon(quick-links);
  }
}

.bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 50%;

  .menuItem {
    height: 36px;
    width: 36px;
    border-radius: 6px;
    margin: 5px;
    cursor: pointer;
  }

  .gingerLogoIcon {
    background-image: url('/assets/inbox/ginger-logo.svg');
    background-repeat: no-repeat;
    background-size: 2rem;
    pointer-events: none;
  }

  .headspaceLogoIcon {
    background-image: url('/assets/imperfect-circle.svg');
    background-repeat: no-repeat;
    background-size: 2rem;
    margin-left: 0.5rem;
    pointer-events: none;
  }
}

div.popper {
  &[data-popper-placement*='right'] {
    .tooltip {
      margin-left: 16px;
      padding: 2px 8px;
      font-size: 16px;
    }
  }
}

.statusPopper {
  z-index: 999999;
  margin-left: 10px !important;
  .menuList {
    padding-bottom: 10px;
    margin-bottom: 0;
    @include body-XS;
    @include themed() {
      background-color: t(color-background);
      color: t(color-text);
      border: 1px solid t(color-border-strong);
      box-shadow: 0px 0px 8px t(background-color-black-opaque);
    }

    border-radius: 6px;

    .menuItem {
      width: 100%;
      text-align: left;
      margin: 0;

      .topLevelStatus {
        @include label-S;
        line-height: 24px;
        letter-spacing: -3%;
      }

      &.menuItem:hover {
        @include themed() {
          background-color: t(color-background-tag-cloud);
        }
      }

      &.menuItem:active {
        @include themed() {
          background-color: t(color-background);
        }
      }

      .logout {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 16px;
        width: 100%;
        height: 24px;
        @include themed() {
          background-color: t(color-primary);
          box-shadow: 0px 2px 0px t(background-color-black-opaque);
        }
        border-radius: 72px;
        flex: none;
        order: 0;
        flex-grow: 1;
        cursor: pointer !important;
        margin-top: 10px;
        z-index: 999999;
      }

      &.specialMenuItem {
        pointer-events: none;

        .username {
          @include label-S;
        }
      }

      &.specialMenuButton:hover,
      &.specialMenuButton:active {
        @include themed() {
          background-color: t(color-background) !important;
        }
        cursor: default;
      }

      .listItemIcon {
        padding: 0;
        margin: 0;
        min-width: 0;
        margin-right: 8px;

        svg {
          height: 12px;
          width: 12px;
        }

        &.specialItemIcon {
          margin-top: -26px !important;
        }

        &.green {
          color: #82b713;
        }

        &.red {
          color: #e95538;
        }

        &.tumeric {
          color: #e5ab33;
        }

        &.blue {
          color: #00a4ff;
        }

        &.white {
          color: #ffffff;
        }
      }

      .endItemIcon {
        margin-right: -25px;

        svg {
          font-size: 16px;
        }
      }
    }

    .menuItemContainer {
      @include themed() {
        border-bottom: 1px solid t(color-border-strong);
      }
      margin-bottom: 12px;
      width: 215px;
    }
  }
}

.inboxIconV2 {
  width: 2rem;
  height: 2rem;
  padding: 0.25rem;
  border-radius: 10%;
  &:hover {
    @include themed() {
      background-color: t(color-background-strongest);
    }
  }
}
