@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography.scss';

.root {
  .control {
    display: flex;
    align-items: center;
    .input {
      margin-right: 8px;
      width: 100%;

      input {
        height: 8px;
        text-align: left;
        &.sm {
          @include label-XS;
        }
        &.md {
          @include label-M;
        }
      }
    }
  }
}
