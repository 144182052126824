@import 'styles/Colors';
@import 'styles/Mixins';
@import 'styles/Variables';

.root {
  display: flex;
  flex-direction: column;
  padding: 0;

  &.fullWidth {
    width: 100%;
  }

  &.halfWidth {
    width: 50%;
  }

  &.maxWidth {
    max-width: 400px;
  }

  .textArea {
    @include app-font($smallFontSize);
    resize: none;
    outline: none;
    padding: 0.5rem;
    width: 100%;
    background: $white;
    border: 1px solid $fadedSlate;
    color: $darkestSlate;
    border-radius: 3px;

    &:hover,
    :focus {
      border: 1px solid $slate;
    }

    &.textArea:disabled {
      cursor: not-allowed;
    }
  }

  .textAreaAutosize {
    @extend .textArea;
    width: auto;
    border-radius: 6px;
  }
}
