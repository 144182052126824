@import 'styles/design-tokens/Colors';
@import 'styles/Variables';

.wrapper {
  width: 100%;
  box-sizing: border-box;
  grid-area: chat;

  .container {
    width: 100%;
    height: calc(100vh - $memberHeaderHeight);

    &.withMultiTab {
      height: calc(100vh - $memberHeaderHeight - $multiTabHeight);
    }

    @include themed() {
      background-color: t(color-background);
    }

    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding-bottom: 16px;
    box-sizing: border-box;

    .newMessageButton {
      $buttonWidth: 152px;
      position: absolute;
      width: $buttonWidth;
      bottom: 85px;
      //this will center the button horizontally
      left: calc(50% - $buttonWidth / 2);
      z-index: 10;
    }

    .content {
      box-sizing: border-box;
      padding: 0 4px 0 16px;
      flex: 1;
      overflow-y: scroll;
      position: relative;
      display: flex;
      height: 100%;
      flex-direction: column-reverse;
      overflow-anchor: none;
    }
  }
}
