@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';

.container {
  width: 100%;
  margin-bottom: 24px;
  padding-right: 20px;
  box-sizing: border-box;

  .bubble {
    box-sizing: border-box;
    @include themed() {
      background-color: t(color-interactive-stronger-alt);
      color: t(color-foreground-weak);
    }
    padding: 12px;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    width: 100%;
    display: flex;
    .title {
      @include themed() {
        color: t(color-text-weak);
      }
      @include body-XS();
      //we don't have a design token w/ this font-weight
      font-weight: 500;
      margin: 0;
    }

    .text {
      @include themed() {
        color: t(color-text-weak);
      }
      @include body-XS();
      margin: 0;
    }
  }
}

.popper {
  .tooltip {
    margin-top: 4px !important;
    inset: 12px auto 0 auto;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    border-radius: 1px;
    @include themed() {
      background-color: t(color-surface);
    }
    @include body-XS();
    padding: 2px 8px;
    @include themed() {
      color: t(color-text-weak);
    }
  }
}
