@import 'styles//Colors';
@import 'styles//Mixins';
@import 'styles//Variables';
@import 'styles/design-tokens/Colors';

.root {
  .checkBoxLabel {
    margin-bottom: 16px;

    .label {
      color: $mediumSlate;
      @include app-font($smallFontSize, 16px);
    }
  }

  .table {
    thead tr th {
      @include app-font($smallFontSize, 16px, 700);
      color: $mediumSlate;
      background-color: $light-grey;

      &.disabled {
        @include themed() {
          color: t(background-color-legacy-silver);
        }
      }
    }

    tbody {
      .actionBtnGroup {
        display: flex;
        justify-content: flex-end;

        .actionBtn {
          padding: 8px;

          svg {
            font-size: 1rem;
          }

          &.saveIcon svg {
            color: $steelBlue;
            font-size: 1.5rem;
          }
        }
      }

      tr.rowEdit {
        td {
          padding: 8px;
        }

        input {
          padding: 8px;
          font-size: $smallFontSize;
        }
      }

      tr.rowView {
        .actionBtnGroup {
          visibility: hidden;
        }

        &:hover {
          @include themed() {
            background-color: t(background-color-gray-opaque);
          }
          .actionBtnGroup {
            visibility: visible;
          }
        }
      }
    }
  }
}
