@import 'styles/design-tokens/Typography.scss';
@import 'styles/design-tokens/Colors.scss';

.cardFieldLabel {
  @include label-XS;
  @include themed() {
    color: t(color-text);
  }
  margin: 0 0 2px;
}
