@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography.scss';

.root {
  .action {
    min-width: 160px;
    margin-top: 24px;

    button.signBtn {
      @include body-XS;
      @include themed() {
        background-color: t(color-text-link);
        color: t(color-surface-static);
      }
      box-shadow: 0 2px 0 rgba(26, 25, 25, 0.2);
      height: 30px;
      border-radius: 14px;

      &:disabled {
        opacity: 0.5;
      }
    }
    button.actionsBtn {
      @include themed() {
        background-color: t(color-text-inverse-light);
        color: t(color-text-inverse-dark);
        border: t(color-text-inverse-light);
      }
      box-shadow: 0 2px 0 rgba(26, 25, 25, 0.2);
      &:disabled {
        opacity: 0.5;
      }
      height: 30px;
      border-radius: 12px;
    }
    .buttonSection {
      padding: 12px;
    }
  }
}
