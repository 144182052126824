@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography.scss';

.root {
  border-radius: 24px;
  .modalTitle {
    @include heading-S;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    @include themed() {
      color: t(color-text-inverse-dark);
    }
  }

  .modalBody {
    @include body-XS;
    @include themed() {
      color: t(color-text-weak-alt);
    }
  }
  .closeIcon {
    @include themed() {
      color: t(color-text-inverse-dark);
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    padding: 16px 24px;
    .btn {
      padding: 0.5rem 1rem;
    }
    .actionBtn {
      @extend .btn;
      margin-left: 5px;
    }
    .cancelBtn {
      @extend .btn;
      @include themed() {
        color: t(color-text-inverse-dark);
        background-color: t(color-interactive-strong);
      }
      box-shadow: 0 2px 0 rgba(26, 25, 25, 0.2);
      @include themed() {
        color: t(color-text-inverse-light);
      }
      border-radius: 72px;
    }
    .modernWarningBtn {
      margin-left: 5px;

      border-radius: 72px;
      box-shadow: 0px 2px 0px rgb(26 25 25 / 20%);
      height: 32px;
      width: 114px;
      @include themed() {
        background-color: t(color-interactive-strong);
      }
      font-size: 14px;
    }
    .modernWarningActionBtn {
      @extend .modernWarningBtn;
      @include themed() {
        background-color: t(color-text-critical);
      }
    }
  }
}
