@import 'styles/Colors';
@import 'styles/Variables';
@import 'styles/design-tokens/Colors';

.root {
  padding: $bannerPadding;
  @include themed {
    color: t(color-background-burnt-orange);
  }
  background-color: $warning;
  margin: 0;
  border: 0;
  text-align: center;
  font-size: 14px;
}
