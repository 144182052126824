@import 'styles/design-tokens/Colors';

.unreadIndicator {
  height: 6px;
  width: 6px;
  @include themed() {
    background-color: t(color-primary);
  }
  border-radius: 50%;
  display: inline-block;
  grid-column: 1 / span 1;
}
