@import 'styles/Colors';
@import 'styles/Mixins';
@import 'styles/Variables';
@import 'styles/design-tokens/Typography.scss';

$dropdownWidth: 100px;
$inputWidth: 150px;
$tableFontSize: $smallFontSize;

@mixin table-input {
  font-size: $tableFontSize;
  padding: 8px;
}

.root {
  .cell {
    vertical-align: text-bottom;
  }
  div.otherInput {
    width: $inputWidth;
  }

  input {
    @include table-input;
  }

  div.datePicker {
    margin: 0;
    width: $inputWidth;

    button {
      padding: 0;
      font-size: $tableFontSize;
    }
  }

  div.numberInput {
    width: $numberInputWidth;

    input {
      text-align: right;
    }
  }

  div.selectInput {
    width: $dropdownWidth;
  }

  .label {
    color: $mediumSlate;
    @include body-XXS;
    user-select: none;

    &.enabled {
      font-weight: 700;
    }
  }
}
