@import 'styles/design-tokens/Colors.scss';
@import 'styles/design-tokens/Typography.scss';

.cardField {
  margin: 0;

  .cardFieldValue {
    @include body-S;
    @include themed() {
      color: t(color-text);
    }
    margin: 0;
    white-space: break-spaces;

    &.emptyCardFieldValue {
      @include themed() {
        color: t(color-text-weakest);
      }
    }
  }
}
