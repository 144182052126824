@import 'styles/design-tokens/Typography';
@import 'styles/design-tokens/Colors';
@import 'styles/Mixins';

.tasks {
  display: flex;
  flex-flow: column nowrap;
  padding: 0;
  .row {
    &:first-child {
      padding-top: 4px;
    }
    display: flex;
    flex-flow: row nowrap;
    padding: 0 16px 0;
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 16px;
    }
  }
  .col {
    display: flex;
    flex-flow: row wrap;
    flex-grow: 1;
    flex-basis: 0;
    align-items: flex-start;
    word-break: break-word;
    svg {
      margin-top: 2px;
      @include checkBoxSize;
    }
    & .textarea {
      flex-grow: 1;
      border: none;
      padding: 0;
      margin-left: 4px;
      background-color: transparent;
      font-weight: normal;
      height: inherit;
      overflow: hidden;
      @include body-S;
      @include themed() {
        color: t(color-text-weaker);
      }

      @include themed() {
        &.unchecked {
          color: t(color-text-weak);
        }
        &.checked {
          color: t(color-text-weaker);
          text-decoration: line-through;
        }
        &.notApplicable {
          color: t(color-text-weaker);
          text-decoration: line-through;
        }
      }
    }
  }
}

.accordionTitle {
  @include themed() {
    color: t(color-primary);
    background-color: t(color-background-tag-cloud-strong);
  }
}
