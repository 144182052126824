@import 'styles/design-tokens/Colors.scss';
@import 'styles/design-tokens/Typography.scss';

.modalLoader {
  margin-top: 35px;
  margin-bottom: 30px;

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
  }

  .description {
    text-align: center;
    @include label-M;
  }
}
