@import 'styles/design-tokens/Colors.scss';
@import 'styles/design-tokens/Typography.scss';
@import 'styles/Variables';

.inboxTabs {
  z-index: 999;
  overflow-y: auto;

  .inboxHeaderTabs {
    // ensure to adjust the height variable when modifying props that may affect the height
    // such as padding, margin e.t.c. So we don't break the sticky position of inbox section titles
    box-sizing: border-box;
    height: $inboxHeaderTabsHeight;
    margin-bottom: 10px;
    padding-top: 10px;
    top: 0;
    position: sticky;

    @include themed() {
      background-color: t(color-background);
    }
    box-shadow: inset -2px 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 999;

    .inboxChildTab {
      @include label-S;
      text-transform: capitalize;
      @include themed() {
        border-bottom: 1px solid t(color-border-strong);
      }
      height: 47px !important;
      min-height: 47px !important;
      white-space: nowrap;
      padding: 5px;
      min-width: fit-content;
    }
    span {
      height: 3px;
    }
  }
}
.indicator {
  @include themed() {
    background-color: t(color-primary);
  }
}
.inboxHeaderLayout {
  width: 100%;
  display: grid;
  //it ok to use fixed width here since the elements are text button which do not change in size in different view ports
  grid-template-columns: 190px 45px;
}
