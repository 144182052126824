@import 'styles/design-tokens/Colors.scss';
@import 'styles/design-tokens/Typography.scss';

.item {
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4px;
  width: 100%;
  background: none;
  border: none;
  cursor: pointer;
}

.name {
  @include label-S;
  min-height: 24px;
}

.description {
  @include body-S;
  min-height: 24px;
}
