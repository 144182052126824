@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography.scss';

.flexCenter {
  display: flex;
  align-items: center;
  gap: 8px;
}
.iconSize {
  font-size: 11px;
  margin-right: 4px;
}
.successState {
  @include themed() {
    color: t(color-text-link-strong);
  }
}

.root {
  padding-right: 16px;

  .btnContainer {
    &:has(button:disabled) {
      cursor: not-allowed;
    }
    &:hover {
      button.aiButton:not(:disabled) {
        @include themed() {
          background-color: t(color-interactive-stronger-alt);
        }
      }
    }
    button.aiButton {
      border: none;
      border-radius: 16px;

      @include themed() {
        color: t(color-text);
      }
      &:disabled {
        color: #2d2c2b;
        border: none;
        cursor: not-allowed;
        opacity: 0.4;
      }
    }
  }
}
