.container {
  display: flex;
  flex: 1;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.loginBox {
  padding: 2rem;
  text-align: center;
}

ul {
  text-align: left;
}
