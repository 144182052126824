@import 'styles/design-tokens/Colors';
@import 'styles/design-tokens/Typography';

.container {
  align-self: flex-end;
  max-width: 65%;
  display: flex;
  margin-bottom: 24px;

  .avatar {
    height: 32px;
    width: 32px;
    margin-right: 12px;
  }
}
.replyContainer {
  align-self: flex-start;
}
.popper {
  .tooltip {
    margin-top: 4px !important;
    inset: 12px auto 0 auto;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    border-radius: 1px;
    @include themed() {
      background-color: t(color-surface);
    }
    @include body-XS;
    padding: 2px 8px;
    @include themed() {
      color: t(color-text-weak);
    }
  }
}
