@import 'styles/design-tokens/Typography';

.ellipsisPopover {
  background-color: transparent;
  border-radius: 6px;

  .unassignButton {
    @include body-XS;
    border-radius: 6px;
    height: 45px;
    width: 100px;
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
}
