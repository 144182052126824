@import 'styles/design-tokens/Colors.scss';

.appointmentsAndNotesTable {
  overflow: initial;
  background: inherit;
  border-radius: 8px;
}

.appointmentsAndNotesTable table {
  border-collapse: separate;
  background: inherit;
  border-radius: 8px;
}

.appointmentsAndNotesTable thead {
  position: sticky;
  top: 120.5px;
  top: 136.5px;
  border-collapse: separate;
  z-index: 1000;
  background: inherit;
}

.unreadIndicator {
  padding-left: 11px !important;
  padding-right: 0 !important;
}
